import { MultiInvokerAddresses } from '@perennial/sdk'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { parseAbi } from 'viem'
import { arbitrum } from 'viem/chains'
import { useAccount, useBalance, usePublicClient, useWalletClient } from 'wagmi'

import { ChainalysisContractAddress } from '../constants/contracts'

import { usePerennialSDKContext } from '../contexts/perennialSdkContext'
import { useDSU, useMarketFactory, useUSDC, useVaultFactory } from './contracts'
import { useAddress, usePerpetualsChainId } from './network'


export type Balances =
  | {
      usdc: bigint
      usdcAllowance: bigint
      dsuAllowance: bigint
    }
  | undefined

export const useBalances = () => {
  const chainId = usePerpetualsChainId()
  const { address } = useAddress()
  const usdcContract = useUSDC()
  const dsuContract = useDSU()

  return useQuery({
    queryKey: ['balances', chainId, address],
    enabled: !!address,
    queryFn: async () => {
      if (!address || !chainId) return

      const [usdcBalance, usdcAllowance, dsuAllowance] = await Promise.all([
        usdcContract.read.balanceOf([address]),
        usdcContract.read.allowance([address, MultiInvokerAddresses[chainId]]),
        dsuContract.read.allowance([address, MultiInvokerAddresses[chainId]]),
      ])

      return {
        usdc: usdcBalance,
        usdcAllowance,
        dsuAllowance,
      }
    },
  })
}

export const useOperators = () => {
  const chainId = usePerpetualsChainId()
  const marketFactory = useMarketFactory()
  const vaultFactory = useVaultFactory()
  const { address } = useAddress()

  return useQuery({
    queryKey: ['operators', chainId, address],
    enabled: !!address,
    queryFn: async () => {
      if (!address || !chainId) return

      return {
        marketFactoryApproved: await marketFactory.read.operators([address, MultiInvokerAddresses[chainId]]),
        vaultFactoryApproved: await vaultFactory.read.operators([address, MultiInvokerAddresses[chainId]]),
      }
    },
  })
}

export const useOperatorTransactions = () => {
  const chainId = usePerpetualsChainId()
  const queryClient = useQueryClient()
  const { data: walletClient } = useWalletClient()
  const { address } = useAddress()
  const sdk = usePerennialSDKContext()

  const onApproveMultiInvokerOperator = async () => {
    try {
      if (!walletClient) throw new Error('No wallet client provided')
      const { to, data, value } = await sdk.operator.build.approveMarketFactoryTx()
      const hash = await walletClient.sendTransaction({ to, data, value })
      const receipt = await sdk.publicClient.waitForTransactionReceipt({ hash })
      await queryClient.invalidateQueries({ queryKey: ['operators', chainId, address] })
      return receipt
    } catch (err) {
      // prevent error if user rejects tx
      return
    }
  }

  return {
    onApproveMultiInvokerOperator,
  }
}

export const useIsSanctioned = () => {
  const { address } = useAddress()
  const publicClient = usePublicClient({ chainId: arbitrum.id })

  return useQuery({
    queryKey: ['chainalysis_sanctioned', address],
    enabled: !!address && !!publicClient,
    queryFn: async () => {
      if (!address || !publicClient) return

      return publicClient.readContract({
        address: ChainalysisContractAddress,
        abi: parseAbi(['function isSanctioned(address) view returns (bool)'] as const),
        functionName: 'isSanctioned',
        args: [address],
      })
    },
  })
}


export const useNativeBalance = () => {
  const { chainId, address } = useAccount()
  const { data, isLoading, refetch } = useBalance({
    chainId,
    address,
    query: {
      enabled: !!address,
      refetchInterval: 120000,
    },
  });

  return {
    data,
    isLoading,
    refetchBalance: refetch,
  }
}