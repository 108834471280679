import { useMemo } from "react";
import { ethers } from "ethers";
import { MulticallWrapper } from "ethers-multicall-provider";
import { getAddress } from "viem";
import { useAccount, usePublicClient } from "wagmi";
import { CHAINS } from "../utils/constants";
import { isSupportedV1Chain } from "../utils/utils";

// multiCallProvider
export const useActiveProvider = () => {
  const { chainId: currentChainId, address, isConnected } = useAccount()
  const publicClient = usePublicClient()
  
  const { chainId, userAccount, multiCallProvider } = useMemo(() => {
    let chainId = CHAINS.ARBITRUM;
    if (currentChainId && isSupportedV1Chain(currentChainId)) {
      chainId = currentChainId;
    }

    let userAccount = getAddress(ethers.ZeroAddress);
    if (address) {
      userAccount = address;
    }

    const multiCallProvider1 = new ethers.InfuraProvider(chainId, process.env.REACT_APP_INFURA_ID)
    
    const multiCallProvider = MulticallWrapper.wrap(
      multiCallProvider1
    );

    return { chainId, userAccount, multiCallProvider }; 
  },
    [address, currentChainId]
  );

  return {
    isConnected,
    chainId,
    currentChainId,
    userAccount,
    publicClient,
    multiCallProvider,
  };
}
