
import React, { useContext, useState } from "react"
import { ethers } from "ethers"
import { gql, useQuery } from "@apollo/client"
import { NumericFormat } from "react-number-format"
import { useTranslation } from "react-i18next"

import { productContext } from "../../states"
import { TOKENS_SYMBOLS, WIKI_TCAP_URL } from "../../utils/constants"
import { aggregatorContracts } from "../../utils/constants"
import { CustomTooltip } from "../../../components/common"

type props = {
  showMarketCap: boolean;
}

export const PriceBox = ({ showMarketCap }: props) => {
  const { t } = useTranslation()
  const currentProduct = useContext(productContext)
  const [totalMarketCap, setTotalMarketCap] = useState("0")
  const [tokenPrice, setTokenPrice] = useState(0)
  const [priceDelta, setPriceDelta] = useState(0)

  const loadPrice = (data: any) => {
    if (data.length > 0) {
      const oraclePrice = BigInt(data[0].answer) * currentProduct.market.multiplier;
      const lastPrice = parseFloat(ethers.formatEther(oraclePrice));
      const secondLastPrice = parseFloat(ethers.formatEther(
        BigInt(data[1].answer) * currentProduct.market.multiplier
      ));

      setTokenPrice(lastPrice);
      setPriceDelta(lastPrice - secondLastPrice);
      setTotalMarketCap(
        parseFloat(ethers.formatEther(oraclePrice * 10000000000n)).toFixed(0)
      );
    }
  }

  const LASTESTS_PRICE_UPDATES = gql`
    query LastPriceUpdates($aggregatorAddress: String!) {
      answerUpdateds(
        first: 2,
        orderBy: blockTimestamp, 
        orderDirection: desc,
        where: { aggregatorAddress: $aggregatorAddress }
      ) {
        id
        updatedAt
        roundId
        blockTimestamp
        blockNumber
        answer
      }
    }
  `;

  useQuery(LASTESTS_PRICE_UPDATES, {
    fetchPolicy: "no-cache",
    variables: {
      aggregatorAddress: aggregatorContracts[currentProduct.market.key].address
    },
    notifyOnNetworkStatusChange: true,
    pollInterval: 20000,
    onError: (error) => {
      console.log(error);
    },
    onCompleted: (data: any) => {
      if (data) {
        loadPrice(data.answerUpdateds);
      }
    },
  }); 

  const priceTooltip = () => (
    <p>
      {currentProduct.market.key === TOKENS_SYMBOLS.tcap && (
        <>
          {t("tooltip.tcap-price")}{" "}
          <a href={WIKI_TCAP_URL} target="_blank" rel="noreferrer" className="ref-">
            wiki
          </a>.
        </>
      )}
    </p>
  );
  
  const priceClassName = (): string => {
    let classN = "number bold green";
    if (priceDelta < 0) {
      classN = "number bold red";
    }
    return classN;
  }

  if (!showMarketCap) {
    return (
      <div className="stats-item">
        <CustomTooltip
          id="sov-price2"
          msg={priceTooltip()}
          hideDelay={1000}
          iconSize={12}
          showIcon={true}
        >
          <>
            <span className="title small">{currentProduct.market.symbol} {t("price")}:</span>
            <NumericFormat
              className={priceClassName()}
              value={tokenPrice.toFixed(currentProduct.market.displayDecimals)}
              displayType="text"
              thousandSeparator=","
              prefix="$"
              decimalScale={currentProduct.market.displayDecimals}
            />
          </>
        </CustomTooltip>
      </div>
    )
  }

  return (
    <div
      className={"oracle-prices ".concat(
        currentProduct.market.key !== TOKENS_SYMBOLS.tcap ? "no-gap" : ""
      )}
    >
      {showMarketCap && currentProduct.market.key === TOKENS_SYMBOLS.tcap && (
        <div className="oracle-prices-item">
          <p>Total Crypto Market Cap:</p>
          <NumericFormat
            className="number bold"
            value={totalMarketCap}
            displayType="text"
            thousandSeparator=","
            prefix="$"
            decimalScale={0}
          />
        </div>
      )}
      <div className="oracle-prices-item price stats-item">
        <p>{currentProduct.market.symbol} {t("price")}:</p>
        <CustomTooltip
          id="ov-price2"
          msg={priceTooltip()}
          hideDelay={2000}
          showIcon={true}
        >
          <NumericFormat
            className={priceClassName()}
            value={tokenPrice.toFixed(currentProduct.market.displayDecimals)}
            displayType="text"
            thousandSeparator=","
            prefix="$"
            decimalScale={currentProduct.market.displayDecimals}
          />
        </CustomTooltip>
      </div>
    </div>  
  )
}
