import { AbstractProvider, Contract } from "ethers";
import collateralAbi from "./abi/collateralAbi.json";
import lensAbi from "./abi/lensAbi.json";
import liquidityRewardsAbi from "./abi/liquidityRewardsAbi.json";
import multiInvokerAbi from "./abi/multiInvokerAbi.json";
import productAbi from "./abi/productAbi.json";
import cashbackHandlerAbi from "./abi/cashbackHandlerAbi.json";
import reserveAbi from "./abi/reserveAbi.json";
import vaultAbi from "./abi/vaultAbi.json";

import { LensProductSnapshotAbi } from "./abi/LensAbi";


const CHAINS = {
  ARBITRUM: 42161,
};


export const multiInvokerContract = {
  [CHAINS.ARBITRUM]: {
    address: "0xe72E82b672d7D3e206327C0762E9805fbFCBCa92",
  },
  abi: multiInvokerAbi,
};

export const lensContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x1593318424df66128cb7d0c5574B1283C3A74C3d",
  },
  abi: lensAbi,
};

export const tcapVaultContract = {
  [CHAINS.ARBITRUM]: {
    address: "0xEa281a4c70Ee2ef5ce3ED70436C81C0863A3a75a",
  },
  abi: vaultAbi,
};

export const tcapLongMarketContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x1cD33f4e6EdeeE8263aa07924c2760CF2EC8aAD0",
  },
  abi: productAbi,
};

export const tcapShortMarketContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x4243b34374cfB0a12f184b92F52035d03d4f7056",
  },
  abi: productAbi,
};

export const productCollateralContract = {
  [CHAINS.ARBITRUM]: {
    address: "0xAF8CeD28FcE00ABD30463D55dA81156AA5aEEEc2",
  },
  abi: collateralAbi,
};

export const reserveContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x0d49c416103Cbd276d9c3cd96710dB264e3A0c27",
  },
  abi: reserveAbi,
};

export const liquidityRewardsContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x938F145D5f3ABf681618Dcc5c71f095B870747Ba",
  },
  abi: liquidityRewardsAbi,
};

export const liquidityRewardsContractCtx = {
  [CHAINS.ARBITRUM]: {
    address: "0x944Cfb09bc40ce43EC7f471297Ef83a325BA78Ff",
  },
  abi: liquidityRewardsAbi,
};

export const rebateHandlerContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x798D986239333d4fa9d7bDf1b143C3F022Ae580B",
  },
  abi: cashbackHandlerAbi,
};


export const getVaultContract = (provider: AbstractProvider, vaultAddress: string): Contract => {
  return new Contract(vaultAddress, tcapVaultContract.abi, provider);
};

export const getLensContract = (chainId: number, provider: AbstractProvider): Contract => {
  return new Contract(lensContract[chainId].address, lensContract.abi, provider);
};

export const getProductContract = (provider: AbstractProvider, productAddress: string): Contract => {
  return new Contract(productAddress, productAbi, provider);
};

export const getCollateralContract = (chainId: number, provider: AbstractProvider): Contract => {
  return new Contract(productCollateralContract[chainId].address, collateralAbi, provider);
};

export const getMultiInvokerContract = (chainId: number, provider: AbstractProvider): Contract => {
  return new Contract(multiInvokerContract[chainId].address, multiInvokerContract.abi, provider);
};

export const getLiquidityRewardsContract = (chainId: number, provider: AbstractProvider): Contract => {
  return new Contract(liquidityRewardsContract[chainId].address, liquidityRewardsContract.abi, provider);
};

export const getLiquidityRewardsContractCtx = (chainId: number, provider: AbstractProvider): Contract => {
  return new Contract(liquidityRewardsContractCtx[chainId].address, liquidityRewardsContractCtx.abi, provider);
};

export const getRebateHandlerContract = (chainId: number, provider: AbstractProvider): Contract => {
  return new Contract(rebateHandlerContract[chainId].address, rebateHandlerContract.abi, provider);
};

export const getLensProductContract = (chainId: number, provider: AbstractProvider): Contract => {
  return new Contract(lensContract[chainId].address, LensProductSnapshotAbi, provider);
};
