import React, { useState } from "react";
import { TCAP_MARKET, TCAP_TOKEN, USDC_TOKEN } from "../utils/constants";
import { tcapVaultContract } from "../utils/contracts";
import { ContractType, TokenType } from "../utils/types";
import { IProductContext } from "../states";


export const useProductContext = (): IProductContext => {
  const [market, setMarket] = useState(TCAP_TOKEN);
  const [collateral, setCollateral] = useState(USDC_TOKEN);
  const [productContract, setProductContract] = useState(TCAP_MARKET.longContract);
  const [vaultContract, setVaultContract] = useState(tcapVaultContract);
  const [type, setType] = useState("long");

  const setCurrentMarket = React.useCallback((currentMarket: TokenType): void => {
    setMarket(currentMarket);
  }, []);
  const setCurrentCollateral = React.useCallback((currentCollateral: TokenType): void => {
    setCollateral(currentCollateral);
  }, []);
  const setCurrentProductContract = React.useCallback((currentProductContract: ContractType): void => {
    setProductContract(currentProductContract);
  }, []);
  const setCurrentVaultContract = React.useCallback((currentVaultContract: ContractType): void => {
    setVaultContract(currentVaultContract);
  }, []);
  const setCurrentType = React.useCallback((currentType: string): void => {
    setType(currentType);
  }, []);

  const isLonging = (): boolean => {
    return type === "long";
  }
  
  return {
    market,
    setCurrentMarket,
    collateral,
    setCurrentCollateral,
    productContract,
    setCurrentProductContract,
    vaultContract,
    setCurrentVaultContract,
    type,
    setCurrentType,
    isLonging,
  };
};
