import { ethers, AbstractProvider } from "ethers";
import useSWR from "swr";
import { useAccount } from "wagmi";
import { useActiveProvider } from ".";
import { getUSDCContract } from "../utils/contracts";
import { CHAINS } from "../utils/constants";
import { TokensBalanceType } from "../utils/types";

export const useUserAccount = (): {
  address: string,
  isConnected: boolean,
  isConnecting: boolean,
  chainId: number,
} => {
  const { chain } = useAccount();
  const { address, isConnected, isConnecting, isReconnecting } = useAccount();
  
  let chainId = CHAINS.ARBITRUM;
  let ownerAddress = ethers.ZeroAddress;
  if (address) {
    ownerAddress = address?.toString();
  }
  if (chain) {
    chainId = chain.id;
  }

  return {
    address: ownerAddress,
    isConnected,
    isConnecting: isConnecting || isReconnecting,
    chainId,
  };
};

export const tokensBalanceFetcher = (provider: AbstractProvider | null) => async ([_, chainId, account]: [
    _: string,
    chainId: number,
    account: string
  ]): Promise<TokensBalanceType> => {
    if (!provider || !account) {
      return {
        usdcBalance: 0n
      }
    }  
  
    const usdcContract = getUSDCContract(chainId, provider);
    let usdcBalance = 0n;  
  
    if (account !== ethers.ZeroAddress && account !== "") { 
      [usdcBalance] = await Promise.all([usdcContract.balanceOf(account)]);
    }
  
    return { usdcBalance }
  };

export const useTokensBalance = () => {
  const { chainId, userAccount, multiCallProvider } = useActiveProvider();

  return useSWR<TokensBalanceType | undefined>(
    !userAccount || !multiCallProvider ? null : ["tokensBalanceFetcher", chainId, userAccount],
    tokensBalanceFetcher(multiCallProvider),
    { revalidateOnFocus: false },
  )
}
