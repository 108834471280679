const numbersOnlyRegex = /^\d*\.?\d*$/


export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const isNumbersOnly = (value: string) => numbersOnlyRegex.test(value)

export const isPositiveNumber = (value: any): boolean => {
  if (isNumbersOnly(value)) {
    return parseFloat(value) > 0;
  }
  return false;
};

export const isNonNegativeNumber = (value: any): boolean => {
  if (isNumbersOnly(value)) {
    return parseFloat(value) >= 0;
  }
  return false;
};

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}