import React from "react";
import { Image } from "react-bootstrap/esm";
import { ReactComponent as ArbIcon } from "../../assets/arbitrum.svg";
import { ReactComponent as DaiIcon } from "../../assets/dai.svg";
import { ReactComponent as EthIcon } from "../../assets/eth.svg";
import { ReactComponent as UsdcIcon } from "../../assets/usdc.svg";
import { ReactComponent as WbtcIcon } from "../../assets/wbtc.svg";
import jpegzIcon from "../../assets/jpegz-coin.png";
import perpeIcon from "../../assets/perpe-coin.png";
import tcapIcon from "../../assets/tcap-coin.png";
import ctxIcon from "../../assets/ctx-icon.png";

type iconProps = {
  name: string;
  size: string;
};

export const TokenIcon = ({ name, size }: iconProps) => {
  const className = name.toLowerCase().concat(" ").concat(size);

  switch (name.toUpperCase()) {
    case "ARB":
      return <ArbIcon className={className} />;
    case "CTX":
      return <Image className={className} src={ctxIcon} alt="TCAP Icon" />;
    case "DAI":
      return <DaiIcon className={className} />;
    case "ETH":
      return <EthIcon className={className} />;
    case "JPEGZ":
      return <Image className={className} src={jpegzIcon} alt="JPEGz Icon" />;
    case "PEPE":
        return <Image className={className} src={perpeIcon} alt="PEPE Icon" />;
    case "TCAP":
      return <Image className={className} src={tcapIcon} alt="TCAP Icon" />;
    case "USDC":
      return <UsdcIcon className={className} />;
    case "WBTC":
      return <WbtcIcon className={className} />;
    case "WETH":
      return <Image className={className} src={perpeIcon} alt="PEPE Icon" />;
    default:
      return <></>;
  }
};
