import React from "react"
import { Stack } from "react-bootstrap"
import { useMediaQuery } from 'react-responsive'


export const WhitelistPage = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })

  return (
    <Stack
      direction="vertical"
      gap={4}
      className="w-100 h-75 justify-content-center align-items-center"
      style={{ padding: !isMobile ? "1rem 15rem" : "1rem 1.5rem" }}
    >
      <h1 className="text-purple text-center">You need to be on the allow list to test the dApp</h1>
      <h3 className="text-center" style={{ color: "#e0e0ea" }}>
        Please reach out to us on{" "}
        <a
          href="https://discord.com/invite/cryptex"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#e0e0ea", fontWeight: "400" }}
        >
          discord
        </a> to be added to the allow list.
      </h3>
    </Stack>
  )
};
