import React, { useContext, useState } from "react"
import { Button, Spinner } from "react-bootstrap/esm"
import { useTranslation } from "react-i18next"
import { ethers } from "ethers"
import "../../../styles/modals.scss"
import { usdcTokenContract, dsuTokenContract, multiInvokerContract } from "../../utils/contracts"
import { ApproveType, ERROR_USER_REJECTED_TRAN } from "../../utils/constants"
import { useActiveProvider, useApproveAllowance } from "../../hooks"
import { productContext } from "../../states"
import { errorNotification, notifyUser } from "../../../components/common"


type props = {
  approvalAction: number;
  currentAllowance: number;
  amountToApprove: number;
  onSuccess: () => void;
};

export const ApproveCollateralButton = ({ approvalAction, currentAllowance, amountToApprove, onSuccess }: props) => {
  const { t } = useTranslation()
  const { chainId } = useActiveProvider();
  const product = useContext(productContext);
  const [writingTransaction, setWritingTransaction] = useState(false);
  const formatUnits = ApproveType.DEPOSIT === approvalAction ? 6 : 18;

  const contractToApprove = () => {
    if (ApproveType.DEPOSIT === approvalAction) {
      return usdcTokenContract;
    } else if (ApproveType.WITHDRAW === approvalAction || ApproveType.STAKE === approvalAction) {
      return product.vaultContract;
    } else {
      return dsuTokenContract;
    }
  };

  const onTransactionSettled = (isSuccess: boolean, error: any) => {
    setWritingTransaction(false);
    if (isSuccess) {
      let msg = t("notification.approved", { token: "USDC"})
      if (ApproveType.WITHDRAW === approvalAction) {
        msg = t("notification.withdraw-shares-approved", { token: "TVA" })
      }
      if (ApproveType.CLAIM === approvalAction) {
        msg = t("notification.withdraw-shares-approved", { token: "DSU" })
      }
      if (ApproveType.STAKE === approvalAction) {
        msg = t("notification.staked-shares-approved")
      }
      notifyUser(msg);
      onSuccess();
    } else {
      if (error.name !== ERROR_USER_REJECTED_TRAN) {
        errorNotification(t("error.transaction"))
      }
    }
  };

  const { onApproveAllowance } = useApproveAllowance(
    contractToApprove(),
    multiInvokerContract[chainId].address,
    onTransactionSettled
  )

  const onButtonClick = async () => {
    setWritingTransaction(true);
    const amount = ethers.parseUnits(
      (parseFloat(amountToApprove.toFixed(4)) + 1).toString(),
      formatUnits
    )
    onApproveAllowance(amount)
  };  

  const msg = (): string => {
    if (ApproveType.DEPOSIT === approvalAction) {
      return t("info-msg.approve-usdc-2", { value: currentAllowance.toFixed(4) })
    } else if (ApproveType.WITHDRAW === approvalAction) {
      return t("info-msg.approve-to-withdraw")
    } else if (ApproveType.STAKE === approvalAction) {
      return t("info-msg.approve-shares")
    } else {  
      return t("info-msg.approve-to-claim")
    }
  };

  return (
    <div className="btn-approve-container">
      <div className="btn-approve-container-msg">
        <h6>{msg()}</h6>
      </div>
      <Button
        className="btn-approve bold"
        onClick={() => onButtonClick()}
        disabled={writingTransaction}
      >
        {writingTransaction && <Spinner animation="border" variant="secondary" className="small" />}
        {writingTransaction ?
          t("approving") :
          t("approve", { token: approvalAction === ApproveType.DEPOSIT ? " USDC" : "" })
        }
      </Button>
    </div>      
  );   
}
