import React, { useEffect, useRef, useState } from 'react';
import { createChart, ColorType, CrosshairMode, LastPriceAnimationMode, BarPrice } from 'lightweight-charts';

import { useIndexPriceLastIntervalUpdate, useIndexChartPrices } from '../../hooks/feeds';
import { notEmpty } from '../../utils/utils';
import { SupportedIndex } from '../../constants/indexes';
import { PricesApiTimeIntervals, TimeFrames, TimeFramesMetadata } from './constants';


export const Chart = ({
  indexSymbol,
  logicalFromTimestamp,
  fromTimestamp,
  timeFrame,
}: {
  indexSymbol: string,
  logicalFromTimestamp: number,
  fromTimestamp: number,
  timeFrame: TimeFrames,
  }) => {
  const timeFrameMetadata = TimeFramesMetadata[timeFrame];
  const chartContainerRef = useRef<any>();
  const chart = useRef<any>();
  const series = useRef<any>();
  const colors = {
    backgroundColor: "#171B26",
    lineColor: '#A440F2',
    textColor: "#e1e0ec",
    areaTopColor: "#a540f282",
    areaBottomColor: "#a540f246",
  }

  const [prices, setPrices] = useState<any[]>([]);
  const [lastPriceOnChart, setLastPriceOnChart] = useState({ time: 0, value: 0 });
  const {
    data: pricesData,
    isLoading,
    isFetchingNextPage,
    // hasNextPage,
    // fetchNextPage
  } = useIndexChartPrices(indexSymbol as SupportedIndex, timeFrameMetadata.interval, fromTimestamp);
  const { data: lastPrice } = useIndexPriceLastIntervalUpdate(
    indexSymbol as SupportedIndex,
    PricesApiTimeIntervals.OneSecond,
    timeFrameMetadata.refetchInterval
  );

  useEffect(() => {
    if (!isLoading && !isFetchingNextPage)  { 
      const prices = pricesData?.pages
        .map((page: any) => page?.prices)
        .flat()
        .filter(notEmpty) ?? []

      /* if (hasNextPage) {
        console.log('fetchNextPage: ')
        fetchNextPage();
      } else { */
      setPrices(prices.sort((a: any, b: any) => a.time - b.time));
      // }
    }
  },
    // eslint-disable-next-line
    [isLoading, isFetchingNextPage, pricesData]
  )

  useEffect(
    () => {
      if (prices.length === 0) return;

      const handleResize = () => {
        if (chartContainerRef.current !== null) {
          chart.current.applyOptions({
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
          });
        }
      };

      chart.current = createChart(chartContainerRef.current, {
        crosshair: {
          mode: CrosshairMode.Normal,
          vertLine: {
            color: '#C3BCDB44',
            labelBackgroundColor: '#494866',
          },
          horzLine: {
            color: '#C3BCDB44',
            labelBackgroundColor: '#494866',
          },
        },
        layout: {
          background: { type: ColorType.Solid, color: colors.backgroundColor },
          textColor: colors.textColor,
        },
        grid: {
          vertLines: {
            color: '#36383A',
          },
          horzLines: {
            color: '#36383A',
          },
        },
        width: chartContainerRef.current.clientWidth,
        height: chartContainerRef.current.clientHeight,
        timeScale: {
          timeVisible: true,
          secondsVisible: false,
        },
        localization: {
          priceFormatter: (price: BarPrice) => {
            return price.toFixed(4);
          },
        },
        rightPriceScale: {
          ticksVisible: true,
        },        
      });

      chart.current.timeScale().scrollToPosition(10);
      series.current = chart.current.addAreaSeries({
        lineColor: colors.lineColor,
        topColor: colors.areaTopColor,
        bottomColor: colors.areaBottomColor,
        lastPriceAnimation: LastPriceAnimationMode.Continuous,
      });
      series.current.applyOptions({
        priceFormat: {
          type: 'custom',
          minMove: 0.0001,
          formatter: (price: BarPrice) => price.toFixed(4),
        },
      });
      series.current.setData(prices);      
      
      if (prices.length > 0) { 
        const fromLogicalIndex = prices.findIndex((price: any) => price.time >= logicalFromTimestamp);
        chart.current.timeScale().setVisibleLogicalRange({
          from: fromLogicalIndex,
          to: prices.length,
        });
      } else {
        // chart.current.timeScale().fitContent();
      }

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
        chart.current.remove();
      };
    },
    // eslint-disable-next-line
    [prices]
  );

  useEffect(() => {
    if (lastPrice && lastPrice.time > 0 && series.current && lastPrice.time >= lastPriceOnChart.time) {
      /* let updateLastTimeOnChart = timeFrameMetadata.interval === PricesApiTimeIntervals.OneHour;
      if (updateLastTimeOnChart) {
        updateLastTimeOnChart = lastPrice.time - lastPriceOnChart.time < 900
      } */
      series.current.update({
        time: lastPrice.time,
        value: lastPrice.value
      });
      setLastPriceOnChart(lastPrice);
    }
  },
    // eslint-disable-next-line
    [lastPrice]
  );

  return (
    <div className="h-100" ref={chartContainerRef} />
  );
};
