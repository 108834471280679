import { AbstractProvider, Typed } from "ethers";
import useSWR from "swr";
import {
  AccountProductPosition,
  ExtendedUserProductSnapshot,
  ProductDetails,
  ProductCollateralDetails,
  ProductSnapshot,
  ProtocolSnapshot,
  PrePositionStruct,
  PositionStruct,
  OpenPositionType,
} from "../utils/types";
import { nextPosition, userPositionSize, userPositionDirection } from "../utils/utils";
import { PRODUCTS_ADDRESS } from "../utils/constants";
import {
  getLensContract,
  getLensProductContract,
  getProductContract,
  getUSDCContract,
  multiInvokerContract,
} from "../utils/contracts";
import { useActiveProvider } from ".";


const protocolSnapshotFetcher =
  (provider: AbstractProvider) =>
  async ([_, chainId]: [_: string, chainId: number]) => {
    
    const lens = getLensContract(chainId, provider);
    return lens.snapshot.staticCall();
  }

export const useProtocolSnapshot = () => {
  const { chainId, multiCallProvider } = useActiveProvider();
  return useSWR<ProtocolSnapshot | undefined>(
    ["protocolSnapshot", chainId],
    protocolSnapshotFetcher(multiCallProvider),
    { revalidateOnFocus: false },
  )
}

export const getUserProductPosition = (
  positions: [PrePositionStruct, PositionStruct] | undefined
): AccountProductPosition => {
  if (!positions)
    return {
      type: "none",
      amount: "0",
      canOpenTaker: true,
      canOpenMaker: true,
    }

  const [pre, settled] = positions
  const canOpenTaker = [pre.openPosition["maker"], pre.closePosition["maker"], settled["maker"]].every((num) =>
    num === 0n
  )
  const canOpenMaker = [pre.openPosition["taker"], pre.closePosition["taker"], settled["taker"]].every((num) =>
    num === 0n
  )

  const netMakerAmount = pre.openPosition["maker"] + settled["maker"] - pre.closePosition["maker"]

  if (netMakerAmount !== 0n) {
    return {
      type: OpenPositionType.Maker,
      amount: netMakerAmount.toString(),
      canOpenTaker,
      canOpenMaker,
    }
  }

  const netTakerAmount = pre.openPosition["taker"] + settled["taker"] - pre.closePosition["taker"]
  if (netTakerAmount !== 0n) {
    return {
      type: OpenPositionType.Taker,
      amount: netTakerAmount.toString(),
      canOpenTaker,
      canOpenMaker,
    }
  }

  return { type: "none", amount: "0", canOpenTaker, canOpenMaker }
};

const userProdPositionFetcher =
  (provider: AbstractProvider) =>
  async ([chainId, productAddress, account]: [
    chainId: number,
    productAddress: string,
    account?: string | null
  ]): Promise<ProductDetails | null> => {
    if (!account || !productAddress) return null

    const lens = getLensContract(chainId, provider);
    const productSnapshot = await lens.snapshot.staticCall(Typed.address(productAddress));

    const userSnapshot = account
      ? await lens.snapshot.staticCall(Typed.address(account), Typed.address(productAddress))
      : undefined
    const position = getUserProductPosition(userSnapshot ? [userSnapshot.pre, userSnapshot.position] : undefined)

    return {
      price: productSnapshot.latestVersion.price.toString(),
      name: productSnapshot.productInfo.name,
      totalTaker: productSnapshot.position.taker
        .add(productSnapshot.pre.openPosition.taker.sub(productSnapshot.pre.closePosition.taker))
        .toString(),
      totalMaker: productSnapshot.position.maker
        .add(productSnapshot.pre.openPosition.maker.sub(productSnapshot.pre.closePosition.maker))
        .toString(),
      rate: productSnapshot.rate.toString(),
      symbol: productSnapshot.productInfo.symbol,
      position,
      maintenanceRequired: userSnapshot?.maintenance.toString(),
      collateral: productSnapshot.collateral.toString(),
      address: productAddress,
      maintenance: productSnapshot.maintenance.toString(),
    }
  }

export const useUserProdPosition = (productAddress: string) => {
  const { chainId, isConnected, userAccount, multiCallProvider } = useActiveProvider();

  return useSWR<ProductDetails | null>(
    isConnected ? [chainId, productAddress, userAccount] : null,
    userProdPositionFetcher(multiCallProvider),
    { revalidateOnFocus: false },
  )
}

const productSnapshotsFetcher =
  (provider: AbstractProvider) =>
    async ([chainId, productAddresses]: [
      chainId: number,
      productAddresses: string[]
    ]) => {
      // const lens = getLensContract(chainId, provider);
      const lens = getLensProductContract(chainId, provider);
      const snapshots = await lens.snapshots(productAddresses);
      // const snapshots = await lens.snapshots.staticCall(productAddresses)["snapshots(address[])"];

      return snapshots.reduce((prods: any, ss: any) => {
        prods[ss.productAddress.toLowerCase()] = {
          productInfo: ss.productInfo,
          productAddress: ss.productAddress.toLowerCase(),
          address: ss.productAddress,
          rate: ss.rate,
          dailyRate: ss.dailyRate,
          latestVersion: ss.latestVersion,
          maintenance: ss.maintenance,
          collateral: ss.collateral,
          shortfall: ss.shortfall,
          pre: ss.pre,
          position: ss.position,
          productFee: ss.productFee,
          protocolFee: ss.protocolFee,
          openInterest: ss.openInterest,
        }

        return prods
      }, {} as { [key: string]: ProductSnapshot })
    };

export const useProductsSnapshots = () => {
  const { chainId, multiCallProvider } = useActiveProvider();

  return useSWR<{ [key: string]: ProductSnapshot }>(
    [chainId, PRODUCTS_ADDRESS[chainId].sort()],
    productSnapshotsFetcher(multiCallProvider),
    { revalidateOnFocus: false },
  )
};

export function useProductSnapshot(productAddress?: string) {
  const { data: snapshots, mutate: mutateProductsSnapshots } = useProductsSnapshots();

  return {
    product: productAddress ? snapshots?.[productAddress.toLowerCase()] : undefined,
    mutateProductsSnapshots,
  }
};

const userProductSnapshotsFetcher =
  (provider: AbstractProvider) =>
  async ([chainId, account, productAddresses]: [
    chainId: number,
    account: string,
    productAddresses: string[]
  ]) => {
    const lens = getLensContract(chainId, provider);
    const snapshots = await lens["snapshots(address,address[])"].staticCall(Typed.address(account), productAddresses);
    // const snapshots = await lens.callStatic["snapshots(address,address[])"](account, productAddresses);

    const ssResp: { [key: string]: ExtendedUserProductSnapshot } = {};
    for (let i = 0; i < snapshots.length; i += 1) {
      let currentClosePosition = {
        maker: 0n,
        taker: 0n,
      };
      const userNextPosition = nextPosition(snapshots[i].pre, snapshots[i].position)

      if (userNextPosition.maker === 0n && userNextPosition.taker === 0n) { 
        const product = getProductContract(provider, snapshots[i].productAddress);
        const [currentPre] = await Promise.all([
          await product.pre.staticCall(Typed.address(account))
        ]);
        currentClosePosition = currentPre.closePosition;
      }

      ssResp[snapshots[i].productAddress.toLowerCase()] = {
        productAddress: snapshots[i].productAddress.toLowerCase(),
        userAddress: snapshots[i].userAddress,
        collateral: snapshots[i].collateral,
        maintenance: snapshots[i].maintenance,
        pre: snapshots[i].pre,
        position: snapshots[i].position,
        liquidatable: snapshots[i].liquidatable,
        liquidating: snapshots[i].liquidating,
        openInterest: snapshots[i].openInterest,
        fees: snapshots[i].fees,
        exposure: snapshots[i].exposure,
        nextPosition: userNextPosition,
        positionSize: userPositionSize(userNextPosition),
        positionDirection: userPositionDirection(userNextPosition, currentClosePosition),
      }
    }

    return ssResp;
  }

export const useUserProductSnapshots = (
  accountAddress: string
): any => {
  const { chainId, multiCallProvider } = useActiveProvider();
  
  return useSWR<{ [key: string]: ExtendedUserProductSnapshot }>(
    [chainId, accountAddress, PRODUCTS_ADDRESS[chainId].sort()],
    userProductSnapshotsFetcher(multiCallProvider),
    { revalidateOnFocus: false },
  );
};

export function useUserProductSnapshot(accountAddress: string, productAddress: string) {
  const { data: snapshots, mutate: mutateUserProductSnapshot } = useUserProductSnapshots(accountAddress);

  return {
    userProduct: snapshots?.[productAddress.toLowerCase()],
    mutateUserProductSnapshot,
  }
};

const collateralFetcher =
  (provider: AbstractProvider) =>
  async ([chainId, productAddress, account]: [
    chainId: number,
    productAddress: string,
    account?: string | null | undefined
  ]): Promise<ProductCollateralDetails | null> => {
    if (!account) {
      return {
        userCollateral: 0n,
        maintenance: 0n,
        usdcAllowance: 0n,
      }
    }  

    const lensContract = getLensContract(chainId, provider);
    const usdcContract = getUSDCContract(chainId, provider);

    const [ userCollateral, maintenance, usdcAllowance] = await Promise.all([
      await lensContract.collateral.staticCall(Typed.address(account), Typed.address(productAddress)),
      await lensContract.maintenance.staticCall(Typed.address(account), Typed.address(productAddress)),
      await usdcContract.allowance(Typed.address(account), Typed.address(multiInvokerContract[chainId].address)),
    ])

    return {
      userCollateral,
      maintenance,
      usdcAllowance,
    }
  }

export const useCollateral = (productAddress: string) => {
  const { chainId, isConnected, userAccount, multiCallProvider } = useActiveProvider();

  return useSWR<ProductCollateralDetails | null>(
    isConnected ? [chainId, productAddress, userAccount] : null,
    collateralFetcher(multiCallProvider),
    { revalidateOnFocus: false },
  )
}

export type BalanceDetails = {
  usdc: bigint
  dsu: bigint
}
