import { useCallback } from 'react'
import { Address, BaseError } from 'viem';
import { useWalletClient, useWriteContract } from "wagmi"
import { useQueryClient } from '@tanstack/react-query'

import { useCtx, useDelegatorFactory } from "../contracts";
import { useChainActions, useChainId } from '../network';
import { errorNotification, notifyUser, useTransactionToasts } from '../../components/common';
import { getPublicClient, metamaskTxRejectedError } from '../../constants/network';


export const useStakerTransactions = () => {
  const chainId =  useChainId()
  const { onSwitchChain } = useChainActions()
  const { data: walletClient } = useWalletClient()
  const { writeContractAsync } = useWriteContract();
  const queryClient = useQueryClient()
  const toasts = useTransactionToasts()
  const ctxToken = useCtx(walletClient)
  const delegatorFactory = useDelegatorFactory(walletClient)
  
  const refresh = useCallback(
    () =>
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) =>
          ['keepers', 'stakerStats', 'ctxBalances'].includes(queryKey.at(0) as string) &&
          queryKey.includes(chainId),
      }),
    [chainId, queryClient],
  )

  const onApproveCtx = async (amount: bigint) => { 
    if (walletClient === undefined) return
    if (!(await onSwitchChain())) return

    try {
      const hash = await writeContractAsync({
        address: ctxToken.address,
        abi: ctxToken.abi,
        functionName: "approve",
        args: [delegatorFactory.address, amount],
      })
    
      await toasts.waitForTransactionAlert(hash,
        {
          successMsg: "Approved successfully",
          errorMsg: "Failed to approve",
          onSuccess: async () => {
            await refresh()
            setTimeout(() => refresh(), 7000)
          }
        }
      )
    } catch (err) { 
      if (err instanceof BaseError) {
        if (!err.message.toLowerCase().includes(metamaskTxRejectedError)) {
          errorNotification("Error: Failed to approve")
        }
      } else {
        errorNotification("Error: Failed to approve")
      }
    }
  }

  const onClaimRewards = async () => { 
    if (walletClient === undefined) return
    if (!(await onSwitchChain())) return

    try {
      const hash = await writeContractAsync({
        address: delegatorFactory.address,
        abi: delegatorFactory.abi,
        functionName: "getReward"
      })
    
      toasts.waitForTransactionAlert(hash,
        {
          successMsg: "Reward has been claimed",
          errorMsg: "Failed to claim reward",
          onSuccess: async () => {
            await refresh()
            setTimeout(() => refresh(), 7000)
          }
        }
      )
    } catch (err) { 
      if (err instanceof BaseError) {
        if (!err.message.toLowerCase().includes(metamaskTxRejectedError)) {
          errorNotification("Error: Failed to claim reward")
        }
      } else {
        errorNotification("Error: Failed to claim reward")
      }
    }
  }

  const onStake = async (keeperAddress: Address, amount: bigint) => { 
    if (walletClient === undefined) return
    if (!(await onSwitchChain())) return

    try {
      const hash = await writeContractAsync({
        address: delegatorFactory.address,
        abi: delegatorFactory.abi,
        functionName: "stake",
        args: [keeperAddress, amount],
      })
    
      await toasts.waitForTransactionAlert(hash,
        {
          successMsg: "Staked successfully",
          errorMsg: "Failed to stake",
          onSuccess: async () => {
            await refresh()
            setTimeout(() => refresh(), 7000)
          }
        }
      )
    } catch (err) { 
      console.log("Stake error: ", err)
      if (err instanceof BaseError) {
        if (!err.message.toLowerCase().includes(metamaskTxRejectedError)) {
          errorNotification("Error: Failed to stake")
        }
      } else {
        errorNotification("Error: Failed to stake")
      }
    }
  }
  
  const onWithdraw = async (keeperAddress: Address, amount: bigint) => { 
    if (walletClient === undefined) return
    if (!(await onSwitchChain())) return

    try {
      const hash = await writeContractAsync({
        address: delegatorFactory.address,
        abi: delegatorFactory.abi,
        functionName: "withdraw",
        args: [keeperAddress, amount],
      })
    
      toasts.waitForTransactionAlert(hash,
        {
          successMsg: "CTX Withdraw was succesful.",
          errorMsg: "Failed to withdraw CTX.",
          onSuccess: async () => {
            await refresh()
            setTimeout(() => refresh(), 7000)
          }
        }
      )
    } catch (err) { 
      if (err instanceof BaseError) {
        if (!err.message.toLowerCase().includes(metamaskTxRejectedError)) {
          errorNotification("Error: Failed to withdraw CTX")
        }
      } else {
        errorNotification("Error: Failed to withdraw CTX")
      }
    }
  }

  const onCreateKeeper = async (keeperAddress: Address) => { 
    if (walletClient === undefined) return
    if (!(await onSwitchChain())) return

    const publicClient = getPublicClient(chainId)
    try {
      const { request } = await publicClient.simulateContract({
        address: delegatorFactory.address,
        abi: delegatorFactory.abi,
        functionName: 'createDelegator',
        args: [keeperAddress],
        account: walletClient.account
      })

      const hash = await walletClient.writeContract(request)
      setTimeout(() => notifyUser("Crypt. Keeper has been create."), 5000)

      return hash
    } catch (err) {
      if (err instanceof BaseError) {
        if (!err.message.toLowerCase().includes(metamaskTxRejectedError)) {
          errorNotification("Error: Failed to create keeper")
        }
      } else {
        errorNotification("Error: Failed to create keeper")
      }
    }  
  }

  return {
    onApproveCtx,
    onClaimRewards,
    onStake,
    onWithdraw,
    onCreateKeeper,
  }
}