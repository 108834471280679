import React, { useMemo } from "react"
import { Button, Form, InputGroup } from "react-bootstrap/esm"
import { NumericFormat } from "react-number-format"
import { useMediaQuery } from "react-responsive"
import { useTranslation } from "react-i18next"
import { isNumber } from "../utils/utils"
import { TokenIcon } from "../../components/common"

type props = {
  tokenSymbol: string;
  showTokenBalance: boolean;  
  changingAmount: boolean;
  amount: string;
  amountUsd: string;
}

export const IndexInput = ({
  tokenSymbol,
  showTokenBalance,
  changingAmount,
  amount,
  amountUsd,
}: props) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })

  const isAmountZero = useMemo(() => {
    let isAmountZero = false;

    if (isNumber(amount)) {
      isAmountZero = parseFloat(amount) === 0
    }

    return isAmountZero;
  }, [amount])

  const IndexInfo = () => (
    <div className="index-info">
      <TokenIcon name={tokenSymbol} size="small" />
      <h6>{tokenSymbol.toUpperCase()}</h6>
    </div>
  );
  
  return (
    <Form.Group className="asset-input-group">
      <div className="asset-input-top">
        <h6>
          {changingAmount ? t("change-amount") : t("amount")}
        </h6>
        <NumericFormat
          className="balance-usd"
          value={amountUsd}
          displayType="text"
          thousandSeparator=","
          suffix=" USD"
        />
      </div>
      <InputGroup className="input-group index">
        <Form.Control
          id="index-input"
          placeholder="0"
          type={isMobile ? "number" : "text"}
          className="balance-input index"
          value={!isAmountZero ? amount : ""}
          readOnly
        />
        <div className="asset-info">
          <IndexInfo />
          {showTokenBalance && (
            <div className="asset-balance">
              <span className="title">{t("balance")}:</span>
              <NumericFormat
                className="balance"
                value={10}
                displayType="text"
                decimalSeparator=","
              />
              <Button variant="secondary" className="small">
                {t("max")}
              </Button>
            </div>
          )}          
        </div>
      </InputGroup>
    </Form.Group>
  )       
}
