import React from "react"
import { Stack } from "react-bootstrap"
import { mainnet } from "viem/chains";

import { LifiForm } from "../common/lifi";



export const Spot = () => (    
  <Stack className="h-100 w-100 mt-4 spot">
    <LifiForm fromToken="ETH" toToken="CTX" fromChain={mainnet.id} toChain={mainnet.id}/>
  </Stack>
);
