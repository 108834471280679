import { PerennialVaultType, chainVaultsWithAddress } from '@perennial/sdk'
import { useQuery } from '@tanstack/react-query'
import { zeroAddress } from 'viem'

import { usePerennialSDKContext } from '../../contexts/perennialSdkContext'

import { useMarketOracles2 } from '../markets2'
import { useAddress, usePerpetualsChainId } from '../network'

export const useVaultSnapshots2 = () => {
  const chainId = usePerpetualsChainId()
  const vaults = chainVaultsWithAddress(chainId)
  const { data: marketOracles } = useMarketOracles2()
  const { address: address_ } = useAddress()
  const sdk = usePerennialSDKContext()

  const address = address_ ?? zeroAddress

  return useQuery({
    enabled: !!vaults && !!vaults.length && !!marketOracles,
    queryKey: ['vaultSnapshots2', chainId, address],
    queryFn: async () => {
      const vaultSnapshots = await sdk.vaults.read.vaultSnapshots({
        address,
        onError: () => {},
        onSuccess: () => {},
      })
      return vaultSnapshots
    },
  })
}


export type VaultPositionHistory = NonNullable<
  Awaited<ReturnType<typeof useVaultPositionHistory>>['data']
>[PerennialVaultType]
export const useVaultPositionHistory = () => {
  const chainId = usePerpetualsChainId()
  const { address } = useAddress()
  const sdk = usePerennialSDKContext()

  return useQuery({
    queryKey: ['vaultPositionHistory', chainId, address],
    enabled: !!chainId && !!address,
    queryFn: async () => {
      if (!address) return undefined
      const vaultPositionHistory = await sdk.vaults.read.vaultPositionHistory({ address })
      return vaultPositionHistory
    },
  })
}
