import {
  Big6Math,
  calcLeverage,
  calcLiquidationPrice,
  calcLpExposure,
  calcNotional,
  calcTotalPositionChangeFee,
  formatBig6,
  formatBig6Percent,
  formatBig6USDPrice,
  isActivePosition,
  isFailedClose,
  MarketMetadata,
  MarketSnapshot,
  PositionSide,
  PositionStatus,
  SupportedChainId,
  SupportedMarket,
  UserMarketSnapshot,
} from '@perennial/sdk';
import { getMakerExposure } from '../../utils/positionUtils'
import { FormattedPositionDetail, OrderTypes } from './constants'

export const calcMaintenance = (price: bigint, position: bigint, maintenanceRate: bigint) => {
  if (Big6Math.isZero(position)) return 0n
  return Big6Math.mul(Big6Math.mul(Big6Math.abs(price), position), maintenanceRate)
}

type CalculateInitialLeverage = {
  isNewPosition: boolean
  amount?: bigint
  currentCollateralAmount?: bigint
  price?: bigint
}

export const calculateInitialLeverage = ({
  isNewPosition,
  amount,
  currentCollateralAmount,
  price,
}: CalculateInitialLeverage) => {
  if (!amount || !currentCollateralAmount || !price) return '0'
  if (isNewPosition) return '1'

  const formattedAmount = Big6Math.toFloatString(amount)
  const parsedPositionAmount = Big6Math.fromFloatString(formattedAmount)
  if (Big6Math.isZero(currentCollateralAmount)) {
    return Big6Math.toFloatString(0n)
  }

  const leverage = calcLeverage(price, parsedPositionAmount, currentCollateralAmount)
  return Big6Math.toFloatString(leverage)
}

export const collateralFromAmountAndLeverage = ({
  currentAmount,
  amount,
  leverage,
  marketSnapshot,
  chainId,
  positionStatus,
  direction,
  interfaceFeeBps,
  settlementFee,
}: {
  currentAmount: bigint
  amount: string
  leverage: string
  marketSnapshot: MarketSnapshot
  chainId: SupportedChainId
  positionStatus: PositionStatus
  direction: PositionSide
  interfaceFeeBps: bigint
  settlementFee: bigint
}) => {
  const parsedLeverage = Big6Math.fromFloatString(leverage)
  if (Big6Math.isZero(parsedLeverage)) return ''

  const {
    global: { latestPrice: price },
  } = marketSnapshot

  const parsedPositionAmount = Big6Math.fromFloatString(amount)
  const notional = calcNotional(parsedPositionAmount, price)
  const fees = calcTotalPositionChangeFee({
    chainId,
    marketSnapshot,
    positionDelta: parsedPositionAmount - currentAmount,
    direction,
    referrerInterfaceFeeDiscount: 0n,
    positionStatus,
    interfaceFeeBps,
    settlementFee,
  })
  // Add fees to collateral amount since they increase the collateral required for a given position
  const newCollateral = Big6Math.div(notional, parsedLeverage) + fees.total
  return Big6Math.toFloatString(newCollateral)
}

export const leverageFromAmountAndCollateral = ({
  currentAmount,
  amount,
  collateral,
  marketSnapshot,
  chainId,
  positionStatus,
  direction,
  interfaceFeeBps,
  settlementFee,
  isLimitOrder,
  limitOrderPrice,
}: {
  currentAmount: bigint
  amount: string
  collateral: string
  marketSnapshot: MarketSnapshot
  chainId: SupportedChainId
  positionStatus: PositionStatus
  direction: PositionSide
  interfaceFeeBps: bigint
  settlementFee: bigint
  isLimitOrder?: boolean
  limitOrderPrice?: string
}) => {
  const parsedCollateralAmount = Big6Math.fromFloatString(collateral)
  if (Big6Math.isZero(parsedCollateralAmount)) {
    return '0'
  }

  const {
    global: { latestPrice },
  } = marketSnapshot
  const parsedPositionAmount = Big6Math.fromFloatString(amount)
  const fees = calcTotalPositionChangeFee({
    chainId,
    positionDelta: parsedPositionAmount - currentAmount,
    marketSnapshot,
    direction,
    positionStatus,
    referrerInterfaceFeeDiscount: 0n,
    interfaceFeeBps,
    settlementFee,
  })
  const newLeverage = calcLeverage(
    isLimitOrder && limitOrderPrice ? Big6Math.fromFloatString(limitOrderPrice) : latestPrice,
    parsedPositionAmount,
    parsedCollateralAmount > fees.total ? parsedCollateralAmount - fees.total : parsedCollateralAmount,
  )
  return Big6Math.toFloatString(newLeverage)
}

export const positionFromCollateralAndLeverage = ({
  currentAmount,
  collateral,
  leverage,
  marketSnapshot,
  chainId,
  positionStatus,
  direction,
  interfaceFeeBps,
  settlementFee,
  isLimitOrder,
  limitOrderPrice,
}: {
  currentAmount: bigint
  collateral: string
  leverage: string
  marketSnapshot: MarketSnapshot
  chainId: SupportedChainId
  positionStatus: PositionStatus
  direction: PositionSide,
  interfaceFeeBps: bigint,
  settlementFee: bigint,
  isLimitOrder?: boolean
  limitOrderPrice?: string
}) => {
  const {
    global: { latestPrice },
  } = marketSnapshot
  const parsedCollateralAmount = Big6Math.fromFloatString(collateral)
  const parsedLeverage = Big6Math.fromFloatString(leverage)
  const price = isLimitOrder && limitOrderPrice ? Big6Math.fromFloatString(limitOrderPrice) : latestPrice
  if (Big6Math.isZero(price)) return ''

  let newPosition = Big6Math.abs(Big6Math.div(Big6Math.mul(parsedLeverage, parsedCollateralAmount), price))

  // Iteratively calculate position size to approach ideal position size for given leverage and fees
  for (let i = 0; i < 10; i++) {
    const fees = calcTotalPositionChangeFee({
      chainId,
      positionDelta: newPosition - currentAmount,
      marketSnapshot,
      direction,
      positionStatus,
      referrerInterfaceFeeDiscount: 0n,
      interfaceFeeBps,
      settlementFee,
    })

    newPosition = Big6Math.abs(Big6Math.div(Big6Math.mul(parsedLeverage, parsedCollateralAmount - fees.total), price))
  }

  return Big6Math.toFloatString(newPosition)
}

export const calcCollateralDifference = (newCollateralAmount: bigint, currentCollateralAmount: bigint): bigint => {
  return newCollateralAmount - currentCollateralAmount
}

export const calcPositionDifference = (newPositionAmount: bigint, currentPositionAmount: bigint): bigint => {
  return newPositionAmount - currentPositionAmount
}

export const calcLeverageDifference = (newLeverage: bigint, currentLeverage: bigint): bigint => {
  return newLeverage - currentLeverage
}

export const needsApproval = ({
  collateralDifference,
  usdcAllowance,
  interfaceFee,
}: {
  collateralDifference: bigint
  usdcAllowance: bigint
  interfaceFee: bigint
}) => {
  const approvalAmount = Big6Math.max(collateralDifference, interfaceFee)
  return { needsApproval: Big6Math.fromDecimals(usdcAllowance, 6) < approvalAmount, approvalAmount }
}

export const calcPositionFee = (price: bigint, positionDelta: bigint, feeRate: bigint) => {
  return Big6Math.abs(Big6Math.mul(Big6Math.mul(price, positionDelta), feeRate))
}

type InitialInputs = {
  userCollateral?: bigint
  amount?: bigint
  price?: bigint
  isNewPosition: boolean
  isConnected: boolean
  isFailedClose?: boolean
}

export const formatInitialInputs = ({
  userCollateral,
  amount,
  price,
  isNewPosition,
  isConnected,
  isFailedClose,
}: InitialInputs) => {
  if (!isConnected)
    return {
      collateral: '',
      amount: '',
      leverage: '1',
    }
  return {
    collateral: userCollateral ? (userCollateral === 0n ? '0' : Big6Math.toFloatString(userCollateral)) : '',
    amount: amount ? (amount === 0n ? '0' : Big6Math.toFloatString(amount)) : isFailedClose ? '0' : '',
    leverage: calculateInitialLeverage({ isNewPosition, amount, currentCollateralAmount: userCollateral, price }),
  }
}

/* MaxLeverage is the minimum of the following:
  min(100x, 1/margin, collateral/minCollateralForFullRange * 1/margin)
*/
export const calcMaxLeverage = ({
  margin,
  minMargin,
  collateral,
}: { margin?: bigint; minMargin?: bigint; collateral?: bigint } = {}) => {
  if (!margin) return 10
  const marginMaxLeverage = Big6Math.div(Big6Math.ONE, margin)
  const minCollateralForFullRange = Big6Math.mul(minMargin ?? 0n, marginMaxLeverage)
  const collateralMaxLeverage = !!collateral
    ? Big6Math.div(Big6Math.mul(collateral, marginMaxLeverage), minCollateralForFullRange)
    : marginMaxLeverage

  const maxLeverage = Big6Math.min(marginMaxLeverage, collateralMaxLeverage)

  const flooredLeverage = Math.floor(Big6Math.toUnsafeFloat(Big6Math.min(maxLeverage, Big6Math.ONE * 100n)))
  // Round to nearest 5x
  return flooredLeverage < 5 ? flooredLeverage : Math.floor(flooredLeverage / 5) * 5
}

export const isFullClose = (closeAmount: string, currPosition: bigint) => {
  return Big6Math.eq(Big6Math.fromFloatString(closeAmount), Big6Math.abs(currPosition))
}

export const calcTradeFeeApr = ({
  fees7Day,
  makerOi,
  collateral,
  notional,
}: {
  fees7Day: bigint
  makerOi: bigint
  collateral: bigint
  notional: bigint
}) => {
  if (!fees7Day || !makerOi || !collateral || !notional) return 0n
  const dailyAvgFee = Big6Math.div(fees7Day, Big6Math.fromDecimals(7n, 0))
  const annualFees = Big6Math.mul(dailyAvgFee, Big6Math.fromDecimals(365n, 0))
  const annualFeesPerUser = Big6Math.mul(annualFees, notional)
  const denominator = Big6Math.mul(makerOi, collateral)
  return Big6Math.div(annualFeesPerUser, denominator)
}

export const removeOrderType = (orderType: OrderTypes, orderTypes: OrderTypes[]) => {
  return orderTypes.filter((type) => type !== orderType)
}

// nuevo

export const getFormattedPositionDetails = ({
  userMarketSnapshot,
  marketSnapshot,
  numSigFigs,
  placeholderString,
}: {
  userMarketSnapshot?: UserMarketSnapshot
  marketSnapshot?: MarketSnapshot
  numSigFigs: number
  placeholderString: string
}) => {
  if (!userMarketSnapshot || !isActivePosition(userMarketSnapshot)) {
    return {
      currentCollateral: placeholderString,
      startCollateral: placeholderString,
      position: placeholderString,
      nextPosition: placeholderString,
      liquidationPrice: placeholderString,
      unformattedLiquidationPrice: placeholderString,
      notional: placeholderString,
      nextNotional: placeholderString,
      unformattedNotional: placeholderString,
      leverage: placeholderString,
      nextLeverage: placeholderString,
      fees: placeholderString,
      liquidationFee: placeholderString,
      exposure: placeholderString,
      exposureSide: placeholderString,
      failedClose: false,
    }
  }
  const failedClose = isFailedClose(userMarketSnapshot)

  const liquidationPrices = calcLiquidationPrice({
    marketSnapshot,
    position: failedClose ? userMarketSnapshot?.magnitude : userMarketSnapshot?.nextMagnitude,
    collateral: userMarketSnapshot?.local?.collateral,
  })

  const liquidationPrice =
    userMarketSnapshot.nextSide === PositionSide.long ? liquidationPrices.long : liquidationPrices.short

  const below1xLeverage = userMarketSnapshot?.nextLeverage <= Big6Math.ONE

  const lpExposure = calcLpExposure(marketSnapshot)
  const isMakerPosition = userMarketSnapshot?.side === PositionSide.maker
  const makerExposure = isMakerPosition
    ? getMakerExposure(
        lpExposure?.lpExposure,
        failedClose ? userMarketSnapshot?.leverage : userMarketSnapshot?.nextLeverage,
      )
    : 0n

  return {
    currentCollateral: formatBig6USDPrice(userMarketSnapshot?.local?.collateral),
    startCollateral: formatBig6USDPrice(userMarketSnapshot?.local?.collateral), // TODO: await graph
    position: formatBig6(userMarketSnapshot?.magnitude, { numSigFigs }),
    nextPosition: formatBig6(userMarketSnapshot?.nextMagnitude, { numSigFigs }),
    liquidationPrice: below1xLeverage ? placeholderString : formatBig6USDPrice(liquidationPrice),
    unformattedLiquidationPrice: placeholderString, // TODO: implement
    notional: formatBig6USDPrice(userMarketSnapshot?.notional),
    nextNotional: formatBig6USDPrice(userMarketSnapshot?.nextNotional),
    unformattedNotional: placeholderString, // TODO: implement
    leverage: formatBig6(userMarketSnapshot?.leverage),
    nextLeverage: formatBig6(userMarketSnapshot?.nextLeverage),
    fees: placeholderString, // TODO: implement
    liquidationFee: placeholderString, // TODO: implement
    makerExposure: formatBig6Percent(makerExposure, { numDecimals: 2 }),
    exposureSide: lpExposure?.exposureSide ?? placeholderString,
    failedClose,
  }
}

export const transformPositionDataToArray = (
  userPositions?: Record<SupportedMarket, UserMarketSnapshot>,
  isMaker?: boolean,
) => {
  const result: FormattedPositionDetail[] = []
  if (!userPositions) return result
  for (const [_asset, positionData] of Object.entries(userPositions)) {
    const asset = _asset as SupportedMarket
    const symbol = MarketMetadata[asset].symbol
    if (isActivePosition(positionData)) {
      if (isMaker && (positionData.side === PositionSide.maker || positionData.nextSide === PositionSide.maker)) {
        result.push({ asset, symbol, details: positionData })
      }
      if (!isMaker && positionData.side !== PositionSide.maker) {
        result.push({ asset, symbol, details: positionData })
      }
    }
  }
  return result
}
