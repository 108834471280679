import React, { useState } from "react"
import { Button, Form, Stack } from "react-bootstrap/esm"
import { Big6Math, PositionSide } from '@perennial/sdk'

import { useMarketContext } from "../../../../../contexts"
import { useLivePriceContext } from "../../../../../contexts/livePriceContext"
import { FormNames, OrderTypes } from "../../../constants"
import { useMediaQuery } from "react-responsive"
import { limitOrderPercents, stopLossPercents, takeProfitPercents } from "./constants"
import { PercentsBox } from "../common"
import { TokenIcon } from "../../../../common"
import { useTranslation } from "react-i18next"


interface TriggerOrderProps {
  onChangeStopLoss: (value: string) => void
  onChangeTakeProfit: (value: string) => void
  stopLossPrice: string
  takeProfitPrice: string
  stopLossError: string
  takeProfitError: string
  latestPrice: bigint
  isFormDirty: boolean
  orderDirection: PositionSide.long | PositionSide.short
  amount: string
  collateral: string
  isLimit: boolean
  addOrRemoveOrderType?: (orderType: OrderTypes, add: boolean) => void
}


export const TriggerOrderGroup = ({
  onChangeStopLoss,
  onChangeTakeProfit,
  stopLossPrice,
  takeProfitPrice,
  stopLossError,
  takeProfitError,
  latestPrice,
  orderDirection,
  isFormDirty,
  amount,
  collateral,
  isLimit,
  addOrRemoveOrderType
}: TriggerOrderProps) => {

  return (
    <Stack className="trigger-order-box" gap={2} direction="vertical">
      <TriggerPriceInput
        name={FormNames.stopLoss}
        label="Stop Loss"
        onChange={onChangeStopLoss}
        latestPrice={latestPrice}
        percentValues={orderDirection === PositionSide.long ? stopLossPercents : takeProfitPercents}
        isFormDirty={isFormDirty}
        triggerPrice={stopLossPrice}
        valueError={stopLossError}
        amount={amount}
        collateral={collateral}
        orderDirection={orderDirection}
        isLimit={isLimit}
        addOrRemoveOrderType={addOrRemoveOrderType}
      />
      <TriggerPriceInput
        name={FormNames.takeProfit}
        label="Take Profit"
        onChange={onChangeTakeProfit}
        latestPrice={latestPrice}
        percentValues={orderDirection === PositionSide.long ? takeProfitPercents : stopLossPercents}
        isFormDirty={isFormDirty}
        triggerPrice={takeProfitPrice}
        valueError={takeProfitError}
        amount={amount}
        collateral={collateral}
        orderDirection={orderDirection}
        isLimit={isLimit}
        addOrRemoveOrderType={addOrRemoveOrderType}
      />
    </Stack>
  )
}

interface TriggerLimitOrderProps {
  onChangeLimit: (value: string) => void
  limitPrice: string
  limitError: string
  latestPrice: bigint
  isFormDirty: boolean
  orderDirection: PositionSide.long | PositionSide.short
  amount: string
  collateral: string
  percent?: string
  onPercentChange?: (value: string) => void
  addOrRemoveOrderType?: (orderType: OrderTypes, add: boolean) => void
  showByDefault?: boolean
}

export const TriggerLimitOrder = ({
  onChangeLimit,
  limitPrice,
  limitError,
  latestPrice,
  isFormDirty,
  orderDirection,
  amount,
  collateral,
  percent,
  onPercentChange,
  addOrRemoveOrderType,
  showByDefault,
}: TriggerLimitOrderProps) => {
  const { t } = useTranslation()
  let percents = limitOrderPercents
  if (orderDirection === PositionSide.long) {
    percents = limitOrderPercents.map((value: string) => {
      return (parseFloat(value) * -1).toFixed(0)
    }) 
  }

  return (
    <Stack className="trigger-order-box limit-order" gap={2} direction="vertical">
      <TriggerPriceInput
        name={FormNames.limitPrice}
        label={t("trigger-price")}
        onChange={onChangeLimit}
        latestPrice={latestPrice}
        percentValues={percents}
        isFormDirty={isFormDirty}
        triggerPrice={limitPrice}
        valueError={limitError}
        amount={amount}
        collateral={collateral}
        orderDirection={orderDirection}
        isLimit={true}
        percent={percent}
        onPercentChange={onPercentChange}
        addOrRemoveOrderType={addOrRemoveOrderType}
        showByDefault={showByDefault}
      />
    </Stack>
  )
}


interface TriggerOrderInputProps {
  onChange: (value: string) => void
  name: FormNames
  label: string
  percentValues: string[]
  latestPrice: bigint
  isFormDirty: boolean
  triggerPrice: string
  valueError: string
  amount: string
  collateral: string
  orderDirection: PositionSide.long | PositionSide.short
  isLimit: boolean
  percent?: string
  onPercentChange?: (value: string) => void
  addOrRemoveOrderType?: (orderType: OrderTypes, add: boolean) => void
  showByDefault?: boolean
}

export const TriggerPriceInput = ({
  onChange,
  name,
  label,
  percentValues,
  latestPrice,
  triggerPrice,
  valueError,
  amount,
  collateral,
  orderDirection,
  isLimit,
  percent,
  onPercentChange,
  addOrRemoveOrderType,
  showByDefault,
}: TriggerOrderInputProps) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const [showInput, setShowInput] = useState(showByDefault)
  // const [percentChange, setPercentChange] = useState('0')
  const { selectedMarket } = useMarketContext()
  const livePrices = useLivePriceContext()
  const indexPrice = livePrices[selectedMarket]?.price ?? latestPrice

  const onPercentClick = (percent: string) => {
    if (name === FormNames.limitPrice) {
      if (onPercentChange) {
        onPercentChange(percent)
      }
    } else {
      const priceAsFloat = Big6Math.toUnsafeFloat(indexPrice)
      const newAmount =
        Big6Math.toUnsafeFloat(isLimit && latestPrice ? latestPrice : indexPrice) * (Number(percent) / 100)
      const newValue = priceAsFloat + newAmount
      onChange(`${newValue}`)
    }
  }

  if (!showInput) {
    return (
      <Button
        className="btn-trigger-add"
        variant="primary"
        onClick={() => {
          setShowInput(true)
          if (addOrRemoveOrderType) {
            if (name !== FormNames.limitPrice) {
              addOrRemoveOrderType(name === FormNames.stopLoss ? OrderTypes.stopLoss : OrderTypes.takeProfit, true)
            }
          }
        }}
      >
        <span>{label}</span>
        <span className="text-purple">{t("add")}</span>
      </Button>
    )
  }

  return (
    <Stack direction="vertical" gap={1} className="trigger-price-box">
      <Stack direction="horizontal" className="trigger-price-header">
        <h6>{label}</h6>
        <PercentsBox percentValues={percentValues} onPercentClick={onPercentClick} />
      </Stack>
      <Stack direction="horizontal" className="trigger-price-group" gap={2}>
        <Stack direction="vertical" className="trigger-price-group-2" style={{ width: "65%" }}>
          <Form.Control
            placeholder="0"
            value={triggerPrice}
            type={isMobile ? "number" : "text"}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const { value } = event.target
              onChange(value)
            }}
          />
          {name === FormNames.limitPrice && <TokenIcon name="usdc" size="medium" />}
          {/* <div className="percent-from-index">
            <span>{percentChange}% from index</span>
          </div> */}
        </Stack>
        {name === FormNames.limitPrice ? (
          <Stack direction="horizontal" className="trigger-price-group-2" style={{ width: "35%" }}>
            <Form.Control
              placeholder="0"
              value={percent}
              type={isMobile ? "number" : "text"}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (onPercentChange) {
                  onPercentChange(event.target.value)
                }
              }}
            />
            <span className="text-muted" style={{ fontSize: "22px" }} >%</span>
          </Stack>
        ): (
          <Button
            variant="outline"
            className="btn-close-trigger"
            onClick={() => {
              onChange("0")
              // setPercentChange("0")
              setShowInput(false)
              if (addOrRemoveOrderType) {
                addOrRemoveOrderType(name === FormNames.stopLoss ? OrderTypes.stopLoss : OrderTypes.takeProfit, false)                
              }
            }}
          >
            {t("cancel")}
          </Button>
        )} 
      </Stack>
      {valueError !== "" && (
        <span className="error">
          {valueError}
        </span>
      )}
    </Stack>
  )
}