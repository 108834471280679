import React from "react"
import { Modal, Tab, Tabs } from "react-bootstrap/esm"

import { usePushContext } from "../../contexts"
import { SubscriptionManager } from "./SubscriptionManger";
import { Notifications } from "./notifications";


export const PushNotifications = ({ showModal, onModalHide } : { showModal: boolean, onModalHide: () => void  }) => {
  const pushAPI = usePushContext();

  return (
    <>
      
        <Modal
          show={showModal}
          onHide={() => {
            pushAPI.loadSubscriptions();
            onModalHide();
          }}
          className="push-modal"
        >
          <Modal.Header closeButton>
            <h5>Cryptex Notifications</h5>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="push-tabs" defaultActiveKey="manage" style={{ display: "flex", flexDirection: "row" }}>
              <Tab eventKey="manage" title="Manage">
                <SubscriptionManager
                  onHide={() => {
                    pushAPI.loadSubscriptions();
                    onModalHide();
                  }}
                />
              </Tab>
              <Tab eventKey="inbox" title="Notifications">
                <Notifications />
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      
    </>
  )
};
