import React, { useMemo } from "react"
import { Button, Image, Stack } from "react-bootstrap"
import { arbitrum, mainnet } from  "viem/chains"
import { useSwitchChain } from "wagmi"
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { useTranslation } from "react-i18next"

import { useMediaQuery } from "react-responsive"
import { FaAngleDown } from "react-icons/fa"
import { useCryptexProductsContext } from "../contexts"
import { useNativeBalance } from "../hooks/wallet"
import { formatUnits } from "viem"


export const CustomConnectButton = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const { data } = useNativeBalance()
  const { switchChain } = useSwitchChain();
  const { isGovernance, isSpot, isProductSupportedChain } = useCryptexProductsContext()

  const formattedBalance = useMemo(() => {
    if (data) {
      return `${parseFloat(formatUnits(data.value, data.decimals)).toFixed(2)} ${data.symbol}`
    }
  }, [data])

  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted
        const connected =
          ready &&
          account &&
          chain;
        
        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              'style': {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <Button
                    onClick={openConnectModal}
                    style={{
                      backgroundColor: "#A440F2",
                      fontWeight: "bold",
                      borderRadius: "3px",
                    }}
                    className="connect-button pt-1 pb-1 ps-3 pe-3"
                  >
                    {t("connect-wallet")}
                  </Button>
                );
              }

              return (
                <Stack direction="horizontal" gap={0}>
                  <Button
                    onClick={openAccountModal}
                    className="border-black"
                    style={{ padding: "0px" }} 
                  >
                    <Stack direction="horizontal" gap={1}>
                      <span style={{ backgroundColor: "#0a0a0a", fontWeight: "bold", padding: "3px 1px" }}>
                        {formattedBalance && !isMobile ? ` ${formattedBalance}` : ''}
                      </span>
                      <Stack
                        direction="horizontal"
                        gap={1}
                        style={{ backgroundColor: '#111111', padding: "3px 7px" }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {account.displayName}
                        </span>
                        <FaAngleDown size={16} />
                      </Stack>  
                    </Stack>
                  </Button>
                  {!isSpot() && !isProductSupportedChain(chain.id) ? (
                    <Button
                      onClick={() => {
                        isGovernance() ? switchChain({ chainId: mainnet.id }) : switchChain({ chainId: arbitrum.id })
                      }}
                      className="btn-wrong-network pt-1 pb-1"
                    >
                      Switch Chain
                    </Button>
                  ) : (
                    <Button
                      onClick={openChainModal}
                      className="border-black"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Stack direction="horizontal" gap={1}>
                        {chain.hasIcon && chain.iconUrl && (
                          <Image
                            alt={chain.name ?? 'Chain icon'}
                            src={chain.iconUrl}
                            style={{ width: "20px", height: "20px" }}
                          />
                        )}
                        {(!isMobile || !chain.hasIcon) && (
                          <span style={{ fontWeight: "bold" }}>
                            {chain.name}
                          </span>
                        )}
                        <FaAngleDown size={16} />
                      </Stack>
                    </Button>
                  )}
                </Stack>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};