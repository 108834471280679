import {
  Big6Math,
  formatBig6Percent,
  PositionStatus,
  TriggerComparison,
  calcNotional,
  isActivePosition,
  isFailedClose,
  MarketSnapshot,
  PositionSide,
  UserMarketSnapshot,
} from '@perennial/sdk';

import { FormattedOpenOrder } from "../components/Trade/hooks";
import { CurrentSTIPSeason, STIPDropParams, STIPSeasonNumber } from "../constants/stipDrop";
import { OrderValues } from '../components/Trade/constants';


export const calcTradeFeeApr = ({
  fees7Day,
  makerOi,
  collateral,
  notional,
}: {
  fees7Day: bigint;
  makerOi: bigint;
  collateral: bigint;
  notional: bigint;
}) => {
  if (!fees7Day || !makerOi || !collateral || !notional) return 0n;
  const dailyAvgFee = Big6Math.div(fees7Day, Big6Math.fromDecimals(7n, 0));
  const annualFees = Big6Math.mul(dailyAvgFee, Big6Math.fromDecimals(365n, 0));
  const annualFeesPerUser = Big6Math.mul(annualFees, notional);
  const denominator = Big6Math.mul(makerOi, collateral);
  return Big6Math.div(annualFeesPerUser, denominator);
};

export function calcLpUtilization(marketSnapshot?: MarketSnapshot) {
  if (!marketSnapshot) return undefined;
  const {
    majorSide,
    minorSide,
    nextPosition: { long, short, maker },
  } = marketSnapshot;

  const majorPosition = majorSide === PositionSide.long ? long : short;
  const minorPosition = majorSide === PositionSide.long ? short : long;

  const lpUtilization =
    maker > 0n
      ? Big6Math.div(majorPosition - minorPosition, maker)
      : 0n;

  return {
    lpUtilization,
    formattedLpUtilization: formatBig6Percent(lpUtilization, {
      numDecimals: 2,
    }),
    exposureSide: minorSide,
  };
}

export function calcLpExposure(marketSnapshot?: MarketSnapshot) {
  if (!marketSnapshot) return undefined
  const {
    majorSide,
    minorSide,
    nextPosition: { long, short, maker },
  } = marketSnapshot

  const majorPosition = majorSide === PositionSide.long ? long : short
  const minorPosition = majorSide === PositionSide.long ? short : long

  const lpExposure = maker > 0n ? Big6Math.div(majorPosition - minorPosition, maker) : 0n

  return {
    lpExposure: lpExposure,
    formattedLpExposure: formatBig6Percent(lpExposure, { numDecimals: 2 }),
    exposureSide: minorSide,
  }
}

export const getMakerExposure = (lpUtililization?: bigint, leverage?: bigint) => {
  if (!lpUtililization || !leverage) return 0n
  return Big6Math.mul(lpUtililization, leverage)
}

export const calcSTIPFeeRebate = ({
  takerNotional,
  takerFeeBps,
  positionFee,
  season = CurrentSTIPSeason,
}: {
  takerNotional: bigint
  takerFeeBps: bigint
  positionFee: bigint
  season?: STIPSeasonNumber
}) => {
  const seasonData = STIPDropParams[season].taker
  const fee = Big6Math.min(Big6Math.mul(takerFeeBps, takerNotional), positionFee)

  return Big6Math.mul(fee, seasonData.feeRebatePct)
}


export const getOpenOrderLabel = ({
  orderDelta,
  orderDirection,
  comparison,
  isMaker,
}: {
  orderDelta: bigint
  orderDirection: PositionSide
  comparison: TriggerComparison
  isMaker: boolean
}) => {
  if (isMaker) {
    return orderDelta > 0n ? 'limitOpen' : 'limitClose'
  }
  if (orderDelta > 0n) {
    return 'limitOpen'
  }
  if (orderDirection === PositionSide.long) {
    return comparison === TriggerComparison.lte ? 'stopLoss' : 'takeProfit'
  } else {
    return comparison === TriggerComparison.gte ? 'stopLoss' : 'takeProfit'
  }
}

export const isOpenOrderValid = ({
  order,
  allOrders,
  userMarketSnapshot,
  marketSnapshot,
}: {
  order: FormattedOpenOrder
  allOrders: FormattedOpenOrder[]
  userMarketSnapshot?: UserMarketSnapshot
  marketSnapshot?: MarketSnapshot
}) => {
  if (!userMarketSnapshot || !marketSnapshot)
    return { isValid: true, limitOpens: 0, pendingOrderSize: 0n, hasOpenPosition: false }
  const snapshotPosition = isFailedClose(userMarketSnapshot)
    ? userMarketSnapshot.magnitude
    : userMarketSnapshot.nextMagnitude

  if (order.details.triggerOrderSide === 3) {
    // TODO: collateral checks
    return { isValid: true, limitOpens: 0, pendingOrderSize: 0n, hasOpenPosition: false }
  }

  const currentSide = isFailedClose(userMarketSnapshot) ? userMarketSnapshot.side : userMarketSnapshot.nextSide

  if (currentSide !== PositionSide.none && order.side !== currentSide) {
    return { isValid: false, limitOpens: 0, pendingOrderSize: 0n, hasOpenPosition: snapshotPosition > 0n }
  }

  if (BigInt(order.details.triggerOrderDelta) > 0n) {
    const { collateral } = userMarketSnapshot.local
    // Subsitute 1n for collateral if none present to bypass 0 collateral check in calcLeverage
    const {
      riskParameter: { margin, minMargin },
    } = marketSnapshot
    const reqMargin = Big6Math.max(
      minMargin,
      Big6Math.mul(
        calcNotional(BigInt(order.details.triggerOrderDelta), BigInt(order.details.triggerOrderPrice)),
        margin,
      ),
    )

    return {
      isValid: collateral >= reqMargin,
      limitOpens: 0,
      pendingOrderSize: 0n,
      hasOpenPosition: snapshotPosition > 0n,
    }
  }

  let limitOpens = 0
  const totalPendingOrderSize = allOrders.reduce((acc, pendingOrder) => {
    if (
      order.details.nonce === pendingOrder.details.nonce ||
      pendingOrder.details.market !== order.details.market ||
      pendingOrder.details.triggerOrderSide > 2 ||
      pendingOrder.details.triggerOrderSide !== order.details.triggerOrderSide ||
      BigInt(pendingOrder.details.triggerOrderDelta) <= 0n
    ) {
      return acc
    }
    limitOpens += 1
    return acc + BigInt(pendingOrder.details.triggerOrderDelta)
  }, 0n)

  return {
    isValid: snapshotPosition >= (totalPendingOrderSize + BigInt(order.details.triggerOrderDelta)) * -1n,
    limitOpens,
    pendingOrderSize: totalPendingOrderSize,
    hasOpenPosition: !Big6Math.isZero(snapshotPosition),
  }
}
// const noOpEstimate = (_: MarketSnapshots, __?: bigint) => 0n


export const calcMakerStats2 = ({
  funding,
  interest,
  positionFee,
  positionSize,
  collateral,
}: {
  funding: bigint
  interest: bigint
  positionFee: bigint
  positionSize: bigint
  collateral: bigint
}) => {
  if (collateral === 0n) return { fundingAPR: 0n, interestAPR: 0n, positionFeeAPR: 0n }
  const fundingAccumulated = Big6Math.mul(funding, positionSize)
  const interestAccumulated = Big6Math.mul(interest, positionSize)
  const positionFeeAccumulated = Big6Math.mul(positionFee, positionSize)

  return {
    fundingAPR: Big6Math.div(fundingAccumulated * 52n, collateral),
    interestAPR: Big6Math.div(interestAccumulated * 52n, collateral),
    positionFeeAPR: Big6Math.div(positionFeeAccumulated * 52n, collateral),
  }
}

export const getOrderValuesFromPosition = ({
  userMarketSnapshot,
  marketSnapshot,
}: {
  userMarketSnapshot?: UserMarketSnapshot
  marketSnapshot?: MarketSnapshot
}) => {
  if (!marketSnapshot || !userMarketSnapshot) return undefined

  const nextAmount = userMarketSnapshot?.nextMagnitude ?? 0n
  const orderValues = {
    collateral: Big6Math.toFloatString(userMarketSnapshot?.local.collateral ?? 0n),
    amount: Big6Math.toFloatString(nextAmount),
    leverage: Big6Math.toFloatString(userMarketSnapshot?.nextLeverage ?? 0n),
  } as OrderValues

  const positionDelta = userMarketSnapshot.nextMagnitude - userMarketSnapshot.magnitude

  return {
    market: marketSnapshot as MarketSnapshot,
    position: userMarketSnapshot as UserMarketSnapshot,
    asset: marketSnapshot.market,
    positionSide: nextAmount === 0n ? userMarketSnapshot.side : userMarketSnapshot.nextSide,
    orderValues,
    positionDelta,
  }
}

export const getStatusDetails = ({
  userMarketSnapshot,
  liquidated,
  isMaker,
}: {
  userMarketSnapshot?: UserMarketSnapshot
  liquidated?: boolean
  isMaker?: boolean
}) => {
  const noValue = {
    isOpenPosition: false,
    isTransitionPosition: false,
    statusColor: 'darkGray',
    status: PositionStatus.resolved,
    directionTextColor: 'darkGray',
    isClosing: false,
    hasPosition: false,
  }
  if (!userMarketSnapshot || !isActivePosition(userMarketSnapshot)) return noValue

  if (
    isMaker &&
    !(userMarketSnapshot.side === PositionSide.maker || userMarketSnapshot.nextSide === PositionSide.maker)
  )
    return noValue

  if (
    !isMaker &&
    (userMarketSnapshot.side === PositionSide.maker || userMarketSnapshot.nextSide === PositionSide.maker)
  )
    return noValue

  const { status } = userMarketSnapshot
  const isOpenPosition = [
    PositionStatus.open,
    PositionStatus.pricing,
    PositionStatus.closing,
    PositionStatus.opening,
  ].includes(status)
  const isTransitionPosition = [PositionStatus.pricing, PositionStatus.opening, PositionStatus.closing].includes(status)
  const hasPosition = PositionStatus.resolved !== status
  const isClosing = status === PositionStatus.closing

  return {
    isOpenPosition,
    isTransitionPosition,
    status,
    hasPosition,
    isClosing,
  }
}
