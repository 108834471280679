import React, { useContext, useEffect, useState } from "react";
import { PushAPI, CONSTANTS } from "@pushprotocol/restapi"
import { useWalletClient } from "wagmi";
import { arbitrum, sepolia } from "wagmi/chains";

import { ChannelAddress } from "../components/push/constants";

const chainId = process.env.REACT_APP_PUSH_ENV !== "staging" ? arbitrum.id : sepolia.id;

export interface IPushContext {
  channelApi: PushAPI | undefined;
  setCurrentChannelAPI: (pushApi: PushAPI) => void;
  channelInfo: any;
  subscriberApi: PushAPI | undefined,
  setCurrentSubscriberAPI: (pushApi: PushAPI) => void;
  subscribtions: Array<any>;
  setCurrentSubscriptions: (subscriptions: Array<any>) => void;
  getChannelCaipAddress: () => string;
  loadSubscriptions: () => void;
  isSubscribed: (channelAddress: string) => boolean;
  getSubscription: (channelAddress: string) => any;
  hasSigner: () => boolean;
};

export const PushContext = React.createContext<IPushContext>({
  channelApi: undefined,
  setCurrentChannelAPI: (pushApi: PushAPI) => {},
  channelInfo: undefined,
  subscriberApi: undefined,
  setCurrentSubscriberAPI: (pushApi: PushAPI) => {},
  subscribtions: [],
  setCurrentSubscriptions: (subscriptions: Array<any>) => {},
  getChannelCaipAddress: () => { return `eip155:${chainId}:${ChannelAddress}` },
  loadSubscriptions: () => {},
  isSubscribed: (channelAddress: string) => false,
  getSubscription: (channelAddress: string) => undefined,
  hasSigner: () => false,
});


export const PushProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: walletClient } = useWalletClient();
  const [channelApi, setChannelApi] = useState<PushAPI | undefined>();
  const [channelInfo, setChannelInfo] = useState<any | undefined>();
  const [subscriberApi, setSubscriberApi] = useState<PushAPI | undefined>();
  const [subscriptions, setSubscriptions] = useState<Array<any>>([]);

  useEffect(() => {
    const load = async () => {
      const channelAPI = await PushAPI.initialize(null, {
        env: process.env.REACT_APP_PUSH_ENV !== "staging" ? CONSTANTS.ENV.PROD : CONSTANTS.ENV.STAGING,
        account: ChannelAddress,
      });
      setCurrentChannelAPI(channelAPI);
      const channelInfo = await channelAPI.channel.info();
      setChannelInfo(channelInfo);
    }
    load()
  }, [])

  useEffect(() => {
    const load = async () => {
      if (walletClient && !subscriberApi) {
        const subscriberAPI = await PushAPI.initialize(null, {
          env: process.env.REACT_APP_PUSH_ENV !== "staging" ? CONSTANTS.ENV.PROD : CONSTANTS.ENV.STAGING,
          account: walletClient.account.address,
        });
        setCurrentSubscriberAPI(subscriberAPI);
      }

      await loadSubscriptions();
    }
    load();
  },
    // eslint-disable-next-line
    [walletClient, subscriberApi]
  )

  const setCurrentChannelAPI = (pushApi: PushAPI): void => {
    setChannelApi(pushApi);
  }

  const setCurrentSubscriberAPI = (pushApi: PushAPI): void => {
    setSubscriberApi(pushApi);
  }

  const setCurrentSubscriptions = (subscriptions: Array<any>): void => {
    setSubscriptions(subscriptions);
  }

  const getChannelCaipAddress = (): string => {
    return `eip155:${chainId}:${channelInfo.channel}`;
  }

  const loadSubscriptions = async () => { 
    if (subscriberApi) {
      const subscriptions = await subscriberApi.notification.subscriptions();
      setCurrentSubscriptions(subscriptions);
    }
  }

  const getSubscription = (channelAddress: string): any => { 
    return subscriptions.find((sub) => sub.channel === channelAddress);
  }

  const hasSigner = (): boolean => {
    return !!subscriberApi && subscriberApi.signer !== null;
  }

  return (
    <PushContext.Provider
      value={{
        channelApi,
        setCurrentChannelAPI,
        channelInfo,
        subscriberApi,
        setCurrentSubscriberAPI,
        subscribtions: subscriptions,
        setCurrentSubscriptions,
        getChannelCaipAddress,
        loadSubscriptions,
        isSubscribed: (channelAddress: string) => subscriptions.some((sub) => sub.channel === channelAddress),
        getSubscription,
        hasSigner,
      }}
    >
      {children}
    </PushContext.Provider>
  )
};

export const usePushContext = () => {
  const context = useContext(PushContext)
  if (context === undefined) {
    throw new Error('usePushContext must be used within a PushProvider')
  }
  return context
}
