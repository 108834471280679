import React, { useContext, useEffect, useState } from "react"
import { Button, Spinner, Table } from "react-bootstrap/esm"
import { ethers } from "ethers"
import { NumericFormat } from "react-number-format"
import { useMediaQuery } from "react-responsive"
import { useTranslation } from "react-i18next"

import { ClosePosition, UpdatePosition, SharePosition } from "../modals"
import { POSITION_STATUS, TCAP_MARKET } from "../../utils/constants"
import {
  useActiveProvider,
  useProductsSnapshots,
  useUserAllPositionsChanges,
} from "../../hooks";
import { positionStatsContext } from "../../states";
import {
  calculateLatestPositionPnl,
  getLiquidationPrice,
  getRateDisplay,
  isLongPosition,
  nextPosition,
  userPositionSize,
  getMarketByProduct,
  BigMath,
} from "../../utils/utils"
import { getProductContract } from "../../utils/contracts"
import { OpenPositionType, TokenType } from "../../utils/types"
import { CustomTooltip, TokenIcon } from "../../../components/common"


type props = {
  ownerAddress: string;
  positionType: string;
  userSnapshots: any;
  openProducts: Array<string>;
  isLoadingPositions: boolean;
}

type PositionType = {
  productAddress: string;
  market: TokenType;
  isLong: boolean;
  positionSize: string;
  pendingPositionSize: string;
  exposure: string;
  initialCollateral: string;
  collateral: string;
  pnl: string;
  lastEntryPrice: string;
  liquidationPrice: string;
  hourlyRate: string;
  liquidatable: boolean;
  liquidating: boolean;
  closing: boolean;
  liquidated: boolean;
  status: string;
}

export const CurrentPositions = ({ ownerAddress, positionType, userSnapshots, openProducts, isLoadingPositions }: props) => {
  const { t } = useTranslation()
  const isSmallScreen = useMediaQuery({ query: "(min-width: 851px) and (max-width: 1700px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 850px)" });
  const { chainId, multiCallProvider } = useActiveProvider();
  const positionStats = useContext(positionStatsContext);
  const { data: productsSnapshots } = useProductsSnapshots();
  const { allPositionsChanges, loadingData: loadingPChanges } =
    useUserAllPositionsChanges(positionType === OpenPositionType.Taker, openProducts);
  const [positions, setPositions] = useState(new Array<PositionType>());
  const [showPositionUpdate, setShowPositionUpdate] = useState(false);
  const [showPositionClose, setShowPositionClose] = useState(false);
  const [showShareTweet, setShowShareTweet] = useState(false);
  const [currentProductAddress, setCurrentProductAddress] = useState(TCAP_MARKET.longContract[chainId].address);
  const [totalExposure, setTotalExposure] = useState(0);
  const [totalCollateral, setTotalCollateral] = useState(0);
  const [totalPnL, setTotalPnL] = useState(0);

  const calculateHourlyRate = async (productAddress: string) => {
    let nextFunding = 0n;
    if (productsSnapshots) {
      const product = productsSnapshots[productAddress];
      const nextGlobalPosition = nextPosition(product.pre, product.position);
      const productContract = getProductContract(
        multiCallProvider,
        productAddress
      );
      const rate = await productContract.rate(nextGlobalPosition);
      nextFunding = BigInt(rate) * 60n * 60n;
    }

    return getRateDisplay(nextFunding);
  }  

  const loadUserSnapshots = async () => {
    if (userSnapshots) {
      const formattedPositions = [];
      const addresses = Object.keys(userSnapshots);
      let tExposure = 0;
      let tCollateral = 0;
      let tPnL = 0;

      for (let i = 0; i < addresses.length; i += 1) {
        const productAddress = addresses[i];
        const userSnapshot = userSnapshots[productAddress];
        const isLong = isLongPosition(chainId, productAddress);

        const isPositionOpen =
          userSnapshot.collateral > 0n || userSnapshot.positionSize > 0n;
        
        if (
          isPositionOpen &&
          productsSnapshots &&
          (userSnapshot.positionDirection === positionType || userSnapshot.positionDirection === 'none')
        ) { 
          let positionStatus = POSITION_STATUS.closed;
          const ps = productsSnapshots[productAddress.toLowerCase()];
          const pendingPositionSize = userPositionSize(userSnapshot?.position);
          const currentMarket = getMarketByProduct(chainId, productAddress);

          let liquidationPrice = "0";
          if (userSnapshot.positionSize !== 0n || pendingPositionSize !== 0n) {
            const pSize = userSnapshot.positionSize === 0n
              ? ethers.formatEther(pendingPositionSize)
              : ethers.formatEther(userSnapshot.positionSize)

            liquidationPrice = getLiquidationPrice(
              ps,
              ethers.formatEther(userSnapshot.collateral),
              pSize,
              isLong,
              currentMarket.displayDecimals
            );
            positionStatus = POSITION_STATUS.open;
          }
          
          const positionChanges = allPositionsChanges[productAddress.toLowerCase()];
          if (userSnapshot.liquidatable) {
            positionStatus = POSITION_STATUS.liquidable
          }
          if (userSnapshot.liquidating) {
            positionStatus = POSITION_STATUS.liquidating
          }
          if (positionChanges && positionChanges.positionLiquidated) {
            positionStatus = POSITION_STATUS.liquidated
          }

          const hourlyRate = await calculateHourlyRate(productAddress);
          let pnl = 0n;
          let lastEntryPrice = "0";
          
          pnl = calculateLatestPositionPnl(positionChanges, userSnapshot.collateral);
          const lp = positionChanges?.buckets.at(-1)?.subPositions.at(-1)?.price
          if (lp) {
            lastEntryPrice = ethers.formatEther(BigMath.abs(lp));
          }
  
          const exposureBN = userSnapshot.exposure;
          let exposure = parseFloat(ethers.formatEther(exposureBN)).toFixed(4);
          if (userSnapshot.exposure === 0n && ps.latestVersion) {
            const psize = parseFloat(ethers.formatEther(userSnapshot.positionSize));
            const price = parseFloat(ethers.formatEther(
              BigMath.abs(ps.latestVersion.price))
            );
            exposure = (psize * price).toFixed(4);
          }

          let closing = false;
          let positionSize = ethers.formatEther(userSnapshot.positionSize);
          if (userSnapshot.positionSize > 0n || pendingPositionSize !== 0n) {
            closing = userSnapshot.positionSize === 0n;
          }

          if (closing) {
            positionStatus = POSITION_STATUS.closing;
          }

          const exposureN = parseFloat(exposure);
          const collateralN = parseFloat(ethers.formatEther(userSnapshot.collateral));
          const pnlN = parseFloat(ethers.formatEther(pnl));

          tExposure +=  parseFloat(exposureN.toFixed(4));
          tCollateral += parseFloat(collateralN.toFixed(4));
          tPnL += parseFloat(pnlN.toFixed(4));

          formattedPositions.push({
            productAddress: userSnapshot.productAddress,
            market: currentMarket,
            isLong,
            positionSize: positionSize,
            pendingPositionSize: ethers.formatEther(pendingPositionSize),
            exposure: exposureN.toFixed(4),
            initialCollateral: (collateralN - pnlN).toFixed(4),
            collateral: collateralN.toFixed(4),
            pnl: pnlN.toFixed(8),
            lastEntryPrice,
            liquidationPrice,
            hourlyRate,
            liquidatable: userSnapshot.liquidatable,
            liquidating: userSnapshot.liquidating,
            closing,
            liquidated: positionChanges ? positionChanges.positionLiquidated : false,
            status: positionStatus,
          })
        }
      }

      setPositions(formattedPositions);
      setTotalExposure(tExposure);
      setTotalCollateral(tCollateral);
      setTotalPnL(tPnL);
    }
  };

  useEffect(() => {
    const load = async () => {
      if (!loadingPChanges) {
        await loadUserSnapshots();
      }  
    };
    load();
  },
    // eslint-disable-next-line
    [userSnapshots, loadingPChanges]
  );

  const onUpdateClick = (productAddress: string) => {
    setCurrentProductAddress(productAddress);
    setShowPositionUpdate(true);
  };

  const onCloseClick = (productAddress: string) => {
    setCurrentProductAddress(productAddress);
    setShowPositionClose(true);
  };

  const pnlClassName = (pnl: number) => {
    if (Math.abs(pnl) < 0.0001) {
      return "number";
    }
    return pnl > 0 ? "number green" : "number red";
  }

  const exposureTooltip = t("tooltip.exposure");

  const collateralTooltip = t("tooltip.collateral");

  const pnlTooltip = t("tooltip.pnl");

  const entryPriceTooltip = t("tooltip.entry-price-2");

  const liqPriceTooltip = t("tooltip.liquidation-price");

  const fundingRateTooltip = t("tooltip.hourly-funding-rate")

  /* const shareToXMsg = (position: PositionType): string => {
    const title = position.market.symbol.concat(position.isLong ? " LONG" : " SHORT").concat(" position.\n\n");
    const exposure = "Exposure: $".concat(position.exposure);
    const pSize = "\nPositio Size: ".concat(position.positionSize).concat(" ").concat(position.market.symbol);
    const collateral = "\nCollateral: $".concat(position.collateral);
    const pnl = "\nPnL: $".concat(position.pnl);

    return title.concat(exposure).concat(pSize).concat(collateral).concat(pnl).concat("\n\n");
  }; */
  
  const postionLabelType = (isLong: boolean): string => {
    const prefix = positionType === OpenPositionType.Maker ? t("provide") : "";

    return isLong ? prefix.concat(" ").concat(t("long").toUpperCase()) : prefix.concat(" ").concat(t("short").toUpperCase());
  };

  const statusColorClass = (status: string) => {
    if (status === POSITION_STATUS.open) {
      return "text-green";
    } else if (status === POSITION_STATUS.closed || status === POSITION_STATUS.closing) {
      return "text-grey";
    }
    return "text-red";
  };

  const RenderPosition = (position: PositionType, index: number) => {
    return (
      <tr key={index}>
        <td>
          <div className="market">
            <TokenIcon name={position.market.symbol} size="normal" />
            <div className="market-info">
              <h6>
                {postionLabelType(position.isLong)}
              </h6>
              {isSmallScreen ? (
                <span className="title">{position.market.description.split("/")[0]}</span>
              ): (
                <span className="title">{position.market.description.split("/")[1]}</span>  
              )}
            </div>
          </div>
        </td>
        <td className="right">
          <div className="flex-column">
            <CustomTooltip
              id="texposure2"
              msg={exposureTooltip}
            >
              <NumericFormat
                className="balance"
                value={position.exposure}
                displayType="text"
                thousandSeparator=","
                decimalScale={4}
                prefix="$"
              />
            </CustomTooltip>  
            <NumericFormat
              className="number small gray"
              value={position.closing ? position.pendingPositionSize : position.positionSize}
              displayType="text"
              thousandSeparator=","
              decimalScale={4}
              suffix={" ".concat(position.market.symbol.toUpperCase())}
            />
          </div>
        </td>
        <td className="right">
          <div className="flex-column">
            <NumericFormat
              className="balance"
              value={position.collateral}
              displayType="text"
              thousandSeparator=","
              prefix="$"
              decimalScale={4}
            />
            <NumericFormat
              className="number small gray"
              value={position.initialCollateral}
              displayType="text"
              thousandSeparator=","
              decimalScale={4}
              prefix="$"
            />
          </div>  
        </td>
        <td className="right">
          <CustomTooltip
            id="tpnl2"
            msg={pnlTooltip}
          >
            <NumericFormat
              className={pnlClassName(parseFloat(position.pnl))}
              value={position.pnl}
              displayType="text"
              thousandSeparator=","
              prefix="$"
              decimalScale={4}
            />
          </CustomTooltip>  
        </td>
        <td className="right">
          <CustomTooltip
            id="taprice2"
            msg={entryPriceTooltip}
          >
            <NumericFormat
              className="balance"
              value={position.lastEntryPrice}
              displayType="text"
              thousandSeparator=","
              prefix="$"
              decimalScale={position.market.displayDecimals}
            />
          </CustomTooltip>  
        </td>
        <td className="right">
          <div className="flex-column liquidation-price">
            <CustomTooltip
              id="tliqprice2"
              msg={liqPriceTooltip}
            >
              <NumericFormat
                className="balance"
                value={position.liquidationPrice}
                displayType="text"
                thousandSeparator=","
                prefix="$"
              />
            </CustomTooltip>
          </div>  
        </td>
        <td className="right">
          <CustomTooltip
            id="texposure"
            msg={fundingRateTooltip}
          >
            <NumericFormat
              className="balance"
              value={position.hourlyRate}
              displayType="text"
              thousandSeparator=","
              suffix="%"
              decimalScale={5}
            />
          </CustomTooltip>
        </td>
        <td className="center col-status">
          <span className={statusColorClass(position.status)}>
            {position.status === POSITION_STATUS.closing && <Spinner animation="border" variant="secondary" className="xxs" />}
            {position.status === POSITION_STATUS.liquidating && <Spinner animation="border" variant="danger" className="xxs" />}
            {t(position.status)}
          </span>
        </td>
        <td className="right">
          <Button
            variant="outline-primary"
            onClick={() => onUpdateClick(position.productAddress)}
            disabled={position.liquidating || position.closing}
          >
            {t("change")}
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => onCloseClick(position.productAddress)}
            disabled={position.liquidating}
          >
            {parseFloat(position.positionSize) > 0 || parseFloat(position.pendingPositionSize) > 0
              ? position.closing ? t("closing") : t("close")
              : t("withdraw")
            }
          </Button>
        </td>
      </tr>
    );  
  };

  const PositionTotals = () => (
    <tr className="table-totals">
      <td>
        <h5>{("totals")}</h5>
      </td>
      <td className="right">
        <NumericFormat
          className="bold"
          value={totalExposure.toFixed(4)}
          displayType="text"
          thousandSeparator=","
          prefix="$"
          decimalScale={4}
        />
      </td>
      <td className="right">
        <NumericFormat
          className="bold"
          value={totalCollateral.toFixed(4)}
          displayType="text"
          thousandSeparator=","
          prefix="$"
          decimalScale={4}
        />
      </td>
      <td className=" right">
        <NumericFormat
          className={"bold ".concat(pnlClassName(totalPnL))}
          value={totalPnL.toFixed(4)}
          displayType="text"
          thousandSeparator=","
          prefix="$"
          decimalScale={4}
        />
      </td>
      <td />
      <td />
      <td />
      <td />
      <td />
    </tr>
  );

  const RenderPositionMobile = (position: PositionType, index: number) => {
    return (
      <div key={index} className="position-box">
        <div className="box-header">
          <div className="market">
            <TokenIcon name={position.market.symbol} size="normal" />
            <div className="market-info">
              <div className="postion-direction">
                <h6>{position.isLong ? t("long").toUpperCase() : t("short").toUpperCase()}</h6>
                <div className="status">
                  <h6>-</h6>
                  <h6 className={statusColorClass(position.status)}>{t(position.status)}</h6>
                  {position.status === POSITION_STATUS.closing && <Spinner animation="border" variant="secondary" className="xxs" />}
                  {position.status === POSITION_STATUS.liquidating && <Spinner animation="border" variant="danger" className="xxs" />}
                </div>
              </div>
              <span className="title">{position.market.description}</span>
            </div>
          </div>
        </div>
        <div className="box-body">
          <div className="position-item">
            <div className="left">
              <NumericFormat
                className="balance"
                value={position.exposure}
                displayType="text"
                thousandSeparator=","
                decimalScale={4}
                prefix="$"
              />
              <CustomTooltip
                id="texposure"
                msg={exposureTooltip}
                showIcon={true}
                placement="top"
                iconOnLeft={true}
              >
                <h6 className="margin-right">{t("exposure")}</h6>
              </CustomTooltip>
            </div>
            <div className="right">
              <NumericFormat
                className="balance"
                value={position.closing ? position.pendingPositionSize : position.positionSize}
                displayType="text"
                thousandSeparator=","
                suffix={" ".concat(position.market.symbol)}
                decimalScale={4}
              />
              <h6 className="margin-right">{t("position-size")}</h6>
            </div>
          </div>
          <div className="position-item">
            <div className="left">
              <NumericFormat
                className="balance"
                value={position.initialCollateral}
                displayType="text"
                thousandSeparator=","
                prefix="$"
                decimalScale={4}
              />
              <h6>{t("initial-collateral")}</h6>
            </div>
            <div className="right">
              <NumericFormat
                className="balance"
                value={position.collateral}
                displayType="text"
                thousandSeparator=","
                prefix="$"
                decimalScale={4}
              />
              <h6>{t("current-collateral")}</h6>
            </div>
          </div>
          <div className="position-item2">
            <div className="left">
              <NumericFormat
                className={pnlClassName(parseFloat(position.pnl))}
                value={position.pnl}
                displayType="text"
                thousandSeparator=","
                prefix="$"
                decimalScale={4}
              />
              <CustomTooltip
                id="tpnl"
                msg={pnlTooltip}
                showIcon={true}
                iconOnLeft={true}
                placement="top"
              >
                <h6 className="margin-right">{t("total-profit-loss")}</h6>
              </CustomTooltip>
            </div>
            <div className="right">
              <NumericFormat
                className="balance"
                value={position.liquidationPrice}
                displayType="text"
                thousandSeparator=","
                prefix="$"
              />
              <CustomTooltip
                id="tliqprice"
                msg={liqPriceTooltip}
                showIcon={true}
                iconOnLeft={true}
                placement="top"
              >
                <h6 className="margin-right">{t("liq-price")}</h6>
              </CustomTooltip>
            </div>
          </div>
          <div className="position-item2">
            <div className="left">
              <NumericFormat
                className="balance"
                value={position.lastEntryPrice}
                displayType="text"
                thousandSeparator=","
                prefix="$"
                decimalScale={position.market.displayDecimals}
              />
              <CustomTooltip
                id="taprice"
                msg={entryPriceTooltip}
                showIcon={true}
                iconOnLeft={true}
                placement="top"
              >
                <h6 className="margin-right">{t("est-entry-price")}</h6>
              </CustomTooltip>
            </div>
            <div className="right">
              <NumericFormat
                className="balance"
                value={position.hourlyRate}
                displayType="text"
                thousandSeparator=","
                suffix="%"
                decimalScale={5}
              />
              <CustomTooltip
                id="texposure"
                msg={fundingRateTooltip}
                showIcon={true}
                iconOnLeft={true}
                placement="top"
              >
                <h6 className="margin-right">{t("h-funding-rate")}</h6>
              </CustomTooltip>
            </div>
          </div>
        </div>
        <div className={"box-footer ".concat(position.liquidating ? t("liquidating") : "")}>
          <Button
            variant="outline-primary"
            onClick={() => onUpdateClick(position.productAddress)}
            disabled={position.liquidating || position.closing}
          >
            {t("change")}
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => onCloseClick(position.productAddress)}
            disabled={position.liquidating}
          >
            {parseFloat(position.positionSize) > 0 || parseFloat(position.pendingPositionSize) > 0
              ? position.closing ? t("closing") : t("close")
              : t("withdraw")
            }
          </Button>
        </div>
      </div>
    );  
  }

  return (
    <>
      {!isMobile ? (
        <>
          {!isLoadingPositions && !loadingPChanges && positions.length > 0 && (
            <Table striped hover variant="dark">
              <thead>
                <tr>
                  <th>{t("market")}</th>
                  <th className="right">
                    <CustomTooltip
                      id="texposure"
                      msg={exposureTooltip}
                      showIcon={true}
                      placement="top"
                    >
                      <h6 className="margin-right">{t("exposure")}</h6>
                    </CustomTooltip>  
                  </th>
                  <th className="right">
                    <CustomTooltip
                      id="texposure"
                      msg={collateralTooltip}
                      showIcon={true}
                      placement="top"
                    >
                      <h6 className="margin-right">{t("collateral")}</h6>
                    </CustomTooltip>  
                  </th>
                  <th className="right">
                    <CustomTooltip
                      id="tpnl"
                      msg={pnlTooltip}
                      showIcon={true}
                      iconOnLeft={false}
                      placement="top"
                    >
                      <h6 className="margin-right">
                        {!isSmallScreen ? t("total-profit-loss") : t("Total-pnl")}
                      </h6>
                    </CustomTooltip>  
                  </th>
                  <th className="right">
                    <CustomTooltip
                      id="taprice"
                      msg={entryPriceTooltip}
                      showIcon={true}
                      iconOnLeft={false}
                      placement="top"
                    >
                      <h6 className="margin-right">{t("est-entry-price")}</h6>
                    </CustomTooltip>  
                  </th>
                  <th className="right">
                    <CustomTooltip
                      id="tliqprice"
                      msg={liqPriceTooltip}
                      showIcon={true}
                      iconOnLeft={false}
                      placement="top"
                    >
                      <h6 className="margin-right">
                        {!isSmallScreen ? t("liquidation-price") : t("liq-price")}
                      </h6>
                    </CustomTooltip>
                  </th>
                  <th className="right">
                    <CustomTooltip
                      id="texposure"
                      msg={fundingRateTooltip}
                      showIcon={true}
                      iconOnLeft={false}
                      placement="top"
                    >
                      <h6 className="margin-right">
                        {!isSmallScreen ? t("hourly-funding-rate") : t("h-funding-rate")}
                      </h6>
                    </CustomTooltip>
                  </th>
                  <th className="center col-status">{t("status")}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {positions.map((item: PositionType, index: number) => {
                  return RenderPosition(item, index);
                })}
                <PositionTotals />
              </tbody>
            </Table>
          )}
          {!isLoadingPositions && !loadingPChanges && positions.length === 0 && (
            <div className="empty-positions">
              <h5 className="text-purple">
                {t("info-msg.no-positions")}
              </h5>
            </div>
          )}
        </>  
      ) : (
        <>
          {positions.map((item: PositionType, index: number) => {
            return RenderPositionMobile(item, index);
          })}
          {positions.length === 0 && !isLoadingPositions && !loadingPChanges && (
            <div className="empty-positions">
              <h5 className="text-purple">
                {t("info-msg.no-positions")}
              </h5>
            </div> 
          )}  
        </>    
      )}
      {(isLoadingPositions || loadingPChanges) && (
        <div className="spinner-container">
          <Spinner className="spinner small " animation="border" />
        </div>
      )}
      {showPositionUpdate && (
        <UpdatePosition
          showModal={showPositionUpdate}
          ownerAddress={ownerAddress}
          productAddress={currentProductAddress}
          onHide={() => setShowPositionUpdate(false)}
        />
      )}
      {showPositionClose && (
        <ClosePosition
          showModal={showPositionClose}
          ownerAddress={ownerAddress}
          productAddress={currentProductAddress}
          isTaker={positionType === OpenPositionType.Taker}
          onHide={() => {
            setShowPositionClose(false);
            if (positionStats.market !== null) {
              setShowShareTweet(true);
            }
          }}
        />
      )} 
      {showShareTweet && (
        <SharePosition
          showModal={showShareTweet}
          onHide={() => {
            setShowShareTweet(false);
            positionStats.setCurrentMarket(null);
          }}
        />
      )}
    </>
  )
};
