import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap/esm";
import { useMediaQuery } from "react-responsive";

type props = {
  controlId: string;
  value: string;
  max: number;
  onChange: (value: number, strValue: string) => void;
}

export const LeverageInput = ({ controlId, value, max, onChange }: props) => {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const [errorClassName, setErrorClassName] = useState("");

  useEffect(() => {
    const validate = () => {
      try {
        const val = parseFloat(value);
        if (val >= 0 && val <= max) {
          setErrorClassName("");
        } else {
          setErrorClassName("error");
        }
      } catch (error) {
        setErrorClassName("error");
      }
    };
    validate();
  },
    // eslint-disable-next-line
    [value]
  )

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valStr = event.target.value
    try {
      let isValid = false
      const val = parseFloat(valStr)

      if (val >= 0 && val <= max) {
        const valueParts = valStr.split(".")
        isValid = valueParts.length === 1 || (valueParts.length === 2 && valueParts[1] !== "")
      }

      if (isValid) {
        onChange(val, valStr)
        setErrorClassName("")
      } else {
        setErrorClassName("error")
        onChange(-1, valStr)  
      }
    } catch (error) {
      setErrorClassName("error");
      onChange(-1, valStr);
    }
  };

  return (
    <div className={"leverage-input-container ".concat(errorClassName)}>
      <Form.Control
        id={controlId}
        placeholder=""
        className="leverage-input"
        type={isMobile ? "number" : "text"}
        value={value}
        onChange={onValueChange}
        onFocus={(event) => event.target.select()}
      />
      <span className="small">x</span>
    </div>    
  )
};
