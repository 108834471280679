import React from "react";
import { USDC_TOKEN, TCAP_TOKEN } from "../utils/constants";
import { tcapLongMarketContract, tcapVaultContract } from "../utils/contracts";
import { ContractType, TokenType } from "../utils/types";


export interface IProductContext { 
  market: TokenType,
  setCurrentMarket: (currentMarket: TokenType) => void;
  collateral: TokenType,
  setCurrentCollateral: (currentCollateral: TokenType) => void;
  productContract: ContractType,
  setCurrentProductContract: (currentProductContract: ContractType) => void;
  vaultContract: ContractType,
  setCurrentVaultContract: (currentVaultContract: ContractType) => void;
  type: string;
  setCurrentType: (currentType: string) => void;
  isLonging: () => boolean;
};

const DEFAULT_VALUES = {
  setCurrentMarket: () => {},
  collateral: USDC_TOKEN,
  setCurrentCollateral: () => {},
  setCurrentProductContract: () => {},
  setCurrentVaultContract: () => {},
  type: "long",
  setCurrentType: () => {},
  isLonging: () => true,
};

const TCAP_DEFAULT_VALUE = {
  market: TCAP_TOKEN,
  productContract: tcapLongMarketContract,
  vaultContract: tcapVaultContract,
  ...DEFAULT_VALUES
}


export const productContext = React.createContext<IProductContext>(TCAP_DEFAULT_VALUE);
