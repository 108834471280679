import { AbstractProvider, Contract, Signer } from "ethers";
import aggregatorAbi from "./abi/aggregatorAbi.json";
import usdcAbi from "./abi/usdcAbi.json";


const CHAINS = {
  ARBITRUM: 42161,
  ARBITRUM_GOERLI: 421613
};


export const tcapOracleContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x4763b84cdBc5211B9e0a57D5E39af3B3b2440012",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0x4B7C73d46f2ECaa76127b4F2304a4FaF5c47F66A",
  },
  abi: aggregatorAbi,
};

export const usdcOracleContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x50834F3163758fcC1Df9973b6e91f0F0F0434aD3",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0x50834F3163758fcC1Df9973b6e91f0F0F0434aD3",
  },
  abi: aggregatorAbi,
};

export const usdcTokenContract = {
  [CHAINS.ARBITRUM]: {
    address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0x6775842AE82BF2F0f987b10526768Ad89d79536E",
  },
  abi: usdcAbi,
};

export const dsuTokenContract = {
  [CHAINS.ARBITRUM]: {
    address: "0x52C64b8998eB7C80b6F526E99E29ABdcC86B841b",
  },
  [CHAINS.ARBITRUM_GOERLI]: {
    address: "0x52C64b8998eB7C80b6F526E99E29ABdcC86B841b",
  },
  abi: usdcAbi,
};


export const getDSUContract = (chainId: number, provider: AbstractProvider | Signer): Contract => {
  return new Contract(dsuTokenContract[chainId].address, dsuTokenContract.abi, provider);
};

export const getUSDCContract = (chainId: number, provider: AbstractProvider | Signer): Contract => {
  return new Contract(usdcTokenContract[chainId].address, usdcTokenContract.abi, provider);
};

export const getUSDCOracleContract = (chainId: number, provider: AbstractProvider | Signer): Contract => {
  return new Contract(usdcOracleContract[chainId].address, usdcOracleContract.abi, provider);
};

export const getTCAPOracleContract = (chainId: number, provider: AbstractProvider): Contract => { 
  return new Contract(tcapOracleContract[chainId].address, tcapOracleContract.abi, provider);
}
