
import { AbiCoder, AbstractProvider, EventLog, Log, Typed } from "ethers";
import useSWR from "swr";
import { TCAP_MARKET } from "../utils/constants";
import {
  OpenPositionType,
  PositionBucket,
  UserPositionChanges,
} from "../utils/types";
import {
  getLensContract,
  getCollateralContract,
  getProductContract,
} from "../utils/contracts";
import { useActiveProvider } from ".";


const getUserPositionChanges = async (
  chainId: number,
  provider: AbstractProvider,
  account: string,
  productAddress: string,
  currentPositionType: string,
  isPositionOpen: boolean,
): Promise<UserPositionChanges | undefined> => {
  const abiCoder = AbiCoder.defaultAbiCoder();
  const lensContract = getLensContract(chainId, provider);
  const collateralContract = getCollateralContract(chainId, provider);
  const productContract = getProductContract(provider, productAddress);

  if (currentPositionType === "none" || !isPositionOpen) return;
  
  const userSide = currentPositionType === OpenPositionType.Maker ? "Make" : "Take";
  const opensFilter = productContract.filters[`${userSide}Opened(address,uint256,uint256)`](account);
  const closesFilter = productContract.filters[`${userSide}Closed(address,uint256,uint256)`](account);
  const liquidationFilter = collateralContract.filters["Liquidation(address,address,address,uint256)"](account, productAddress);

  const [openEvents, closeEvents, userPrePosition, liquidationEvents] = await Promise.all([
    productContract.queryFilter(opensFilter),
    productContract.queryFilter(closesFilter),
    lensContract["pre(address,address)"].staticCall(Typed.address(account), Typed.address(productAddress)),
    collateralContract.queryFilter(liquidationFilter),
  ])

  const opens = openEvents
    .map((openedEvent: EventLog | Log) => {
      const [version, amount] = abiCoder.decode(["uint256", "uint256"], openedEvent.data)
      return {
        amount: amount,
        version: version,
        blockNumber: openedEvent.blockNumber,
        transactionHash: openedEvent.transactionHash
      }
    })
    .sort((a, b) => (a.version <= b.version ? -1 : 1));

  const closes = closeEvents
    .map((closedEvent) => {
      const [version, amount] = abiCoder.decode(["uint256", "uint256"], closedEvent.data)

      return {
        amount: amount,
        version: version,
        blockNumber: closedEvent.blockNumber,
        transactionHash: closedEvent.transactionHash,
      }
    })
    .sort((a, b) => (a.version <= b.version ? -1 : 1));
  
  // sort liquidations in descending order
  const liquidations = liquidationEvents
    .map(({ blockNumber, transactionHash }) => ({
      blockNumber,
      transactionHash,
    })).sort((a, b) => b.blockNumber - a.blockNumber);
  
  
  // Check if the last position was liquidated  
  let positionLiquidated = false;
  if (liquidations.length > 0) {
    const lastClosed = closes[closes.length - 1];
    const lastOpened = opens[opens.length - 1];

    positionLiquidated =
      liquidations[0].transactionHash === lastClosed.transactionHash &&
      lastClosed.blockNumber > lastOpened.blockNumber;
  }

  const openVersions = openEvents.map(({ data }) => {
    // If the prePosition version is equal to the position version, we are still waiting on price settlement
    // so use the pre-settlement price to calculate
    const [version,] = abiCoder.decode(["uint256", "uint256"], data);    
    return userPrePosition.oracleVersion === version ? version : version + 1n
  })

  const closeVersions = closeEvents.map(({ data }) => {
    // If the prePosition version is equal to the position version, we are still waiting on price settlement
    // so use the pre-settlement price to calculate
    const [version,] = abiCoder.decode(["uint256", "uint256"], data);
    return userPrePosition.oracleVersion === version ? version : version + 1n
  })
  
  const versions = Array.from(new Set([...openVersions, ...closeVersions]));

  const prices = await lensContract.atVersions.staticCall(productAddress, versions)
  const versionToPrice = versions.reduce((ret: any, version: any, i: number) => {
    ret[version.toString()] = prices[i].price
    return ret
  }, {} as { [key: string]: bigint })

  /**
   * Bucket the position changes into discreet position actions (opening to full close)
   * Record the collateral at the start of each open position for use in PnL calcs
   */

  // Add 1 here because the price settlement occurs 1 version after the position change
  // If version+1 is not in the map, use version: this means the price has not settled yet
  const getPrice = (version: bigint) => {
    return versionToPrice[(version + 1n).toString()] || versionToPrice[version.toString()]
  }

  const getCollateral = async (block: number) => {
    const [, collateral] = await Promise.all([
      productContract.settleAccount.staticCall(account, { blockTag: block }),
      collateralContract.collateral.staticCall(Typed.address(account), Typed.address(productAddress), {
        blockTag: block,
      }),
    ])

    return collateral
  }

  const getCollateralChanges = async (startBlock?: number, endBlock?: number) => {
    const depositsFilter = collateralContract.filters.Deposit(account, productAddress)
    const withdrawalsFilter = collateralContract.filters.Withdrawal(account, productAddress)

    const [deposits, withdrawals] = await Promise.all([
      collateralContract.queryFilter(depositsFilter, startBlock, endBlock),
      collateralContract.queryFilter(withdrawalsFilter, startBlock, endBlock),
    ])

    return {
      deposits: deposits.map((d) => {
        const [amount] = abiCoder.decode(["uint256"], d.data);
        return amount;
      }),
      withdrawals: withdrawals.map((w) => {
        const [amount] = abiCoder.decode(["uint256"], w.data);
        return amount;
      }),
    }
  }

  // Convert closes to negative amounts and merge the opens and closes, sorting by version
  const merged = [...opens, ...closes.map((c) => ({ ...c, amount: c.amount * -1n }))].sort((a, b) =>
    a.version <= b.version ? -1 : 1
  )

  // Net out position changes that occur at the same version
  const netted = []
  for (let i = 0; i < merged.length; i++) {
    const last = netted[netted.length - 1]

    if (!last || last.version !== merged[i].version) netted.push(merged[i])
    else last.amount = last.amount + merged[i].amount
  }

  let buckets: PositionBucket[] = []
  let currentBucket = 0
  let netPosition = 0n
  for (let i = 0; i < netted.length; i++) {
    const change = netted[i]

    if (!buckets[currentBucket]) {
      buckets.push({
        startingBlock: change.blockNumber,
        endingBlock: -1,
        startingCollateral: await getCollateral(change.blockNumber - 1), // Collateral value right before position was opened
        subPositions: [],
        collateralChanges: { deposits: [], withdrawals: [] },
      })
      if (currentBucket >= 1) {
        buckets[currentBucket - 1].afterCloseCollateralChanges = await getCollateralChanges(
          buckets[currentBucket - 1].endingBlock,
          change.blockNumber - 1
        )
      }
    }
    netPosition = netPosition + change.amount
    buckets[currentBucket].subPositions.push({
      size: netPosition,
      price: getPrice(change.version),
      delta: change.amount,
      block: change.blockNumber,
      tx: change.transactionHash,
      version: change.version,
    })
    if (netPosition === 0n) {
      buckets[currentBucket].endingBlock = change.blockNumber
      buckets[currentBucket].collateralChanges = await getCollateralChanges(
        buckets[currentBucket].startingBlock,
        buckets[currentBucket].endingBlock
      )
      currentBucket += 1
    }
  }

  if (buckets[buckets.length - 1] && buckets[buckets.length - 1].endingBlock === -1) {
    buckets[buckets.length - 1].collateralChanges = await getCollateralChanges(
      buckets[buckets.length - 1].startingBlock
    )
  } else if (buckets[buckets.length - 1] && buckets[buckets.length - 1].endingBlock > -1) {
    buckets[buckets.length - 1].afterCloseCollateralChanges = await getCollateralChanges(
      buckets[buckets.length - 1].endingBlock
    )
  }

  return {
    buckets,
    positionLiquidated
  }
}


export const useUserPositionChanges = (isTaker: boolean, productAddress: string, openProducts?: Array<string>) => {
  const { chainId, userAccount, multiCallProvider } = useActiveProvider();
  let isPositionOpen = true;
  if (openProducts) {
    isPositionOpen = openProducts.includes(productAddress);
  }

  return useSWR<UserPositionChanges | undefined>(
    productAddress &&
    [
      "userPositionChanges",
      chainId,
      userAccount,
      productAddress,
      isPositionOpen,
    ],
    async ([_, chainId, ownerAddress, productAddress, isPositionOpen]: [
      _: string,
      chainId: number,
      ownerAddress: string,
      productAddress: string,
      isPositionOpen: boolean,
    ]) => {
      let positionType = isTaker ? OpenPositionType.Taker : OpenPositionType.Maker;
      return getUserPositionChanges(chainId, multiCallProvider, ownerAddress, productAddress, positionType, isPositionOpen);
    },
    { keepPreviousData: true }
  )
};

export const useUserAllPositionsChanges = (isTaker: boolean, openProducts: Array<string>) => {
  const { chainId } = useActiveProvider();

  const { data: tcapPositionChangesLong, isLoading: loadingTcapLong } = useUserPositionChanges(
    isTaker,
    TCAP_MARKET.longContract[chainId].address.toLowerCase(),
    openProducts
  );
  const { data: tcapPositionChangesShort, isLoading: loadingTcapShort } = useUserPositionChanges(
    isTaker,
    TCAP_MARKET.shortContract[chainId].address.toLowerCase(),
    openProducts
  );

  return {
    loadingData: loadingTcapLong || loadingTcapShort,
    allPositionsChanges: {
      [TCAP_MARKET.longContract[chainId].address.toLowerCase()]: tcapPositionChangesLong,
      [TCAP_MARKET.shortContract[chainId].address.toLowerCase()]: tcapPositionChangesShort,
    }
  };
};
