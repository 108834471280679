import { useQuery } from '@tanstack/react-query'
import { gql } from '@apollo/client'
import { mainnet } from 'viem/chains'

import axios from 'axios'
import { KeeperInfoType, KeeperAllEndPoint } from '../../constants/governance'
import { useAddress, useChainId, useV1GraphClient } from '../network'
import { GraphDefaultPageSize, queryAll } from '../../utils/graphUtils'


export const useKeepers = () => {
  const chainId = useChainId()
  const address = useAddress()
  const graphClient = useV1GraphClient()

  return useQuery({
    queryKey: ['keepers', chainId, address],
    enabled: true,
    queryFn: async () => {
      if (chainId !== mainnet.id) return { keepers: [], keepersFormattedInfo: [] }

      const query = gql(`
        query DELEGATORS($currentSignerAddress: String!) {
          delegators(orderBy: delegatedVotesRaw, orderDirection: desc) {
            id
            delegatee
            delegatedVotes
            delegatedVotesRaw
            totalHoldersRepresented
            created_at
            tokenOwners(where: { tokenOwner: $currentSignerAddress }) {
              stake
              stakeRaw
            }
          }
        }
      `);

      const { delegators: keepers } = await queryAll(async (pageNumber: number) =>
        graphClient.request(query, {
          currentSignerAddress: address.address ?? "",
          first: GraphDefaultPageSize,
          skip: pageNumber * GraphDefaultPageSize,
        }) as any
      )

      const keepersFormattedInfo = new Array<KeeperInfoType>();
      const keepersInfo = await fetchKeeperInfo();
      if (keepers && keepersInfo) {
        keepersInfo.forEach((keeper: any) => {
          const index = keepers.findIndex(
            // @ts-ignore
            (item) => item.delegatee.toLowerCase() === keeper.address.toLowerCase()
          );
          if (index !== -1) {
            keepersFormattedInfo.push({
              id: keeper.id,
              address: keeper.address,
              name: keeper.name,
              eth_name: keeper.eth_name,
              image: keeper.image,
              expertise: keeper.expertise,
              why: keeper.why,
              discord: keeper.discord,
              twitter: keeper.twitter,
              delegatedVotes: parseInt(keepers[index].delegatedVotes),
              totalHoldersRepresented: keepers[index].totalHoldersRepresented,
              createdAt: Number(keepers[index].created_at),
            });
          }
        })
      }

      return {
        keepers,
        keepersFormattedInfo
      }
    },
  })
}

const fetchKeeperInfo = async () => { 
  const resp = await axios.get(KeeperAllEndPoint);
  if (resp && resp.data) { 
    if (resp.status === 200) { 
      return resp.data;
    }
  }
}
