import { useCallback, useMemo } from 'react'
import { Address, getAddress } from 'viem'
import {
  Big6Math,
  formatBig6USDPrice,
  PositionSide, 
  MarketSnapshot,
  OpenOrder,
  PositionStatus,
  SupportedAsset,
  SupportedChainId,
  TriggerComparison,
} from '@perennial/sdk';

import { collateralFromAmountAndLeverage, leverageFromAmountAndCollateral, positionFromCollateralAndLeverage } from './utils'
import { isNumbersOnly } from '../../utils/formUtils'
import { FormNames, OrderTypes } from './constants'
import { useOpenOrders } from '../../hooks/markets2/graph'
import { useMarketContext } from '../../contexts';
import { useSettlementFees } from '../../hooks/markets2';
import { interfaceFeeBps } from '../../constants/network';


export type TradeFormValues = { amount: string; collateral: string; leverage: string }

interface OnChangeHandlersProps {
  setValue: (valueName: string, value: string) => void,
  leverage: string
  collateral: string
  amount: string
  leverageFixed: boolean
  currentPosition: bigint
  marketSnapshot: MarketSnapshot
  chainId: SupportedChainId
  positionStatus: PositionStatus
  direction: PositionSide
  isLimitOrder?: boolean
  limitOrderPrice?: string
}


export const useOnChangeHandlers = ({
  setValue,
  leverage,
  collateral,
  amount,
  leverageFixed,
  currentPosition,
  marketSnapshot,
  chainId,
  positionStatus,
  direction,
  isLimitOrder,
  limitOrderPrice,
}: OnChangeHandlersProps) => {
  const { marketMetadata } = useMarketContext()
  const { data: settlementFees } = useSettlementFees()
  const settlementFee = useMemo(() => {
    if (!settlementFees) return 0n

    return settlementFees[marketSnapshot.market].totalCost
  }, [settlementFees, marketSnapshot.market]);

  const onChangeAmount = useCallback(
    (newAmount: string) => {
      if (!isNumbersOnly(newAmount)) return
      const validatedAmount = Big6Math.max6Decimals(newAmount)
      setValue(FormNames.amount, validatedAmount)


      if (leverageFixed) {
        const newCollateralAmt = collateralFromAmountAndLeverage({
          currentAmount: currentPosition,
          amount: validatedAmount,
          leverage,
          marketSnapshot,
          chainId,
          positionStatus,
          direction,
          interfaceFeeBps: !marketMetadata.isCryptexIndex ? interfaceFeeBps[chainId].feeAmount[direction] : 0n,
          settlementFee,
        })
        setValue(FormNames.collateral, newCollateralAmt)
      } else {
        const newLeverage = leverageFromAmountAndCollateral({
          currentAmount: currentPosition,
          amount: validatedAmount,
          collateral,
          marketSnapshot,
          chainId,
          positionStatus,
          direction,
          interfaceFeeBps: !marketMetadata.isCryptexIndex ? interfaceFeeBps[chainId].feeAmount[direction] : 0n,
          settlementFee,
        })
        setValue(FormNames.leverage, newLeverage)
      }
    },
    [
      setValue,
      leverageFixed,
      currentPosition,
      leverage,
      marketSnapshot,
      chainId,
      positionStatus,
      direction,
      collateral,
      settlementFee,
      marketMetadata.isCryptexIndex,
    ],
  )

  const onChangeLeverage = useCallback(
    (newLeverage: string) => {
      if (!isNumbersOnly(newLeverage)) return
      const validatedLeverage = Big6Math.max6Decimals(newLeverage)
      setValue(FormNames.leverage, validatedLeverage)

      const newPosition = positionFromCollateralAndLeverage({
        currentAmount: currentPosition,
        collateral,
        leverage: validatedLeverage,
        marketSnapshot,
        chainId,
        positionStatus,
        direction,
        isLimitOrder,
        limitOrderPrice,
        interfaceFeeBps: !marketMetadata.isCryptexIndex ? interfaceFeeBps[chainId].feeAmount[direction] : 0n,
        settlementFee,
      })
      setValue(FormNames.amount, newPosition)
    },
    [
      chainId,
      collateral,
      currentPosition,
      direction,
      marketSnapshot,
      positionStatus,
      setValue,
      isLimitOrder,
      limitOrderPrice,
      settlementFee,
      marketMetadata.isCryptexIndex,
    ],
  )

  const onChangeCollateral = useCallback(
    (newAmount: string) => {
      if (!isNumbersOnly(newAmount)) return
      const validatedCollateral = Big6Math.max6Decimals(newAmount)
      setValue(FormNames.collateral, validatedCollateral)

      if (leverageFixed) {
        const newPosition = positionFromCollateralAndLeverage({
          currentAmount: currentPosition,
          collateral: validatedCollateral,
          leverage: `${leverage}`,
          marketSnapshot,
          chainId,
          positionStatus,
          direction,
          interfaceFeeBps: !marketMetadata.isCryptexIndex ? interfaceFeeBps[chainId].feeAmount[direction] : 0n,
          settlementFee,
        })
        setValue(FormNames.amount, newPosition)
      } else {
        const newLeverage = leverageFromAmountAndCollateral({
          currentAmount: currentPosition,
          amount,
          collateral: validatedCollateral,
          marketSnapshot,
          chainId,
          positionStatus,
          direction,
          isLimitOrder,
          limitOrderPrice,
          interfaceFeeBps: !marketMetadata.isCryptexIndex ? interfaceFeeBps[chainId].feeAmount[direction] : 0n,
          settlementFee,
        })
        setValue(FormNames.leverage, newLeverage)
      }
    },
    [
      setValue,
      leverageFixed,
      currentPosition,
      leverage,
      marketSnapshot,
      chainId,
      positionStatus,
      limitOrderPrice,
      direction,
      amount,
      isLimitOrder,
      settlementFee,
      marketMetadata.isCryptexIndex,
    ],
  )

  return { onChangeAmount, onChangeLeverage, onChangeCollateral }
}

export type FormattedOpenOrder = {
  status: string
  side: PositionSide
  orderDelta: string
  orderDeltaNotional: string
  type: TriggerComparison
  triggerPrice: string
  triggerPriceUnformatted: bigint
  market: SupportedAsset
  transactionHash: string
  blockTimestamp: string
  projectedFee: string
  marketAddress: Address
  nonce: bigint
  details: OpenOrder
}

const orderIntToPositionSide = (orderSide: number) => {
  switch (orderSide) {
    case 0:
      return PositionSide.maker
    case 1:
      return PositionSide.long
    case 2:
      return PositionSide.short
    default:
      return PositionSide.none
  }
}


export const useOpenOrderData = (): FormattedOpenOrder[] => {
  const { isMaker } = useMarketContext()
  const { data: openOrders } = useOpenOrders(isMaker)

  const orders = openOrders?.pages
    .map((page) => page?.orders || [])
    .flat()
    .map((order) => {
      const { market } = order
      const orderSize = BigInt(order.triggerOrderDelta)
      const deltaNotional = Big6Math.mul(orderSize, BigInt(order.triggerOrderDelta))
      const type = order.triggerOrderComparison === -1 ? TriggerComparison.lte : TriggerComparison.gte

      return {
        status: 'open',
        side: orderIntToPositionSide(order.triggerOrderSide),
        orderDelta: Big6Math.toFloatString(orderSize),
        orderDeltaNotional: formatBig6USDPrice(deltaNotional),
        type,
        triggerPrice: formatBig6USDPrice(BigInt(order.triggerOrderPrice)),
        triggerPriceUnformatted: BigInt(order.triggerOrderPrice),
        market,
        marketAddress: getAddress(order.marketAddress),
        nonce: BigInt(order.nonce),
        transactionHash: order.transactionHash,
        blockTimestamp: order.blockTimestamp,
        projectedFee: formatBig6USDPrice(BigInt(order.triggerOrderFee)),
        details: order,
      }
    })
  return orders || []
}


export const getOrderTypeFromOrder = (order: FormattedOpenOrder) => {
  if (BigInt(order.details.triggerOrderDelta) > 0n) {
    return OrderTypes.limit
  }
  if (order.side === PositionSide.long) {
    return order.type === TriggerComparison.lte ? OrderTypes.stopLoss : OrderTypes.takeProfit
  } else {
    return order.type === TriggerComparison.gte ? OrderTypes.stopLoss : OrderTypes.takeProfit
  }
}
