import useSWR from "swr";
import { AbstractProvider } from "ethers";
import { useActiveProvider } from ".";
import { getTCAPOracleContract, getUSDCOracleContract } from "../utils/contracts";


const tcapOraclePriceFetcher =
  (provider: AbstractProvider) =>
    async ([_, chainId]: [_: string, chainId: number]) => {
      const tcapOracle = getTCAPOracleContract(chainId, provider);
      return tcapOracle.latestAnswer();
    };

export const useTcapOraclePrice = () => {
  const { chainId, multiCallProvider } = useActiveProvider();

  return useSWR<bigint | null>(
    ["tcapOraclePrice", chainId],
    tcapOraclePriceFetcher(multiCallProvider),
    { revalidateOnFocus: false },
  )
}

const usdcOraclePriceFetcher =
  (provider: AbstractProvider) =>
    async ([_, chainId]: [_: string, chainId: number]) => {
      const usdcOracle = getUSDCOracleContract(chainId, provider);
      return usdcOracle.latestAnswer();
    };

export const useUSDCOraclePrice = () => {
  const { chainId, multiCallProvider } = useActiveProvider();

  return useSWR<bigint | null>(
    ["usdcOraclePrice", chainId],
    usdcOraclePriceFetcher(multiCallProvider),
    { revalidateOnFocus: false },
  )
}

