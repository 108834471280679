import React, { createContext, useContext, useEffect, useState } from 'react'
import { PerennialVaultType, VaultSnapshots } from '@perennial/sdk'

import { useVaultSnapshots2 } from '../hooks/vaults2'

type VaultContextType = {
  vaultSnapshots?: VaultSnapshots
  status: 'pending' | 'error' | 'success'
  selectedVault?: PerennialVaultType
  setSelectedVault: (type: PerennialVaultType) => void
}

const VaultContext = createContext<VaultContextType>({
  vaultSnapshots: undefined,
  status: 'pending',
  selectedVault: PerennialVaultType.alpha,
  setSelectedVault: (index: PerennialVaultType) => {},
})

export const VaultProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedVault, setSelectedVault] = useState<PerennialVaultType>(PerennialVaultType.alpha)
  const { data: vaultSnapshots, status } = useVaultSnapshots2()

  useEffect(() => { 
    const urlParams = new URLSearchParams(window.location.search)
    const vaultTypeFromParams = urlParams.get('vault-type')?.toLowerCase()

    if (vaultTypeFromParams && vaultTypeFromParams in PerennialVaultType) {
      setSelectedVault(vaultTypeFromParams as PerennialVaultType)
    }
  }, [])

  return (
    <VaultContext.Provider
      value={{
        vaultSnapshots,
        status,
        selectedVault,
        setSelectedVault,
      }}
    >
      {children}
    </VaultContext.Provider>
  )
}

export const useVaultContext = () => {
  const context = useContext(VaultContext)
  if (!context) {
    throw new Error('useVaultContext must be used within a VaultProvider')
  }
  return context
}
