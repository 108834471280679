import React from "react"
import { Tabs, Tab } from "react-bootstrap/esm"

import { PositionsList } from "./PositionsList"
import { PositionsHistory } from "./History"
import Orders from "../Orders"
import { useMediaQuery } from "react-responsive"
import { useTranslation } from "react-i18next"


const Positions = ({ isProInterface }: { isProInterface: boolean }) => {
  const { t } = useTranslation()
  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 850px)'
  })

  return (
    <div className="positions">
      <Tabs
        defaultActiveKey="my-positions"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab key="my-positions" eventKey="my-positions" title={t("my-positions")}>
          <PositionsList isProInterface={isProInterface} />
        </Tab>
        {!isProInterface && (
          <Tab key="orders" eventKey="orders" title={t("orders")}>
            <Orders />
          </Tab>
        )}
        {!isMobileOrTablet && (
          <Tab key="history" eventKey="history" title={t("positions-history")}>
            <PositionsHistory isProInterface={isProInterface} />
          </Tab>
        )}
      </Tabs>    
    </div>    
  )
};

export default Positions;
