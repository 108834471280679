
import React, { useCallback, useEffect, useState } from "react"
import { NumericFormat } from "react-number-format"
import { useTranslation } from "react-i18next"
import { useActiveProvider, useProductSnapshot } from "../hooks"
import { calculatePositionFee, getRateDisplay, nextPosition, isNumber, addPositions, BigMath } from "../utils/utils"
import { getProductContract } from "../utils/contracts"
import { CustomTooltip } from "../../components/common"

type props = {
  productAddress: string;
  positionDelta: bigint;
  isTaker: boolean;
  isLiquidity: boolean;
};

export const Fees = ({ productAddress, positionDelta, isTaker, isLiquidity }: props) => {
  const { t } = useTranslation()
  const { multiCallProvider } = useActiveProvider()
  const { product } = useProductSnapshot(productAddress)
  const [nextFunding, setNextFunding] = useState(0n)

  const pre = product ? product.pre : undefined
  const position = product ? product.position : undefined
  const tokenPrice = product ? product.latestVersion.price : 0n

  const calcPositionFee = useCallback(
    () => {
      let pf = 0;
      if (product && isNumber(positionDelta)) {
        const tradingFee = isTaker ? product.productInfo.takerFee : product.productInfo.makerFee
        pf = calculatePositionFee(tokenPrice, BigMath.abs(positionDelta), tradingFee);
      }
      return parseFloat(pf.toFixed(4));
    },
    [tokenPrice, positionDelta, product, isTaker]
  );

  useEffect(() => {
    const fetch = async () => {
      if (pre && position) {
        const positionChange = {
          maker: 0n,
          taker: positionDelta,
        }
        const nextGlobalPosition = nextPosition(pre, addPositions(position, positionChange));
        const productContract = getProductContract(
          multiCallProvider,
          productAddress
        );
        
        setNextFunding(BigInt(await productContract.rate(nextGlobalPosition)) * 60n * 60n);
      }  
    }

    fetch()
  },
    // eslint-disable-next-line
    [position, pre, productAddress, multiCallProvider]
  )  

  return (
    <div className="fees">
      <div className={"fees-container"}>
        {!isLiquidity && (
          <div className="row-value">
            <CustomTooltip
              id="ol-fee1"
              msg={("tooltip.maker-taker-fee")}
              showIcon={true}
              iconOnLeft={true}
              iconSize={13}
            >
              <h6 className="bold margin-left">
                {t("maker")}/{t("taker")} {t("fee")}:
              </h6>
            </CustomTooltip>  
            <CustomTooltip
              id="ol-fee2"
              msg={("tooltip.maker-taker-fee")}
            >
              <NumericFormat
                className="balance-usd bold"
                value={calcPositionFee()}
                displayType="text"
                thousandSeparator=","
                decimalScale={4}
                suffix=" USD"
              />
            </CustomTooltip>  
          </div>
        )}
        <div className="row-value">
          <CustomTooltip
            id="ol-hfunding1"
            msg={("tooltip.hourly-funding-rate")}
            showIcon={true}
            iconOnLeft={true}
            iconSize={13}
          >
            <h6 className="bold margin-left">
              {t("hourly-funding-rate")}:
            </h6>
          </CustomTooltip>  
          <CustomTooltip
            id="ol-hfunding2"
            msg={("tooltip.hourly-funding-rate")}
          >
            <NumericFormat
              className="balance-usd bold"
              value={getRateDisplay(nextFunding)}
              displayType="text"
              thousandSeparator=","
              decimalScale={5}
              suffix="%"
            />
          </CustomTooltip>  
        </div>
      </div>
    </div>  
  )    
}
