import React, { createContext, useContext, useState } from 'react'

import { DefaultChainId, SetTokenMetadata, SupportedSetTokens, UserComponentsUnitsType, UserComponentsStatusType } from "../constants/crypdex";
import { SupportedChainIdType } from "../constants/network"
import { SetTokenSnapshots, SetTokenSnapshot, useSetTokensSnapshots } from "../hooks/crypdex";
import { useChainId } from '../hooks/network';


type CrypdexContextType = {
  chainId: SupportedChainIdType;
  setTokenMetadata: (typeof SetTokenMetadata)[SupportedSetTokens];
  selectedSetToken: SupportedSetTokens;
  setSelectedSetToken: (asset: SupportedSetTokens) => void;
  snapshots?: SetTokenSnapshots;
  selectedSetTokenSnapshot?: SetTokenSnapshot;
  userCurrentSetToken?: { balance: bigint, allowance: bigint }
  currentUserComponentsUnits?: UserComponentsUnitsType
  setCurrentUserComponentsUnits: (component: UserComponentsUnitsType) => void,
  currentUserComponentsStatus?: UserComponentsStatusType,
  setCurrentUserComponentsStatus: (component: UserComponentsStatusType) => void,
}

const CrypdexContext = createContext<CrypdexContextType>({
  chainId: DefaultChainId,
  setTokenMetadata: SetTokenMetadata[SupportedSetTokens.meem],
  selectedSetToken: SupportedSetTokens.meem,
  setSelectedSetToken: (asset: SupportedSetTokens) => {},
  snapshots: undefined,
  selectedSetTokenSnapshot: undefined,
  userCurrentSetToken: undefined,
  currentUserComponentsUnits: undefined,
  setCurrentUserComponentsUnits: (component: UserComponentsUnitsType) => { },
  currentUserComponentsStatus: undefined,
  setCurrentUserComponentsStatus: (component: UserComponentsStatusType) => { },
});

export const CrypdexProvider = ({ children }: { children: React.ReactNode }) => { 
  const chainId = useChainId();
  const [selectedSetToken, _setSelectedSetToken] = useState<SupportedSetTokens>(SupportedSetTokens.meem);
  const [currentUserComponentsUnits, _setCurrentUserComponentsUnits] = useState<UserComponentsUnitsType | undefined>();
  const [currentUserComponentsStatus, _setCurrentUserComponentsStatus] = useState<UserComponentsStatusType | undefined>();
  const { data: snapshots } = useSetTokensSnapshots();

  const setSelectedSetToken = (setToken: SupportedSetTokens) => {
    _setSelectedSetToken(setToken);
  }

  const setCurrentUserComponentsUnits = (component: UserComponentsUnitsType) => {
    _setCurrentUserComponentsUnits(component);
  }

  const setCurrentUserComponentsStatus = (component: UserComponentsStatusType) => {
    _setCurrentUserComponentsStatus(component);
  }

  return (
    <CrypdexContext.Provider
      value={{
        chainId,
        setTokenMetadata: SetTokenMetadata[selectedSetToken],
        selectedSetToken,
        setSelectedSetToken,
        snapshots,
        selectedSetTokenSnapshot: snapshots?.setTokens?.[selectedSetToken],
        userCurrentSetToken: snapshots?.user?.[selectedSetToken],
        currentUserComponentsUnits,
        setCurrentUserComponentsUnits,
        currentUserComponentsStatus,
        setCurrentUserComponentsStatus,
      }}
    >
      {children}
    </CrypdexContext.Provider>
  );
}

export const useSetTokenContext = () => {
  const context = useContext(CrypdexContext)
  if (context === undefined) {
    throw new Error('useSetTokenContext must be used within a SetTokenProvider')
  }
  return context
}
