import React, { createContext, useContext, useEffect, useState } from 'react'
import {
  ChainMarkets,
  MarketSnapshot,
  MarketSnapshots,
  PositionSide,
  SupportedMarket,
  SupportedChainId,
  UserMarketSnapshot,
} from '@perennial/sdk'
import ReactGA from "react-ga4"

// import { AdjustmentModalProps } from '@/components/pages/Trade/TradeForm/components/AdjustPositionModal'
import { MarketMetadata } from '../constants/markets'
import { DefaultChain } from '../constants/network'
import { AdjustmentModalProps } from '../components/Trade/types';
import { useMarketSnapshots2 } from '../hooks/markets2'
import { usePerpetualsChainId } from '../hooks/network'

export enum PositionsTab {
  current,
  all,
  history,
}

export type OverrideValues = Pick<
  AdjustmentModalProps,
  'market' | 'position' | 'asset' | 'orderValues' | 'positionSide' | 'positionDelta'
>

type MarketContextType = {
  chainId: SupportedChainId
  orderDirection: PositionSide.long | PositionSide.short
  setOrderDirection: (orderDirection: PositionSide.long | PositionSide.short) => void
  marketMetadata: (typeof MarketMetadata)[SupportedMarket]
  selectedMarket: SupportedMarket
  setSelectedMarket: (asset: SupportedMarket) => void
  selectedMakerMarket: SupportedMarket
  setSelectedMakerMarket: (makerMarket: SupportedMarket) => void
  activePositionTab: PositionsTab
  setActivePositionTab: (tab: PositionsTab) => void
  isMaker: boolean
  // V2
  snapshots2?: MarketSnapshots
  userCurrentPosition?: UserMarketSnapshot
  selectedMarketSnapshot2?: MarketSnapshot
  setOverrideValues: (overrideValues?: OverrideValues) => void
  overrideValues?: OverrideValues
  manualCommitment: boolean
}

const MarketContext = createContext<MarketContextType>({
  chainId: DefaultChain.id,
  orderDirection: PositionSide.long,
  setOrderDirection: (orderDirection: PositionSide.long | PositionSide.short) => {},
  selectedMarket: SupportedMarket.eth,
  marketMetadata: MarketMetadata[SupportedMarket.eth],
  setSelectedMarket: (asset: SupportedMarket) => {},
  setSelectedMakerMarket: (makerMarket: SupportedMarket) => {},
  selectedMakerMarket: SupportedMarket.eth,
  activePositionTab: PositionsTab.current,
  setActivePositionTab: (tab: PositionsTab) => {},
  isMaker: false,
  // V2
  snapshots2: undefined,
  userCurrentPosition: undefined,
  selectedMarketSnapshot2: undefined,
  setOverrideValues: (overrideValues?: OverrideValues) => {},
  overrideValues: undefined,
  manualCommitment: false,
})

export const MarketProvider = ({ children, isMaker }: { children: React.ReactNode; isMaker?: boolean }) => {
  const chainId = usePerpetualsChainId()
  const [selectedMarket, _setSelectedMarket] = useState<SupportedMarket>(SupportedMarket.eth)
  const [selectedMakerMarket, _setSelectedMakerMarket] = useState<SupportedMarket>(SupportedMarket.eth)
  const [orderDirection, _setOrderDirection] = useState<PositionSide.long | PositionSide.short>(PositionSide.long)
  const [activePositionTab, setActivePositionTab] = useState<PositionsTab>(PositionsTab.current)
  const [overrideValues, setOverrideValues] = useState<OverrideValues | undefined>(undefined)
  const [manualCommitment, setManualCommitment] = useState<boolean>(false)
  const { data: snapshots2 } = useMarketSnapshots2()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const marketFromParams = urlParams.get('market')?.toLowerCase()
    const makerMarketFromParams = urlParams.get('makerMarket')?.toLowerCase() as SupportedMarket
    const directionFromParams = urlParams.get('direction')?.toLowerCase()
    if (marketFromParams && marketFromParams in ChainMarkets[chainId]) {
      ReactGA.send({ hitType: "pageview", page: `/perpetuals/?market=${marketFromParams}`, title: "Perpetuals Trade" });  
      _setSelectedMarket(marketFromParams as SupportedMarket)
    } else {
      const marketFromLocalStorage = localStorage.getItem(`${chainId}_market`)
      const makerMarketFromLocalStorage = localStorage.getItem(`${chainId}_makerMarket`)

      if (marketFromLocalStorage && marketFromLocalStorage in ChainMarkets[chainId]) {
        _setSelectedMarket(marketFromLocalStorage as SupportedMarket)
      }

      if (makerMarketFromLocalStorage && makerMarketFromLocalStorage in ChainMarkets[chainId]) {
        _setSelectedMakerMarket(makerMarketFromLocalStorage as SupportedMarket)
      }
    }

    if (makerMarketFromParams && makerMarketFromParams in ChainMarkets[chainId]) {
      _setSelectedMakerMarket(makerMarketFromParams as SupportedMarket)
    }

    if (makerMarketFromParams && makerMarketFromParams in ChainMarkets[chainId]) {
      _setSelectedMakerMarket(makerMarketFromParams as SupportedMarket)
    }

    if (directionFromParams && [PositionSide.long, PositionSide.short].includes(directionFromParams as PositionSide)) {
      _setOrderDirection(directionFromParams as PositionSide.long | PositionSide.short)
    } else {
      const directionFromLocalStorage = localStorage.getItem(`${chainId}_orderDirection`)

      if (
        directionFromLocalStorage &&
        [PositionSide.long, PositionSide.short].includes(directionFromLocalStorage as PositionSide)
      ) {
        _setOrderDirection(directionFromLocalStorage as PositionSide.long | PositionSide.short)
      }
    }
  },
    // eslint-disable-next-line
    []
  )

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const manualCommitmentFromParams = urlParams.get('manualCommitment')?.toLowerCase()

    if (manualCommitmentFromParams === 'true' && !manualCommitment) setManualCommitment(true)

    if (!(selectedMarket in ChainMarkets[chainId])) {
      _setSelectedMarket((Object.keys(ChainMarkets[chainId])[0] as SupportedMarket) ?? SupportedMarket.eth)
    }

    if (!(selectedMakerMarket in ChainMarkets[chainId])) {
      _setSelectedMakerMarket(SupportedMarket.eth)
    }
  }, [chainId, selectedMarket, selectedMakerMarket, manualCommitment])

  const setSelectedMarket = (asset: SupportedMarket) => {
    localStorage.setItem(`${chainId}_market`, asset)
    _setSelectedMarket(asset)
  }

  const setSelectedMakerMarket = (makerMarket: SupportedMarket) => {
    localStorage.setItem(`${chainId}_makerMarket`, makerMarket)
    _setSelectedMakerMarket(makerMarket)
  }

  const setOrderDirection = (orderDirection: PositionSide.long | PositionSide.short) => {
    localStorage.setItem(`${chainId}_orderDirection`, orderDirection)
    _setOrderDirection(orderDirection)
  }

  return (
    <MarketContext.Provider
      value={{
        chainId,
        orderDirection,
        setOrderDirection,
        selectedMarket,
        setSelectedMarket,
        snapshots2,
        marketMetadata: MarketMetadata[isMaker ? selectedMakerMarket : selectedMarket],
        activePositionTab,
        setActivePositionTab,
        setSelectedMakerMarket,
        selectedMakerMarket,
        isMaker: isMaker ?? false,
        userCurrentPosition: snapshots2?.user?.[isMaker ? selectedMakerMarket : selectedMarket],
        selectedMarketSnapshot2: snapshots2?.market?.[isMaker ? selectedMakerMarket : selectedMarket],
        setOverrideValues,
        overrideValues,
        manualCommitment,
      }}
    >
      {children}
    </MarketContext.Provider>
  )
}

export const useMarketContext = () => {
  const context = useContext(MarketContext)
  if (context === undefined) {
    throw new Error('useMarketContext must be used within a MarketProvider')
  }
  return context
}
