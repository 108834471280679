import React, { useState } from "react"
import { Button, Image, Spinner, Stack } from "react-bootstrap/esm"
import { ExplorerURLs, formatBig6,  formatBig6Percent, formatBig6USDPrice} from "@perennial/sdk"
import { useTranslation } from "react-i18next"
import { FaCaretDown } from "react-icons/fa"

import { useMarketContext } from "../../contexts"
import { MarketMetadata } from "../../constants/markets"
import { shortenAddress } from "../../utils/addressUtils"
import { usePerpetualsChainId } from "../../hooks/network"
import { useMediaQuery } from "react-responsive"
import { capitalize } from "../../v1/utils/utils"
import { useSettlementFees } from "../../hooks/markets2"


export const MarketInfo = () => {
  const { t } = useTranslation()
  const chainId = usePerpetualsChainId()
  const { data: settlementFees } = useSettlementFees()
  const { isMaker, selectedMarket, selectedMakerMarket, selectedMarketSnapshot2 } = useMarketContext()
  
  if (!selectedMarketSnapshot2) {
    return (
      <div className="market-info">
        <Spinner animation="border" variant="primary" className="xs" />
      </div>
    )
  }

  const {
    marketAddress,
    oracle,
    riskParameter: {
      takerFee,
      makerFee,
      liquidationFee,
      margin,
      minMargin,
      maintenance,
      minMaintenance,
      efficiencyLimit,
      makerReceiveOnly,
      staleAfter,
    },
    parameter: {
      fundingFee,
      interestFee,
      riskFee,
      maxPendingGlobal,
      maxPendingLocal,
    },
    global: {
      latestPrice,
      oracleFee,
    },
  } = selectedMarketSnapshot2

  const settlementFee = settlementFees ? settlementFees[isMaker ? selectedMakerMarket : selectedMarket].totalCost : 0n

  // const takerFeeMax = takerFee + takerSkewFee + takerImpactFee
  // const makerFeeMax = makerFee + makerImpactFee

  return (
    <div className="market-info-wrapper">
      <Stack
        direction={"vertical"}
        className="market-info-container"
        gap={0}
      >
        <Stack direction="vertical" className="left">
          <MarketRow label={t("market")} value={MarketMetadata[isMaker ? selectedMakerMarket : selectedMarket].symbol} />
          <MarketRow
            label={t("address")}
            value={
              <a href={`${ExplorerURLs[chainId]}/address/${marketAddress}`} target="_blank" rel="noreferrer">
                {shortenAddress(marketAddress)}
              </a>
            }
          />
          <MarketRow
            label={t("price-feed")}
            value={
              <a href={`${ExplorerURLs[chainId]}/address/${oracle}`} target="_blank" rel="noreferrer">
                {shortenAddress(oracle)}
              </a>
            }
          />
          <MarketRow label={t("collateral")} value={'DSU / USDC'} />
          <MarketRow label={t("metrics.margin-min")} value={`${formatBig6Percent(margin)} (${formatBig6USDPrice(minMargin)})`} />
          <MarketRow label={t("metrics.maintenance")} value={`${formatBig6Percent(maintenance)} (${formatBig6USDPrice(minMaintenance)})`} />
          <MarketRow label={t("metrics.oracle-fee")} value={formatBig6Percent(oracleFee)} />
          {/* <MarketRow label={t("metrics.position-fee")} value={formatBig6Percent(positionFee)} /> */}
          <MarketRow label={t("metrics.risk-fee")} value={formatBig6Percent(riskFee)} />
          {/* <MarketRow label={t("metrics.taker-close-always")} value={capitalize(String(takerCloseAlways))} /> */}
          {/* <MarketRow label={t("metrics.maker-close-always")} value={capitalize(String(makerCloseAlways))} /> */}
          <MarketRow label={t("metrics.stale-after")} value={`${String(staleAfter)}s`} />
          <MarketRow label={t("latest-price")} value={formatBig6USDPrice(latestPrice)} />
          <MarketRow label={t("metrics.liq-fee")} value={formatBig6Percent(liquidationFee)} />
        </Stack>
        <Stack direction="vertical" className="right">
          <MarketRow label={t("metrics.funding-fee")} value={formatBig6Percent(fundingFee)} />
          <MarketRow label={t("metrics.interest-fee")} value={formatBig6Percent(interestFee)} />
          <MarketRow label={t("settlement-fee")} value={formatBig6USDPrice(settlementFee)} />
          <MarketRow label={t("metrics.taker-linear-fee")} value={formatBig6Percent(takerFee.linearFee)} />
          <MarketRow label={t("metrics.taker-proportional-fee")} value={formatBig6Percent(takerFee.proportionalFee)} />
          <MarketRow label={t("metrics.taker-adiabatic-fee")} value={formatBig6Percent(takerFee.adiabaticFee)} />
          <MarketRow label={t("metrics.taker-scale")} value={formatBig6(takerFee.scale)} />
          <MarketRow label={t("metrics.maker-linear-fee")} value={formatBig6Percent(makerFee.linearFee)} />
          <MarketRow label={t("metrics.maker-proportional-fee")} value={formatBig6Percent(makerFee.proportionalFee)} />
          {/* <MarketRow label={t("metrics.maker-adiabatic-fee")} value={formatBig6Percent(makerFee.adiabaticFee)} /> */}
          <MarketRow label={t("metrics.maker-scale")} value={formatBig6(makerFee.scale)} />
          <MarketRow label={t("metrics.efficiency-limit")} value={formatBig6Percent(efficiencyLimit)} />
          <MarketRow label={t("metrics.max-pending-global")} value={Number(maxPendingGlobal)} />
          <MarketRow label={t("metrics.max-pending-local")} value={Number(maxPendingLocal)} />
          <MarketRow label={t("metrics.maker-receive-only")} value={capitalize(String(makerReceiveOnly))} />
        </Stack>
      </Stack>
    </div>
  )     
}


export const MarketInfoButton = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const [showInfo, setShowInfo] = useState(false)
  const { isMaker, selectedMarket, selectedMakerMarket, selectedMarketSnapshot2 } = useMarketContext()
  const currentMarket = !isMaker ? selectedMarket : selectedMakerMarket
  const currentAssetMetadata = MarketMetadata[currentMarket]
  
  if (!selectedMarketSnapshot2) {
    return (
      <Button className="btn-market-info" disabled>
        <Spinner animation="border" variant="primary" className="xs" />
      </Button>
    )
  }

  return (
    <div className="market-info">
      <Button className="btn-market-info" active={showInfo} onClick={() => setShowInfo(!showInfo)}>
        {!isMobile ? (
          <Stack direction="horizontal" gap={1}>
            <Image src={currentAssetMetadata.icon} width={28} height={28} />
            <Stack direction="vertical">
              <span className="small">{currentAssetMetadata.symbol}</span>
              <span className="text-muted xs">{t("metrics.market-info")}</span>
            </Stack>
            <FaCaretDown size={18} />
          </Stack>
        ) : (
          <Stack direction="horizontal" gap={2} style={{ justifyContent: "center" }}>
            <span className="text-muted xs">{t("metrics.market-info")}</span>
            <FaCaretDown size={18} />
          </Stack>
        )}
      </Button>
      {showInfo && (
        <MarketInfo />
      )}
    </div>    
  )     
}

const MarketRow = ({ label, value }: { label: string, value: string | React.ReactNode }) => (
  <div className="info-row">
    <h6 className="text-muted">{label}</h6>
    {typeof value === 'string' ? <h6>{value}</h6> : value}
  </div>
)
