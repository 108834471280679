import React from "react"
import { Button, Spinner } from "react-bootstrap"
import { notEmpty } from "@perennial/sdk"
import { useHistoricalPositions } from "../../../../hooks/markets2/graph"

import { PositionsList } from "./PositionsList"
import { useTranslation } from "react-i18next"

export const PositionsHistory = ({ isProInterface }: { isProInterface: boolean }) => {
  const { t } = useTranslation()
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useHistoricalPositions(isProInterface)

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" variant="primary" />
      </div>
    )
  }

  const positions =
    data?.pages
      .map((page: any) => page?.positions)
      .flat()
      .filter(notEmpty) ?? []

  return (
    <div className="positions-history">
      <PositionsList positions={positions} />
      <div className="flex-row center p-3">
        {(hasNextPage || isLoading) && (
          <Button
            disabled={isLoading || isFetchingNextPage}
            onClick={() => fetchNextPage()}
            className="btn-more"
          >
            <div className="btn-spinner">
              {(isLoading || isFetchingNextPage) && <Spinner animation="border" variant="secondary" className="xs" />}
              {(isLoading || isFetchingNextPage) ? t("loading") : t("load-more")}
            </div>
          </Button>
        )}
      </div>
    </div>    
  )
}
