import React from "react";


export interface IGeolocationContext { 
  countryCode: string,
  setCurrentCountryCode: (currentCode: string) => void;
  isRestricted: () => boolean;
};

export const GEOLOCATION_DEFAULT_VALUE = {
  countryCode: "US",
  setCurrentCountryCode: () => {},
  isRestricted: () => true,
}

export const geoLocationContext = React.createContext<IGeolocationContext>(GEOLOCATION_DEFAULT_VALUE);
