import React, { useState } from "react"
import { formatBig6USDPrice } from "@perennial/sdk"
import { useTranslation } from "react-i18next"

import { CustomTooltip } from "../../../common"
import { Button } from "react-bootstrap"
import { NumericFormat } from "react-number-format"


export const TradingFees = ({
  isMaker,
  hasPositionChange,
  totalFee,
  settlementFee,
  interfaceFee,
  isClosePosition = false,
  isLimitOrder,
}:{
  isMaker: boolean,
  hasPositionChange: boolean,
  totalFee: { total: bigint, pct: bigint },
  settlementFee: bigint,
  interfaceFee: bigint,
  isClosePosition?: boolean,
  isLimitOrder?: boolean,
}) => {
  const { t } = useTranslation()  
  const [showFeeDetail, setShowFeeDetail] = useState(false)

  return (
    <div className="row-value">
      <div className="total-value-group">
        <div className="value-item total">
          <div className="value-item-desc">
            <CustomTooltip
              id="trad-fee"
              msg={t("tooltip.trading-fee")}
              showIcon={!isClosePosition}
              iconOnLeft={!isClosePosition}
              iconSize={13}
            >
              <h6 className={!isClosePosition ? "bold margin-left" : "title"}>
                {isLimitOrder ? t("est-trading-fee") : t("trading-fee")}
              </h6>
            </CustomTooltip>      
            <Button
              variant={"secondary small underline ".concat(isClosePosition ? "title" : "")}
              onClick={() => setShowFeeDetail(!showFeeDetail)}
            >
              {showFeeDetail ? `(${t("hide")})` : `(${t("detail")})`}
            </Button>
            <h6>:</h6>  
          </div>
          <NumericFormat
            className={!isClosePosition ? "bold" : "number"}
            value={formatBig6USDPrice(
              hasPositionChange ? interfaceFee + settlementFee + totalFee.total : 0n,
            )}
            displayType="text"
            thousandSeparator=","
            decimalScale={4}
            prefix="$"
          />
        </div>
        <div className={"total-value-group-detail ".concat(showFeeDetail ? "show" : "hide")}>
          <div className="value-item">
            <h6>{t("settlement-fee")}:</h6>
            <span className="number bold">
              {formatBig6USDPrice(settlementFee)}
            </span>
          </div>
          <div className="value-item">
            <h6>{t("market-fee")}:</h6>
            <span className="number bold">
              {formatBig6USDPrice(totalFee.total, { fullPrecision: true, compact: true })}  
            </span>
          </div>
          <div className="value-item">
            <h6>{t("interface-fee")}:</h6>
            <span className="number bold">
              {formatBig6USDPrice(interfaceFee)}
            </span>
          </div>
        </div>
      </div>
    </div>    
  )    
}
