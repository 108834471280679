import { useQuery } from '@tanstack/react-query'
import { Address, parseUnits, zeroAddress } from 'viem'
import { Big18Math } from '@perennial/sdk'
import { useAddress, useChainId } from '../network'

import {
  chainSetTokensWithAddress,
  ChainSetTokens,
  SupportedSetTokens,
  addressToComponent,
  SupportedComponents,
  getSetTokenContract,
  getERC20Contract,
  ChainComponentsTokens,
  BasicIssuanceModuleAddresses,
  SetTokenMetadata,
  getBasicIssuanceModuleContract,
  TokenExchangeSetIssuerAddresses,
  SupportedTokens,
} from '../../constants/crypdex'
import { useUsdcContract, useWethContract } from './contracts'
import { SupportedChainIdType } from '../../constants/network'
import { fetchBuyComponentsData } from '../paraswap'
import { DummyAddress } from '../../constants/paraswap'


export type SetTokenSnapshot = {
  components: SupportedComponents[],
  price: bigint
};

export type SetTokenSnapshots = {
  setTokens: Record<SupportedSetTokens, SetTokenSnapshot>,
  user: Record<SupportedSetTokens, { balance: bigint, allowance: bigint }>
}

export type UserSeTokenSnapshot = Record<SupportedSetTokens, { balance: bigint, allowance: bigint }>;


export const useSetTokensBalances = () => { 
  const chainId = useChainId()
  const address = useAddress()
  const setTokens = chainSetTokensWithAddress(chainId)

  return useQuery({
    queryKey: ['SetTokensBalances', chainId, address.address],
    enabled: true,
    queryFn: async () => {
      const balances = {} as Record<SupportedSetTokens, { balance: bigint, allowance: bigint }>;
      setTokens.forEach((setToken, index) => { 
        balances[setToken.asset] = {
          balance: 0n,
          allowance: 0n,
        }
      })

      if (address.address) {
        const balanceCalls = [];
        const allowanceCalls = [];
        for (const setToken of setTokens) {
          const contract = getSetTokenContract(chainId, setToken.setTokenAddress.token);
          balanceCalls.push(contract.read.balanceOf([address.address]))
          allowanceCalls.push(contract.read.allowance([address.address, TokenExchangeSetIssuerAddresses[chainId]]))
        }

        const response = await Promise.all(balanceCalls)
        const response2 = await Promise.all(allowanceCalls)
        setTokens.forEach((setToken, index) => {
          balances[setToken.asset] = {
            balance: response[index],
            allowance: response2[index],
          }
        })
      }

      return balances
    },
  })
}

export const useSetTokensSnapshots = () => { 
  const chainId = useChainId()
  const address = useAddress()
  const { data: balances } = useSetTokensBalances();

  return useQuery({
    queryKey: ['SetTokensSnapshot', chainId, address, !!balances],
    enabled: true,
    queryFn: async () => {
      if (balances === undefined) return {} as SetTokenSnapshots;

      const snapshots = await fetchSetTokensSnapshots(chainId, address.address || zeroAddress)

      return {
        setTokens: snapshots,
        user: balances
      }
    },
  })
}

export const fetchSetTokensSnapshots = async (chainId: SupportedChainIdType, address: Address) => {
  const setTokens = chainSetTokensWithAddress(chainId)

  const componentCalls = setTokens.map((setToken) => {
    const contract = getSetTokenContract(chainId, setToken.setTokenAddress.token);
    return contract.read.getComponents();
  })

  const components = await Promise.all(componentCalls);
  const prices = [Big18Math.fromFloatString("10")]; // await Promise.all(priceCalls)]

  const snapshot = {} as Record<SupportedSetTokens, { components: SupportedComponents[], price: bigint }>;
  setTokens.forEach((setToken, index) => {
    const supportedComponents = components[index].map((address) => addressToComponent(address)) as SupportedComponents[];

    snapshot[setToken.asset] = {
      components: supportedComponents,
      price: prices[index],
    }
  })

  return snapshot;
}

export const useComponentUnitsForIssue = (setToken: SupportedSetTokens, amount: bigint) => {
  const chainId = useChainId()

  return useQuery({
    queryKey: ['ComponentUnitsForIssue', chainId, setToken, amount.toString()],
    enabled: true,
    queryFn: async () => {
      return await fetchComponentUnitsForIssue(chainId, setToken, amount);
    },
  })
}

export const fetchComponentUnitsForIssue = async (chainId: SupportedChainIdType, setToken: SupportedSetTokens, amount: bigint) => { 
  const basicIssuanceModule = getBasicIssuanceModuleContract(chainId);
  const setTokenAddress = ChainSetTokens[chainId][setToken]

  if (!setTokenAddress) return {} as Record<SupportedComponents, { address: Address, unit: bigint }>;

  const [ unitsForIssue ] = await Promise.all([
    basicIssuanceModule.read.getRequiredComponentUnitsForIssue([setTokenAddress.token, amount])
  ])

  const addresses = unitsForIssue[0]
  const units = unitsForIssue[1]

  const componentsUnitsForIssue = {} as Record<SupportedComponents, { address: Address, unit: bigint }>;
  addresses.forEach((address, index) => {
    const component = addressToComponent(address);
    if (component) {
      componentsUnitsForIssue[component] = {
        address,
        unit: units[index]
      }
    }
  })

  return componentsUnitsForIssue
}

export const useComponentsBalances = (components: SupportedComponents[]) => { 
  const chainId = useChainId()
  const address = useAddress()

  return useQuery({
    queryKey: ['ComponentsBalances', chainId, address.address, components],
    enabled: !!address.address && components.length > 0,
    queryFn: async () => {
      const issuanceModuleAddress = BasicIssuanceModuleAddresses[chainId];
      const userAddress = address.address || zeroAddress;

      if (userAddress === zeroAddress) return;

      const balanceCalls = components.map((component) => {
        const componentAddress = ChainComponentsTokens[chainId][component]?.token || zeroAddress;
        const contract = getERC20Contract(chainId, componentAddress);
        return contract.read.balanceOf([userAddress])
      })
      const allowanceCalls = components.map((component) => {
        const componentAddress = ChainComponentsTokens[chainId][component]?.token || zeroAddress;
        const contract = getERC20Contract(chainId, componentAddress);
        return contract.read.allowance([userAddress, issuanceModuleAddress])
      })

      const balances = await Promise.all(balanceCalls);
      const allowances = await Promise.all(allowanceCalls);

      const componentsBalances = {} as Record<SupportedComponents, { balance: bigint, allowance: bigint }>;
      components.forEach((component, index) => { 
        componentsBalances[component] = {
          balance: balances[index],
          allowance: allowances[index],
        }
      })

      return componentsBalances;
    },
  })
}

export const useAmountToMint = (setToken: SupportedSetTokens, token: SupportedTokens, amount: number) => {
  const chainId = useChainId()
  const address = useAddress()
  const { data: componentsUnits } = useComponentUnitsForIssue(setToken, Big18Math.fromFloatString("1"));
  
  return useQuery({
    queryKey: ['AmountToMint', chainId, address, amount],
    enabled: !!componentsUnits,
    queryFn: async () => {
      return await fetchAmountToMint(chainId, setToken, token, amount, address.address || DummyAddress);
    },
  })
}

export const fetchAmountToMint = async (
  chainId: SupportedChainIdType,
  setToken: SupportedSetTokens,
  token: SupportedTokens,
  tokenAmount: number,
  userAddress: Address,
) => { 
  const data = await fetchBuyComponentsData(chainId, setToken, Big18Math.fromFloatString("1"), token, userAddress); 
  
  let priceOneSetToken = Object.keys(data).reduce((total, componentToken) => {
    const componentU = data[componentToken  as SupportedComponents];
    return total + componentU.srcAmount;
  }, 0);

  if (priceOneSetToken === 0) {
    return {
      setTokenAmount: 0,
      setTokenAmountBI: 0n,
    };
  }  

  const setTokenMetadata = SetTokenMetadata[setToken];
  const setTokenAmount = tokenAmount / priceOneSetToken;
  const setTokenAmountBI = parseUnits(setTokenAmount.toString(), setTokenMetadata.decimals);

  return {
    setTokenAmount,
    setTokenAmountBI,
  }
}

export const useSetTokenPrice = (setToken: SupportedSetTokens) => { 
  const chainId = useChainId()

  return useQuery({
    queryKey: ['SetTokenPrice', chainId, setToken],
    enabled: true,
    refetchInterval: 300000,
    refetchIntervalInBackground: false,
    queryFn: async () => {
      const data = await fetchBuyComponentsData(chainId, setToken, Big18Math.fromFloatString("1"), SupportedTokens.usdc, DummyAddress);  
      
      let priceOneSetToken = Object.keys(data).reduce((total, componentToken) => {
        const componentU = data[componentToken  as SupportedComponents];
        return total + componentU.srcAmount;
      }, 0);

      /* const componentsUnits = await fetchComponentUnitsForIssue(chainId, setToken, Big18Math.fromFloatString("1"));
      if (!componentsUnits) return { priceOneSetToken: 0 };

      const components = Object.keys(componentsUnits) as SupportedComponents[];
      const usdcPrices = await fetchTokensUsdcPrices(components);

      let priceOneSetToken = Object.keys(componentsUnits).reduce((total, componentToken) => {
        const componentU = componentsUnits[componentToken  as SupportedComponents];
        const componentData = usdcPrices.find((price) => price.srcToken.toLowerCase() === componentU.address.toLowerCase());
        
        if (!componentData) return total;

        const cp = parseFloat(formatUnits(componentU.unit, componentData.srcDecimals)) * parseFloat(componentData.srcUSD || "0")
        return total + cp;
      }, 0);

      const flokiUnits = componentsUnits[SupportedComponents.floki].unit;
      const flokiMetadata = ComponentMetadata[SupportedComponents.floki];
      const flokiData = await computeFlokiTransferData(chainId, SupportedTokens.usdc, flokiUnits, "0xc0ffee254729296a45a3885639AC7E10F9d54979");

      console.log("flokiData: ", formatUnits(flokiData.amountIn, flokiMetadata.decimals));
      priceOneSetToken = priceOneSetToken + parseFloat(formatUnits(flokiData.amountIn, flokiMetadata.decimals)); */

      return { priceOneSetToken }
    },
  })
}

export const useTokensBalances = () => { 
  const chainId = useChainId()
  const address = useAddress()
  const usdcContract = useUsdcContract()
  const wethContract = useWethContract()

  return useQuery({
    queryKey: ['ExchangeIssuerBalances', chainId, address],
    enabled: !!address.address,
    queryFn: async () => {
      const exchangeSetIssuerAddress = TokenExchangeSetIssuerAddresses[chainId];
      const balances = {} as Record<SupportedTokens, { balance: bigint, allowance: bigint }>;
      Object.keys(SupportedTokens).forEach((token) => { 
        balances[token as SupportedTokens] = {
          balance: 0n,
          allowance: 0n,
        }
      })

      if (address.address) {
        const balancesCalls = await Promise.all([
          usdcContract.read.balanceOf([address.address]),
          usdcContract.read.allowance([address.address, exchangeSetIssuerAddress]),
          wethContract.read.balanceOf([address.address]),
          wethContract.read.allowance([address.address, exchangeSetIssuerAddress]),
        ])

        for (let i = 0; i < balancesCalls.length; i += 2) {
          const token = i === 0 ? SupportedTokens.usdc : SupportedTokens.weth;
          balances[token] = {
            balance: balancesCalls[i],
            allowance: balancesCalls[i + 1],
          }
        }
      }
      return balances;
    },
  })
}
