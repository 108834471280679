import React from "react";
import ReactSlider from "react-slider";
import "../../styles/slider.scss";
import { useCryptexProductsContext } from "../../contexts";

type props = {
  defaultValue: number;
  value: number;
  maxValue: number;
  onChange: (value: number, index: number) => void;
}

export const LeverageSlider = ({ defaultValue, value, maxValue, onChange }: props) => {
  const cryptexProducts = useCryptexProductsContext()
  const step = cryptexProducts.isPerpetuals()
    ? (maxValue > 10 ? 1 : 0.1) : 1

  return (
    <ReactSlider
      className="horizontal-slider"
      marks
      markClassName="slider-mark"
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      min={cryptexProducts.isPerpetuals() ? 0 : 1}
      max={maxValue}
      step={step}
      thumbClassName="slider-thumb"
      trackClassName="slider-track"
      withTracks={true}
      renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
    />
  )  
};
