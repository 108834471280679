import React, { useContext, useState } from "react"
import { Button, Container, Nav, NavDropdown, Navbar } from "react-bootstrap/esm"
import { useTranslation } from "react-i18next"
import { AiOutlineMenu } from "react-icons/ai"
import { FaRegBell } from "react-icons/fa6";
import { MdOutlineLanguage } from "react-icons/md";
import { useMediaQuery } from "react-responsive"

import { ReactComponent as CryptexLogo } from "../assets/logo.svg"
import { ReactComponent as CryptexMobileLogo } from "../assets/cryptex.svg"
import { geoLocationContext } from "../v1/states"
import { CustomConnectButton } from "./CustomConnectButton"
import { LanguageDropdown, LanguageModal } from "./common/Localization"
import { LanguageMetadata, SupportedLanguage } from "../constants/localization"
import { PushNotifications } from "./push"
import { useCryptexProductsContext } from "../contexts"


const Header = () => {
  const { t, i18n } = useTranslation()
  const { isPerpetuals, isPerpetualsV1 } = useCryptexProductsContext();
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const isTablet = useMediaQuery({ query: "(min-width: 601px) and (max-width: 850px)" })
  const currentLanguage = (i18n.resolvedLanguage || SupportedLanguage.en) as SupportedLanguage
  const currentGeolocation = useContext(geoLocationContext)
  const [showLangModal, setShowLangModal] = useState(false)
  const [showNotifications, setShowNotifications] = useState(false)

  const onModalHide = () => {
    setShowLangModal(false)
  }

  const onNotificationsHide = () => { 
    setShowNotifications(false)
  }

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container fluid>
        <Navbar.Brand href="/">
          {isMobile ? <CryptexMobileLogo className="logo" width={32} height={32} /> : <CryptexLogo className="logo" />}
        </Navbar.Brand>
        <Navbar.Collapse id="navbar-nav">
          <Nav className="menu-container">
            <div className="menu">
              <NavDropdown id="trade-dropdown" title={"Perpetuals"}>
                <NavDropdown.Item href="/">
                  {t("trade")}
                </NavDropdown.Item>
                <NavDropdown.Item href="liquidity">
                  {t("provide")}
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/spot">
                Spot
              </Nav.Link>
              <Nav.Link href="/indexes">
                Indexes
              </Nav.Link>
              <Nav.Link href="/governance">
                CTX Governance
              </Nav.Link>
              {isMobile && (
                <>
                  <Navbar.Toggle
                    onClick={() => setShowNotifications(true)}
                    className="nav-btn-language"
                    style={{ borderBottom: "0.5px solid #9795b0 !important" }}
                  >
                    Notifications
                  </Navbar.Toggle>
                  <Navbar.Toggle onClick={() => setShowLangModal(true)} className="nav-btn-language">
                    <MdOutlineLanguage size={26} className="pe-1" />
                    {LanguageMetadata[currentLanguage].name}
                  </Navbar.Toggle>
                </>
              )}
            </div>
          </Nav>
        </Navbar.Collapse>
        <Nav.Item className="navbar-right">
          {currentGeolocation.isRestricted() && (isPerpetuals() || isPerpetualsV1()) && !isMobile ? (
            <>
              <div className="connect">
                <button className="btn btn-primary" type="button" disabled>
                  {t("app-not-available")}
                </button>
              </div>
              {(isTablet || isMobile) && (
                <Navbar.Toggle
                  aria-controls="navbar-nav"
                  children={
                    <AiOutlineMenu size={18} fontWeight={700} />
                  }  
                />    
              )}
            </>
          ) : (
            <>
              {(!currentGeolocation.isRestricted() || (currentGeolocation.isRestricted() && !isPerpetuals() && !isPerpetualsV1())) && (
                <div className="connect">
                  <CustomConnectButton />
                </div>
              )}
              {isTablet && <LanguageDropdown />}  
              <Navbar.Toggle
                aria-controls="navbar-nav"
                children={
                  <AiOutlineMenu size={24} fontWeight={700} />
                }
              />
            </>
          )}
          {!isTablet && !isMobile && <LanguageDropdown />}
          {!isMobile && (
            <Button
              variant="primary"
              className="border-black ps-1 pe-1"
              onClick={() => setShowNotifications(!showNotifications)}
              style={{ marginLeft: "0px", borderRadius: "3px" }}
            >
              <FaRegBell size={23} />
            </Button>
          )}
        </Nav.Item>
      </Container>
      <LanguageModal show={isMobile && showLangModal} onHide={onModalHide} />
      <PushNotifications showModal={showNotifications} onModalHide={onNotificationsHide} />
    </Navbar>
  )
};

export default Header
