import React, { useMemo, useState } from 'react';
import { Spinner, Stack } from 'react-bootstrap';
import { Toolbar } from './Toolbar';
import { Chart } from './Chart';
import { TimeFrames, TimeFramesMetadata } from './constants';
import { useMarketContext } from '../../contexts';
import { MarketMetadata } from '../../constants/markets';


export const TradingViewChart = () => {
  const { selectedMarket, selectedMakerMarket, isMaker, snapshots2 } = useMarketContext();
  const market = snapshots2?.market?.[isMaker ? selectedMakerMarket : selectedMarket]
  const currentMarket = isMaker ? selectedMakerMarket : selectedMarket;
  const [currentTimeFrame, setCurrentTimeFrame] = useState(TimeFrames.OneMinute);

  const { logicalFromTimestamp, fromTimestamp } = useMemo(() => {
    const now = new Date();
    const logicalFromTimestamp = (now.getTime() / 1000) - TimeFramesMetadata[currentTimeFrame].seconds;
    
    const tfMetadata = TimeFramesMetadata[currentTimeFrame].seconds <= TimeFramesMetadata[TimeFrames.OneHour].seconds
      ? TimeFramesMetadata[TimeFrames.OneHour]
      : TimeFramesMetadata[currentTimeFrame];
    const fromTimestamp = (now.getTime() / 1000) - tfMetadata.seconds;

    return {
      logicalFromTimestamp: Math.floor(logicalFromTimestamp),
      fromTimestamp: Math.floor(fromTimestamp),
    };
  }, [currentTimeFrame])

  return (
    <Stack direction="vertical" className="chart-wrapper mt-2 w-100">
      {market && MarketMetadata[currentMarket].isCryptexIndex ? (
        <>
          <Toolbar
            currentMarket={currentMarket}
            currentTimeFrame={currentTimeFrame}
            handleTimeFrameChange={(timeFrame) => setCurrentTimeFrame(timeFrame)}
          />
          <Chart
            indexSymbol={currentMarket}
            logicalFromTimestamp={logicalFromTimestamp}
            fromTimestamp={fromTimestamp}
            timeFrame={ currentTimeFrame}
          />
        </>  
      ) : (
        <div className="spinner-container trade">
          <Spinner animation="border" variant="primary" className="small" />
        </div>
      )}
    </Stack>
  );
};

