import React, { useMemo } from "react";
import { Button, Form, Image, InputGroup } from "react-bootstrap/esm";
import { SupportedMarket } from '@perennial/sdk'
import { NumericFormat } from "react-number-format";
import { useMediaQuery } from "react-responsive";
import { MarketMetadata } from '../../../../constants/markets'
import { isNumbersOnly } from "../../../../utils/utils";
import { useTranslation } from "react-i18next";

type props = {
  marketMetadata: (typeof MarketMetadata)[SupportedMarket];
  showTokenBalance: boolean;  
  changingAmount: boolean;
  amount: string;
  amountUsd: string;
}

export const IndexInput = ({
  marketMetadata,
  showTokenBalance,
  changingAmount,
  amount,
  amountUsd,
}: props) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const isAmountZero = useMemo(() => {
    let isAmountZero = false;

    if (isNumbersOnly(amount)) {
      isAmountZero = parseFloat(amount) === 0;
    }

    return isAmountZero;
  }, [amount])

  const IndexInfo = () => (
    <div className="index-info">
      <Image className="token-icon margin-right" src={marketMetadata.icon} width={20} height={20} />
      <h6>{marketMetadata.baseCurrency.toUpperCase()}</h6>
    </div>
  );
  
  return (
    <Form.Group className="asset-input-group">
      <div className="asset-input-top">
        <h6>
          {changingAmount ? t("change-amount") : t("amount")}
        </h6>
        <NumericFormat
          className="balance-usd"
          value={amountUsd}
          displayType="text"
          thousandSeparator=","
          suffix=" USD"
        />
      </div>
      <InputGroup className="input-group index">
        <Form.Control
          id="index-input"
          placeholder="0"
          type={isMobile ? "number" : "text"}
          className="balance-input index"
          value={!isAmountZero ? amount : ""}
          readOnly
        />
        <div className="asset-info">
          <IndexInfo />
          {showTokenBalance && (
            <div className="asset-balance">
              <span className="title">{t("balance")}:</span>
              <NumericFormat
                className="balance"
                value={10}
                displayType="text"
                decimalSeparator=","
              />
              <Button variant="secondary" className="small">
                {t("max")}
              </Button>
            </div>
          )}          
        </div>
      </InputGroup>
    </Form.Group>
  );       
};
