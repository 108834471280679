import React from "react"
import { useTranslation } from "react-i18next"
import logo from "../../assets/logo.svg"
import mainVideoWebm from "../../assets/videos/main.webm"

export const LoadingPage = () => {
  const { t } = useTranslation()

  return (
    <div className="loading-page">
      <div className="container-background-video">
        <video className="video-object" autoPlay loop muted>
          <source src={mainVideoWebm} type="video/mp4" />
        </video>
      </div>
      <div className="loading-page-container">
        <img
          src={logo}
          alt="cryptex logo"
          className="breathing-icon"
          height="70"
        />
        <h3 className="bold">{t("loading")}...</h3>
      </div>
    </div>
  )
}
