import React, { useEffect, useMemo, useState } from "react"
import { usePushContext } from "../../contexts";
import { Stack } from "react-bootstrap";


export const Notifications = () => {
  const pushAPI = usePushContext();
  const [notifications, setNotifications] = useState<any>([]);
  
  const loadNotifications = async () => { 
    if (pushAPI.subscriberApi) {
      const notifications = await pushAPI.subscriberApi.notification.list( undefined, {
        channels: [pushAPI.channelInfo.channel],
        limit: 20,
      })
      setNotifications(notifications);
    }
  }

  useEffect(() => {
    loadNotifications();
  },
    // eslint-disable-next-line
    []
  )

  return (
    <div>
      <Stack
        direction="vertical"
        gap={3}
        style={{
          padding: "1rem",
          overflowY: "auto",
          height: "calc(100vh - 350px)",
          border: "0.5px solid #2f2f2f",
          borderRadius: "5px",
          backgroundColor: "#0a0a0a",
        }}
      >
        {notifications.map((notification: any, index: number) => {
          return <Notification key={index} notification={notification} />
        })}
        <>
          {notifications.length === 0 && (
            <div style={{ textAlign: "center", color: "#fff" }}>
              <p>You haven't received notifications yet</p>
            </div>
          )}
        </>
      </Stack>
    </div>
  );
};

const Notification = ({ notification } : { notification: any }) => {
  // const date = new Date(notification.timestamp);

  const { title, date } = useMemo(() => {
    let title = notification.notification.title;
    const splitTitle = notification.notification.title.split("-");

    if (splitTitle.length > 1) {
      title = splitTitle[1];
    }

    let date;
    const tsPart = notification.message.split("timestamp:");
    if (tsPart.length > 1) { 
      try {
        const ts = parseInt(tsPart[1].replace("]", "").trim());
        date = new Date(ts * 1000);
      } catch (e) {}
    }

    return { title, date }
  }, [notification])

  return (
    <Stack
      direction="vertical"
      style={{
        backgroundColor: "#0f0f0f",
        border: "1px solid #494866",
        borderRadius: "5px",
        justifyContent: "space-between",
        height: "11rem",
        maxHeight: "11rem",
      }}
    >
      <div style={{ borderBottom: "1px solid #494866", padding: "0.5rem 0.5rem", }}>
        <p>{title}</p>
      </div>
      <div style={{ padding: "0.5rem 0.5rem", height: "80%" }}>
        <p>{notification.notification.body}</p>
      </div>
      <div style={{ padding: "0.2rem 0.5rem", textAlign: "right" }}>
        {date && (
          <span className="small text-muted text-right">
            {date.toLocaleDateString()}  {date.toLocaleTimeString()}
          </span>
        )}
      </div>
    </Stack>
  );
}