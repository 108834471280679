import React, { useMemo }  from "react";
import { Button, Form, InputGroup } from "react-bootstrap/esm";
import { formatBig6, formatBig18 } from '@perennial/sdk'
import { useTranslation } from "react-i18next";

import { useMediaQuery } from "react-responsive";
import { NumericFormat } from "react-number-format";
import { TokenIcon } from "./TokenIcon";

import { isNumbersOnly } from "../../utils/formUtils";


type props = {
  controlId: string;
  showBalance: boolean;
  amount: string;
  title: string;
  maxAmount: bigint;
  tokenPrice: bigint;
  maxCaption: string;
  onChange: (value: string) => void;
  onSetMaxAmount: () => void;
  titleSize?: string;
  is18Decimals?: boolean;
  showIcon?: boolean;
  tokenSymbol?: string;
  showUSDValue?: boolean;
}

export const NumericFormInput = ({
  controlId,
  showBalance,
  amount,
  title,
  tokenPrice,
  maxAmount,
  maxCaption,
  onChange,
  onSetMaxAmount,
  titleSize = "1rem",
  is18Decimals = false,
  showIcon = true,
  tokenSymbol = "USDC",
  showUSDValue = true,
}: props) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })

  const { formattedMaxAmount, formattedAmountUSD, isValueZero } = useMemo(() => {
    let formattedAmountUSD = "0";
    let isValueZero = false;
    let formattedMaxAmount = is18Decimals ? formatBig18(maxAmount, { minDecimals: 6 }) : formatBig6(maxAmount, { minDecimals: 6 });
    let formattedTokenPrice = is18Decimals ? formatBig18(tokenPrice, { minDecimals: 6 }) : formatBig6(tokenPrice, { minDecimals: 6 });

    if (isNumbersOnly(amount)) {
      isValueZero = parseFloat(amount) === 0;
      formattedAmountUSD = (parseFloat(amount) * parseFloat(formattedTokenPrice)).toFixed(4);
    }
    return {
      formattedMaxAmount,
      formattedAmountUSD,
      isValueZero,
    };
  },
    [amount, is18Decimals, maxAmount, tokenPrice]
  );

  return (
    <Form.Group className="asset-input-group">
      <div className="asset-input-top">
        <h6>{title}</h6>
        {showUSDValue && (
          <NumericFormat
            className="balance-usd"
            value={formattedAmountUSD}
            displayType="text"
            thousandSeparator=","
            decimalScale={4}
            suffix=" USD"
          />
        )}        
      </div>
      <InputGroup className="input-collateral">
        <Form.Control
          id={controlId}
          placeholder="0"
          className="balance-input"
          value={!isValueZero ? amount : ""}
          type={isMobile ? "number" : "text"}
          onChange={
            (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)
          }
        />
        <div className="asset-info">
          <div className="asset-info2">
            {showIcon && (
              <TokenIcon name={tokenSymbol} size="small" />
            )}
            <span className="ms-1">{tokenSymbol}</span>
          </div>
          {showBalance && (
            <div className="asset-balance">
              <span className="title" style={{ fontSize: titleSize }} >{maxCaption}:</span>
              <span className="balance">{formattedMaxAmount}</span>
              <Button
                variant="secondary"
                className="small"
                onClick={() => onSetMaxAmount()}
              >
                {t("max")}
              </Button>
            </div>
          )}          
        </div>
      </InputGroup>
    </Form.Group>
  );       
};
