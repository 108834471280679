import React, { useState } from "react";
import { IGeolocationContext } from "../states";


export const useGeolocationContext = (): IGeolocationContext => {
  const [countryCode, setCountryCode] = useState("US");

  const setCurrentCountryCode = React.useCallback((currentCode: string): void => {
    setCountryCode(currentCode);
  }, []);  
    
  const isRestricted = (): boolean => {
    return countryCode === "US";
  }
  
  return {
    countryCode,
    setCurrentCountryCode,
    isRestricted,
  };
};
