import { useQuery } from '@tanstack/react-query'
import { getAddress, zeroAddress } from 'viem'
import { useAddress, useChainId } from '../network'
import { useCtx, useDelegatorFactory } from '../contracts'
import { DelegatorFactoryAddress } from '../../constants/governance'


export const useCtxBalances = () => { 
  const chainId = useChainId()
  const address = useAddress()
  const ctxToken = useCtx()

  return useQuery({
    queryKey: ['ctxBalances', chainId, address],
    enabled: !!address,
    queryFn: async () => {
      if (!address.address) return { ctxBalance: 0n, ctxAllowance: 0n}

      const userAccount = address.address
      const [ctxBalance, ctxAllowance] = await Promise.all([
        ctxToken.read.balanceOf([userAccount]),
        ctxToken.read.allowance([userAccount, getAddress(DelegatorFactoryAddress)]),
      ]);

      return { ctxBalance, ctxAllowance }
    },
  })

}

export const useStakerStats = () => {
  const chainId = useChainId()
  const address = useAddress()
  const delegatorFactory = useDelegatorFactory()

  return useQuery({
    queryKey: ['stakerStats', chainId, address],
    enabled: !!address,
    queryFn: async () => {
      const userAccount = address.address ?? zeroAddress

      const [totalSupply, currentStake, currentReward, currentWaitTime, currentPeriodEnds] =
        await Promise.all([
          delegatorFactory.read.totalSupply(),
          delegatorFactory.read.balanceOf([userAccount]),
          delegatorFactory.read.earned([userAccount]),
          delegatorFactory.read.waitTime(),
          delegatorFactory.read.periodFinish(),
        ]);

      return {
        totalSupply,
        currentStake,
        currentReward,
        currentWaitTime: parseInt(currentWaitTime.toString()),
        currentPeriodEnds: new Date(Number(currentPeriodEnds) * 1000),
      }
    },
  })
}

export const useStakerWaitTime = (keeperAddress: string) => {
  const address = useAddress()
  const delegatorFactory = useDelegatorFactory()

  return useQuery({
    queryKey: ['stakerWaitTime', address, keeperAddress],
    enabled: !!address,
    queryFn: async () => {
      if (address.address === undefined) return 0;

      const waitTime = await delegatorFactory.read.stakerWaitTime([address.address, getAddress(keeperAddress)])

      return parseInt(waitTime.toString())
    },
  })
}

