import React, { useMemo } from "react"
import { getAddress, zeroAddress } from "viem"
import { Button, Modal, Spinner } from "react-bootstrap/esm"
import { NumericFormat } from "react-number-format"
import {
  Big6Math,
  formatBig6,
  formatBig6Percent,
  formatBig6USDPrice,
  calcInterfaceFee,
  calcTradeFee,
  PositionSide,
  PositionStatus,
  SupportedAsset,
} from "@perennial/sdk";
import "../../../styles/modals.scss"

import { ModifyPositionButton } from "../TradeForm/components/ActionButtons"
import { usePerpetualsChainId } from "../../../hooks/network"
import { useLivePriceContext, useMarketContext } from "../../../contexts"
import { useCloseAmountValidator } from "../validatorHooks"

import { usePositionPnl } from "../../../hooks/markets2/metrics"
import { TradingFees } from "../TradeForm/components/TradingFees"
import { useBalances } from "../../../hooks/wallet"
import { needsApproval } from "../utils"
import { ApproveButton } from "../../common/TxButtons"
import { useTranslation } from "react-i18next"
import { interfaceFeeBps } from "../../../constants/network";
import { useSettlementFees } from "../../../hooks/markets2";
import { MarketMetadata } from "../../../constants/markets";


type props = {
  showModal: boolean
  isMaker: boolean
  onHide: () => void;
};

export const ClosePosition = ({ showModal, isMaker, onHide }: props) => {
  const { t } = useTranslation()
  const chainId = usePerpetualsChainId()
  const livePrices = useLivePriceContext()
  const { data: settlementFees } = useSettlementFees()
  const { data: balances, refetch: refetchBalances } = useBalances()
  const { snapshots2, selectedMarket, selectedMakerMarket } = useMarketContext()
  const currentMarket = !isMaker ? selectedMarket : selectedMakerMarket
  const market = snapshots2?.market?.[currentMarket]
  const marketMetadata = MarketMetadata[currentMarket as SupportedAsset]
  const userPosition = snapshots2 && snapshots2.user && snapshots2.user[currentMarket as SupportedAsset]
  const marketPnl = usePositionPnl(userPosition, market, livePrices)
    
  const { usdcAllowance } = useMemo(() => {
    return {
      usdcAllowance: balances?.usdcAllowance || 0n
    }
  }, [balances])

  const { marketAddress, settlementFee } = useMemo(() => {
    let marketAddress = getAddress(zeroAddress)
    let settlementFee = 0n

    if (market) {
      marketAddress = market.marketAddress
    }
    if (settlementFees) {
      settlementFee = settlementFees[currentMarket].totalCost
    }

    return {
      marketAddress,
      settlementFee,
    }
  }, [market, settlementFees, currentMarket])

  const { orderSide, formattedCollateral, currentCollateral, currentPosition, positionStatus } = useMemo(() => {
    let formattedCollateral = "0"
    let currentCollateral = 0n
    let currentPosition = 0n
    let currentLeverage = "0"
    let orderSide = PositionSide.long
    let positionStatus = userPosition?.status ?? PositionStatus.open

    if (userPosition) {
      formattedCollateral = Big6Math.toFloatString(userPosition.local.collateral)
      currentCollateral = userPosition.local.collateral
      currentPosition = userPosition.nextMagnitude
      currentLeverage = parseFloat(Big6Math.toFloatString(userPosition.nextLeverage)).toFixed(1)

      if (userPosition.nextSide !== PositionSide.none) {
        orderSide = userPosition.nextSide 
      }
    }

    return {
      orderSide,
      formattedCollateral,
      currentCollateral,
      currentPosition,
      currentLeverage,
      positionStatus
    }
  }, [userPosition])

  const { initialCollateral, pnl } = useMemo(() => {
    let pnl = "0"
    let initialCollateral = "0"
    if (marketPnl) {
      initialCollateral = formatBig6USDPrice(marketPnl.data.startCollateral)
      pnl = formatBig6(marketPnl.livePnl)
    }

    return {
      initialCollateral,
      pnl
    }
  },
    // eslint-disable-next-line
    [marketPnl?.livePnl, marketPnl?.data?.startCollateral]
  )

  const interfaceFee = calcInterfaceFee({
    chainId,
    positionStatus: positionStatus,
    latestPrice: market?.global.latestPrice ?? 0n,
    positionDelta: -1n * currentPosition,
    side: orderSide,
    referrerInterfaceFeeDiscount: 0n,
    referrerInterfaceFeeShare: 0n,
    interfaceFeeBps: !marketMetadata.isCryptexIndex ? interfaceFeeBps[chainId].feeAmount[orderSide] : 0n,
  })

  const { tradeFee, tradeImpact } = market ? calcTradeFee({
    positionDelta: -1n * currentPosition,
    side: orderSide,
    marketSnapshot: market,
  }) : {
    tradeFee: {
      total: 0n,
      pct: 0n,
    },
    tradeImpact: {
      total: 0n,
      pct: 0n,
    },
  }

  const amountValidator = useCloseAmountValidator({
    currentPositionAmount: currentPosition,
    isMaker,
    liquidity: market ? market.nextPosition.maker + market.nextMinor : 0n,
    maker: market ? market.nextPosition.maker : 0n,
    major: market ? market.nextMajor : 0n,
    marketClosed: market?.parameter.closed ?? false,
    efficiencyLimit: market ? market.riskParameter.efficiencyLimit :0n,
  })

  const { isAmountValid, positionError } = useMemo(() => {
    let isAmountValid = true;
    let positionError = "";

    const maxValidation = amountValidator.max(Big6Math.toFloatString(currentPosition));
    if (!maxValidation.isValid) {
      isAmountValid = false;
      positionError = maxValidation?.error || "";
    }
    
    return {
      isAmountValid,
      positionError,
    }
  }, [currentPosition, amountValidator])

  const pnlClassName = (pnlValue: number) => {
    if (Math.abs(pnlValue) < 0.0001) {
      return "number";
    }
    return pnlValue > 0 ? "number green" : "number red";
  }

  const approvalInfo = needsApproval({
    collateralDifference: currentCollateral,
    usdcAllowance,
    interfaceFee: interfaceFee.interfaceFee,
  })

  return (
    <Modal show={showModal} onHide={onHide} className="modal-close-position">
      <Modal.Header closeButton>
        <h5 className="bold">{t("confirm-close-position")}</h5>
      </Modal.Header>
      <Modal.Body>
        <p>
          {positionStatus === PositionStatus.closed ? (
            t("info-msg.collateral-available-withdraw")
          ) : (
            t("change-warning")
          )}
        </p>
        <div className="close-info">
          <div className="summary-item">
            <h6 className="title">{t("initial-collateral")}:</h6>
            {positionStatus !== PositionStatus.closing ? (
              <div className="value-change">
                <NumericFormat
                  className="number"
                  value={initialCollateral}
                  displayType="text"
                  thousandSeparator=","
                  prefix="$"
                  decimalScale={4}
                />
              </div>
            ) : (
              <Spinner animation="border" variant="secondary" className="xs" />
            )}
          </div>
          <div className="summary-item">
            <h6 className="title">{t("profit")}/{t("loss")}:</h6>
            {positionStatus !== PositionStatus.closing ? (
              <NumericFormat
                className={pnlClassName(parseFloat(pnl))}
                value={pnl}
                displayType="text"
                thousandSeparator=","
                prefix="$"
                decimalScale={4}
              />
            ) : (
              <Spinner animation="border" variant="secondary" className="xs" />
            )}
          </div>
          <div className="summary-item">
            <h6 className="title">{t("current-collateral")}:</h6>
            {positionStatus !== PositionStatus.closing ? (
              <NumericFormat
                className="number"
                value={formattedCollateral}
                displayType="text"
                thousandSeparator=","
                prefix="$"
                decimalScale={4}
              />
            ) : (
              <Spinner animation="border" variant="secondary" className="xs" />
            )}  
          </div>
          <div className="summary-item">
            <h6 className="title">{t("est-price-impact")}:</h6>
            {positionStatus !== PositionStatus.closing ? (
              <NumericFormat
                className={`balance-usd bold ${tradeImpact.pct === 0n ? "text-green" : "text-red"}`}
                value={formatBig6Percent(tradeImpact.pct, { numDecimals: 4 })}
                displayType="text"
                thousandSeparator=","
                decimalScale={4}
                suffix="%"
              />
            ) : (
              <Spinner animation="border" variant="secondary" className="xs" />
            )}  
          </div>
          {positionStatus !== PositionStatus.closed && (
            <TradingFees
              isMaker={isMaker}
              hasPositionChange={true}
              totalFee={tradeFee}
              settlementFee={settlementFee}
              interfaceFee={interfaceFee.interfaceFee}
              isClosePosition={true}
            />
          )}
        </div>
        {isAmountValid && (
          <div className="error-box">
            <span className="text-error">{positionError}</span>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {positionStatus !== PositionStatus.closing && !approvalInfo.needsApproval && (
          <ModifyPositionButton
            marketAddress={marketAddress}
            label={positionStatus === PositionStatus.closed ? t("withdraw") : t("close")}
            txHistoryLabel={
              positionStatus === PositionStatus.closed ? t("withdraw-collateral") : t("close-position")
            }
            positionSide={orderSide}
            collateralDelta={positionStatus === PositionStatus.closed ? -1n * currentCollateral : 0n}
            positionAbs={0n}
            interfaceFee={interfaceFee.interfaceFee}
            disabled={!isAmountValid}
            successMessage={
              positionStatus === PositionStatus.closed
                ? t("notification.collateral-withdrawn")
                : t("notification.position-closed")
            }
            onSuccess={() => {
              if (positionStatus === PositionStatus.closed) {
                onHide()
              }
            }}
          />
        )}
        {positionStatus === PositionStatus.closing && (
          <Button className="btn-close-position bold" disabled>
            <div className="btn-spinner">
              <Spinner animation="border" variant="secondary" className="small" />
              {t("closing")}
            </div>
          </Button>
        )}
        {positionStatus !== PositionStatus.closing && approvalInfo.needsApproval && (
          <ApproveButton
            approvalType="market"
            contractAddres={marketAddress}
            currentAllowance={formatBig6(usdcAllowance)}
            amountToApprove={approvalInfo.approvalAmount + Big6Math.fromFloatString("1")}
            isInterfaceFee={true}
            onSuccess={() => {
              refetchBalances()
            }}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};
