import {
  Big6Math,
  calcMakerExposure,
  calcFundingRates,
  calcMakerStats,
  formatBig6USDPrice,
  MarketSnapshot,
  PositionSide,
  PositionStatus,
  SupportedAsset,
  UserMarketSnapshot,
  formatBig6Percent,
} from '@perennial/sdk';
import { LivePrices } from "."
import { useMarketContext } from "../../contexts"
import { useActivePositionsMarketPnls, useMarket7dData } from "./graph"
import { getFormattedPositionDetails, transformPositionDataToArray } from '../../components/Trade/utils';
import { MarketMetadata } from '../../constants/markets';


export type PnlMetricsType = ReturnType<typeof getPnlMetrics>
const getPnlMetrics = (
  userMarketSnapshot?: UserMarketSnapshot,
  marketSnapshot?: MarketSnapshot,
  livePrices?: LivePrices,
  pnlData?: any,
  failedClose?: boolean
) => {
  if (!pnlData || !userMarketSnapshot) return undefined
  const { market, side, nextSide, nextMagnitude, magnitude } = userMarketSnapshot

  if (!market) return undefined
  const assetPnlData = pnlData[market]
  if (!assetPnlData) return undefined

  const { realtime, realtimePercent, startCollateral, netDeposits, averageEntryPrice } = assetPnlData
  const realtimePercentDenominator = startCollateral + (netDeposits > 0n ? netDeposits : 0n)
  const averageEntryPriceFormatted = formatBig6USDPrice(averageEntryPrice)

  let livePnl = realtime
  let livePnlPercent = realtimePercent
  if (marketSnapshot && livePrices) {
    const {
      global: { latestPrice },
      nextPosition,
    } = marketSnapshot
    const livePrice = livePrices[market]
    const liveDelta = livePrice ? livePrice.price - latestPrice : 0n
    const magnitudeForCalc =
      failedClose || userMarketSnapshot.status === PositionStatus.closing ? magnitude : nextMagnitude

    let livePnlDelta = Big6Math.mul(magnitudeForCalc, liveDelta)
    if (side === PositionSide.maker || nextSide === PositionSide.maker) {
      const makerExposure = calcMakerExposure(
        magnitudeForCalc,
        nextPosition.maker,
        nextPosition.long,
        nextPosition.short,
      )
      // Maker positions are dampened by exposure
      livePnlDelta = Big6Math.mul(liveDelta, makerExposure)
    } else if (side === PositionSide.short || nextSide === PositionSide.short) {
      // Shorts are negative
      livePnlDelta = Big6Math.mul(liveDelta, magnitudeForCalc * -1n)
    }

    livePnl = livePnl + livePnlDelta
    livePnlPercent =
      realtimePercentDenominator > 0n ? Big6Math.abs(Big6Math.div(livePnl, realtimePercentDenominator)) : 0n
  }

  return {
    data: assetPnlData,
    pnl: realtime,
    unrealized: realtime - assetPnlData.netPnl,
    pnlPercent: realtimePercent,
    averageEntryPriceFormatted,
    averageEntryPrice,
    liquidation: assetPnlData.liquidation,
    livePnl,
    livePnlPercent,
    totalFees: assetPnlData.totalFees,
    liveUnrealized: livePnl - assetPnlData.netPnl,
  }
}

export const useActiveMarketsPnls = (livePrices?: LivePrices) => {
  const { snapshots2 } = useMarketContext()
  const { data: pnlData } = useActivePositionsMarketPnls()
  
  if (!snapshots2) return undefined
  if (!snapshots2.user) return undefined
    
  return Object.keys(snapshots2.user).reduce((acc, asset) => {
    const market = snapshots2.market[asset as SupportedAsset]
    const userMarketSnapshot = snapshots2.user ? snapshots2.user[asset as SupportedAsset] : undefined

    acc[asset as SupportedAsset] = getPnlMetrics(
      userMarketSnapshot,
      market,
      livePrices,
      pnlData
    )
    return acc
  }, {} as Record<SupportedAsset, PnlMetricsType>)

}


export const usePositionPnl = (
  userMarketSnapshot?: UserMarketSnapshot,
  marketSnapshot?: MarketSnapshot,
  livePrices?: LivePrices,
  failedClose?: boolean,
) => {
  const { data: pnlData } = useActivePositionsMarketPnls()

  if (!pnlData || !userMarketSnapshot) return undefined
  const { market, side, nextSide, nextMagnitude, magnitude } = userMarketSnapshot

  if (!market) return undefined
  const assetPnlData = pnlData[market]
  if (!assetPnlData) return undefined

  const { realtime, realtimePercent, startCollateral, netDeposits, averageEntryPrice } = assetPnlData
  const realtimePercentDenominator = startCollateral + (netDeposits > 0n ? netDeposits : 0n)
  const averageEntryPriceFormatted = formatBig6USDPrice(averageEntryPrice)

  let livePnl = realtime
  let livePnlPercent = realtimePercent
  if (marketSnapshot && livePrices) {
    const {
      global: { latestPrice },
      nextPosition,
    } = marketSnapshot
    const livePrice = livePrices[market]
    const liveDelta = livePrice ? livePrice.price - latestPrice : 0n
    const magnitudeForCalc =
      failedClose || userMarketSnapshot.status === PositionStatus.closing ? magnitude : nextMagnitude

    let livePnlDelta = Big6Math.mul(magnitudeForCalc, liveDelta)
    if (side === PositionSide.maker || nextSide === PositionSide.maker) {
      const makerExposure = calcMakerExposure(
        magnitudeForCalc,
        nextPosition.maker,
        nextPosition.long,
        nextPosition.short,
      )
      // Maker positions are dampened by exposure
      livePnlDelta = Big6Math.mul(liveDelta, makerExposure)
    } else if (side === PositionSide.short || nextSide === PositionSide.short) {
      // Shorts are negative
      livePnlDelta = Big6Math.mul(liveDelta, magnitudeForCalc * -1n)
    }

    livePnl = livePnl + livePnlDelta
    livePnlPercent =
      realtimePercentDenominator > 0n ? Big6Math.abs(Big6Math.div(livePnl, realtimePercentDenominator)) : 0n
  }

  return {
    data: assetPnlData,
    pnl: realtime,
    unrealized: realtime - assetPnlData.netPnl,
    pnlPercent: realtimePercent,
    averageEntryPriceFormatted,
    averageEntryPrice,
    liquidation: assetPnlData.liquidation,
    livePnl,
    livePnlPercent,
    totalFees: assetPnlData.totalFees,
    liveUnrealized: livePnl - assetPnlData.netPnl,
  }
}

export const useFormatPosition = () => {
  const { marketMetadata, isMaker, userCurrentPosition, selectedMarketSnapshot2, selectedMakerMarket, orderDirection } =
    useMarketContext()
  const { data: market7dData } = useMarket7dData()
  const noValue = 'N/A'

  const numSigFigs = marketMetadata.displayDecimals
  const minorSide = selectedMarketSnapshot2?.minorSide
  const { hourlyFunding, dailyFunding, eightHourFunding, yearlyFunding } = calcFundingRates(
    selectedMarketSnapshot2?.fundingRate[
      isMaker ? (minorSide as PositionSide.long | PositionSide.short) : orderDirection
    ],
  )

  const makerStats = isMaker
    ? calcMakerStats({
        funding: market7dData?.[selectedMakerMarket]?.makerAccumulation.funding ?? 0n,
        interest: market7dData?.[selectedMakerMarket]?.makerAccumulation.interest ?? 0n,
        positionFee: market7dData?.[selectedMakerMarket]?.makerAccumulation.positionFee ?? 0n,
        positionSize: userCurrentPosition?.nextPosition?.maker ?? 0n,
        collateral: userCurrentPosition?.local?.collateral ?? 0n,
      })
    : undefined

  const direction =
    userCurrentPosition?.status === PositionStatus.closing ? userCurrentPosition?.side : userCurrentPosition?.nextSide

  return {
    positionDetails: userCurrentPosition,
    formattedValues: {
      direction,
      isFundingNegative: hourlyFunding < 0n,
      dailyFunding: userCurrentPosition ? formatBig6Percent(Big6Math.abs(dailyFunding), { numDecimals: 4 }) : noValue,
      hourlyFunding: userCurrentPosition ? formatBig6Percent(Big6Math.abs(hourlyFunding), { numDecimals: 4 }) : noValue,
      eightHourFunding: userCurrentPosition
        ? formatBig6Percent(Big6Math.abs(eightHourFunding), { numDecimals: 4 })
        : noValue,
      yearlyFundingRate: userCurrentPosition
        ? formatBig6Percent(Big6Math.abs(yearlyFunding), { numDecimals: 2 })
        : noValue,
      fundingFeeAPR: !!makerStats ? formatBig6Percent(makerStats.fundingAPR, { numDecimals: 4 }) : noValue,
      tradingFeeAPR: !!makerStats ? formatBig6Percent(makerStats.positionFeeAPR, { numDecimals: 4 }) : noValue,
      isOpening: userCurrentPosition?.status === PositionStatus.opening,
      totalAPR: !!makerStats
        ? formatBig6Percent(makerStats.fundingAPR + makerStats.interestAPR + makerStats.positionFeeAPR, {
            numDecimals: 4,
          })
        : noValue,
      ...getFormattedPositionDetails({
          marketSnapshot: selectedMarketSnapshot2,
          userMarketSnapshot: userCurrentPosition,
          placeholderString: noValue,
          numSigFigs,
        }),
    },
  }
}

export const useOpenPositionsData = () => {
  const { isMaker, snapshots2 } = useMarketContext()
  const transformedPositions = transformPositionDataToArray(snapshots2?.user, isMaker)
  
  const noValue = 'N/A'
  const positions = transformedPositions
    .map((position) => {
      const numSigFigs = MarketMetadata[position.asset]?.displayDecimals ?? 2
      
      return {
        details: position.details as UserMarketSnapshot,
        asset: position.asset,
        symbol: position.symbol,
        isClosed: position.details.status === PositionStatus.closed,
        isClosing: position.details.status === PositionStatus.closing,
        triggerPrice: noValue,
        projectedFees: noValue,
        pendingSTIPAPR: 0n,
        stipAPREstimatedOI: 0n,
        ...getFormattedPositionDetails({
          marketSnapshot: snapshots2?.market[position.asset],
          userMarketSnapshot: position.details,
          placeholderString: noValue,
          numSigFigs,
        }),
      }
    })
    .sort((a, b) => Big6Math.cmp(b.details?.nextMagnitude ?? 0n, a.details?.nextMagnitude ?? 0n))

  return {
    positions,
  }
}