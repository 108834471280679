import React, { useContext, useState } from "react"
import { Accordion, AccordionContext, Col, Image, Row, Spinner, Stack, Table, useAccordionButton } from "react-bootstrap"
import {
  Big6Math,
  BigOrZero,
  calcNotional,
  ExplorerURLs,
  formatBig6,
  formatBig6USDPrice,
  formatDateRelative,
  SubPositionChange,
  SupportedAsset,
  notEmpty,
} from "@perennial/sdk"
import { useTranslation } from "react-i18next"

import { HistoricalPosition, useHistoricalSubPositions } from "../../../../hooks/markets2/graph"
import { MarketMetadata } from "../../../../constants/markets"
import { capitalize } from "../../../../utils/utils"
import { usePerpetualsChainId } from "../../../../hooks/network"
import { NumericFormat } from "react-number-format"


export const PositionsList = ({
  positions,
  onClick
}: {
  positions: HistoricalPosition[]
  onClick?: (row: HistoricalPosition) => void
}) => { 
  const { t } = useTranslation()

  return (
    <>
      <Row className="positions-history-header">
        <Col lg={2}>
          <h6>{t("opened")}</h6>
        </Col>
        <Col lg={1}>
          <h6>{t("market")}</h6>
        </Col>
        <Col lg={2} className="text-right">
          <h6>{t("initial-collateral")}</h6>
        </Col>
        <Col lg={2} className="text-right">
          <h6>{t("initial-position")}</h6>
        </Col>
        <Col lg={2} className="text-right">
          <h6>{t("pnl")}</h6>
        </Col>
        <Col lg={2} className="text-right">
          <h6>{t("avg-entry-price")}</h6>
        </Col>
        <Col lg={1} className="text-right">
          <h6>{t("fees")}</h6>
        </Col>
      </Row>
      <Accordion>
        {positions.map((position, index) => (
          <Accordion.Item key={index.toString()} eventKey={index.toString()}>
            <PositionRow key={index.toString()} rowIndex={index.toString()} position={position} onClick={onClick} /> 
          </Accordion.Item>  
        ))}
      </Accordion>
    </> 
  )  
}


function PositionToggle({
  children,
  eventKey,
  callback
}: {
  children: React.ReactElement,
  eventKey: string,
  callback?: (eventKey: string) => void
}) {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;
  const opacity = activeEventKey ? (isCurrentEventKey ? "1" : "0.1") : "1"

  return (
    <button
      type="button"
      className={"accordion-button ".concat(!isCurrentEventKey ? "collapsed" : "")}
      style={{
        backgroundColor: isCurrentEventKey ? "#141018" : "#0a0a0a",
        width: "100%",
        border: "none",
        opacity: opacity,        
      }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

const PositionRow = ({
  rowIndex,
  position,
  onClick
}: {
  rowIndex: string,
  position: HistoricalPosition,
  onClick?: (row: HistoricalPosition) => void
}) => { 
  const [enabledSubPositions, setEnabledSubPositions] = useState(false) 
  const market = MarketMetadata[position.market as SupportedAsset]
  const fees = position.totalFees
  // Taker position fees are factored into avg entry price
  // const displayedFees = position.side === 'maker' ? fees : fees - position.priceImpactFees
  const pnl = position.netPnl
  const isPnlPositive = pnl > 0n
  const openDate = formatDateRelative(new Date(Number(position.startVersion) * 1000)) //  position.startTime

  return (
    <>
      <div key={`pr-history-${rowIndex}`} className="accordion-header">
        <PositionToggle
          eventKey={rowIndex}
          callback={(eventKey) => {
            setEnabledSubPositions(rowIndex === eventKey)
          }}
        >
          <Row className="position-row">
            <Col lg={2}>
              <h6>
                {openDate}
              </h6>
            </Col>
            <Col lg={1}>
              <Stack direction="horizontal" gap={2}>
                <Image src={market.icon} width={30} height={30} />
                <Stack gap={0.5}>
                  <h6>{capitalize(position.side)}</h6>
                  <span className="number small gray">
                    {market.name}
                  </span>
                </Stack>  
              </Stack>
            </Col>
            <Col lg={2} className="text-right">
              <h6>
                {formatBig6USDPrice(position.startCollateral)}
              </h6>
            </Col>
            <Col lg={2} className="text-right">
              <Stack gap={0.5}>
                <h6>
                  {formatBig6(position.startSize)} {position.market.toUpperCase()}
                </h6>
                <span className="number small gray">
                  {formatBig6USDPrice(Big6Math.mul(position.startSize, position.startPrice))}
                </span>  
              </Stack>            
            </Col>
            <Col lg={2} className="text-right">
              <span className={"number ".concat(isPnlPositive ? "text-green" : "text-red")}>
                {formatBig6USDPrice(pnl)}
              </span>
            </Col>
            <Col lg={2} className="text-right">
              <span className="number">
                {formatBig6USDPrice(position.averageEntryPrice)}
              </span>
            </Col>
            <Col lg={1} className="text-right">
              <span className="number">
                {formatBig6USDPrice(fees)}
              </span>  
            </Col>
          </Row>
        </PositionToggle>
      </div>
      <Accordion.Body>
        <PositionActionsHistory position={position} enabledSubPositions={enabledSubPositions} />
      </Accordion.Body>
    </>
  )
}


const PositionActionsHistory = ({ position, enabledSubPositions } : { position: HistoricalPosition, enabledSubPositions: boolean }) => {
  const { t } = useTranslation()
  const { market, liquidation, liquidationFee } = position

  const { data: history } = useHistoricalSubPositions({
    market,
    positionId: position.positionId,
    enabled: enabledSubPositions,
  })

  if (!history) return null

  const changes = history.pages
    .map((p: any) => p?.changes)
    .flat()
    .filter(notEmpty)
    .reverse()
  
  if (changes.length === 0) {
    <div className="spinner-container">
      <Spinner animation="border" variant="primary" className="small" />
    </div>
  }
  
  return (
    <Table className="sub-positions">
      <thead>
        <tr>
          <th>{t("date")}</th>
          <th className="text-right">{t("size")}</th>
          <th className="text-right">{t("change")}</th>
          <th className="text-right">{t("collateral")}/{t("change")}</th>
          <th className="text-right">{t("exec-price")}</th>
          <th className="text-right">{t("fees")}</th>
          <th className="text-right">{t("pnl")}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {changes.length > 0 && changes.map((change, index) => (
          <SubPositionRow
            key={`spr-h-${index}`}
            asset={market}
            change={change}
            liquidation={index === 0 && !!liquidation}
            liquidationFee={index === 0 ? liquidationFee : 0n}
            changeIndex={index}
          />
        ))}  
      </tbody>
    </Table>
  )
}

const SubPositionRow = ({
  asset,
  change,
  liquidation,
  liquidationFee,
  changeIndex,
}: {
  asset: SupportedAsset
  change: SubPositionChange
  liquidation: boolean
  liquidationFee: bigint
  changeIndex: number
}) => {
  const { t } = useTranslation()
  const chainId = usePerpetualsChainId()
  const { magnitude, delta, valid: valid_, netDeposits } = change

  const collateralOnly = delta === 0n && netDeposits !== 0n
  const settlementOnly = delta === 0n && netDeposits === 0n
  const settled = valid_ || collateralOnly || settlementOnly
  const price = change.executionPriceWithOffset
  
  const fees = change.totalFees
  const pnl = change.netPnl
  const collateralChange = BigOrZero(netDeposits)
  const startCollateral = change.startCollateral + collateralChange

  const valid = valid_ || ((delta ?? 0n) === 0n && collateralChange !== 0n)
  
  const changeColorClass = (value: bigint): string => {
    return value > 0n ? "text-green" : (value < 0n ? "text-red" : "text-muted")
  }

  return (
    <tr key={`spr-history-${changeIndex}`}>
      <td>
        <span className="number">
          {formatDateRelative(new Date(Number(change.version) * 1000))}
        </span>
      </td>
      <td className="text-right">
        <Stack direction="vertical">
          <NumericFormat
            className={"number"}
            value={formatBig6(magnitude)}
            displayType="text"
            thousandSeparator=","
            suffix={" ".concat(asset.toUpperCase())}
            decimalScale={4}
          />
          <NumericFormat
            className={"number small text-muted"}
            value={formatBig6USDPrice(calcNotional(magnitude, price))}
            displayType="text"
            thousandSeparator=","
            prefix="$"
            decimalScale={4}
          />
        </Stack>
      </td>
      <td className="text-right">
        {delta !== null ? (
          <Stack direction="vertical">
            <NumericFormat
              className={"number ".concat(changeColorClass(delta))}
              value={formatBig6(Big6Math.abs(delta))}
              displayType="text"
              thousandSeparator=","
              prefix={delta >= 0n ? "+" : "-"}
              suffix={" ".concat(asset.toUpperCase())}
              decimalScale={4}
            />
            {liquidation && <span className="small text-muted">{t("liquidated")}</span> }
          </Stack>  
        ): (
          <span className="number">-</span>  
        )}  
      </td>
      <td className="text-right">
        <Stack direction="vertical">
          <span className="number">{settled ? formatBig6USDPrice(startCollateral) : "-"}</span>
          <NumericFormat
            className={"number small ".concat(changeColorClass(collateralChange))}
            value={formatBig6USDPrice(Big6Math.abs(collateralChange))}
            displayType="text"
            thousandSeparator=","
            prefix={collateralChange > 0n ? "+$" : (collateralChange < 0n ? "-$" : "$")}
            decimalScale={4}
          />
        </Stack>
      </td>
      <td className="text-right">
        {valid ? (
          <span className="number">{formatBig6USDPrice(price)}</span>
        ) : !valid && changeIndex > 0 ? (
            <span className="number">{t("failed")}</span>
        ) : (
          <span className="number">-</span>
        )}
      </td>
      <td className="text-right">
        {!!liquidation ? (
          <span className="number">
            {formatBig6USDPrice(fees)}
          </span>
        ) : (
          <span className="number">
            {settled ? formatBig6USDPrice(fees) : "-"}
          </span>
        )}
      </td>
      <td className="text-right">
        {settled ? (
          <span className={"number ".concat(pnl >= 0n ? "text-green" : "text-red")}>
            {formatBig6USDPrice(pnl)}
          </span>
        ) : (
            <span className="number">{t("unsettled")}</span>
        )}
      </td>
      <td className="text-right">
        <a
          href={`${ExplorerURLs[chainId]}/tx/${change.transactionHashes[0]}`}
          target="_blank"
          rel="noreferrer"
        >
          {t("transaction")}
        </a>
      </td>
    </tr>
  )
}
