import React, { useContext } from "react"
import { useTranslation } from "react-i18next"

import { TOKENS_SYMBOLS, WIKI_TCAP_URL } from "../../utils/constants"
import { productContext } from "../../states"
import { useActiveProvider } from "../../hooks"
import { getMarketByProduct } from "../../utils/utils"


export const PriceUpdateWarning = ({ productAddress }: { productAddress?: string }) => {
  const { t } = useTranslation()
  const { chainId } = useActiveProvider();
  const product = useContext(productContext);
  let currentMarket = product.market;
  if (productAddress) {
    currentMarket = getMarketByProduct(chainId, productAddress);
  }

  return (
   <div className="warning-price-update">
      <p>
        {currentMarket.key === TOKENS_SYMBOLS.tcap && (
          <>
            {t("tooltip.tcap-price")}{" "}
            <a href={WIKI_TCAP_URL} target="_blank" rel="noreferrer" className="ref-">
              wiki
            </a>.
            {t("info-msg.transaction-settle")}
          </>
        )}
      </p>
    </div>    
  );
};
