import { useQuery } from '@tanstack/react-query'
import { usePushContext } from '../../contexts';
import { CONSTANTS } from "@pushprotocol/restapi";
import { useEffect } from 'react';
import { notifyUser } from '../common';


export const useInitStream = () => { 
  const pushAPI = usePushContext();

  return useQuery({
    queryKey: ['pushStream'],  
    enabled: !!pushAPI && !!pushAPI.channelApi && !!pushAPI.subscriberApi && !!pushAPI.channelInfo,
    queryFn: async () => { 
      if (!pushAPI.channelApi || !pushAPI.subscriberApi || !pushAPI.channelInfo) return;

      const stream = await pushAPI.subscriberApi.initStream([CONSTANTS.STREAM.NOTIF], {
        filter: {
          channels: [pushAPI.channelInfo.channel], // pass in specific channels to only listen to those
        },
        connection: {
          retries: 3, // number of retries in case of error
        },
      });

      return stream;
    }
  })
}

export const usePushStream = () => { 
  const { data: stream } = useInitStream();

  useEffect(() => {
    if (!stream || stream.connected()) return;

    // console.log("Starting to watch stream");
    stream.on(CONSTANTS.STREAM.NOTIF, (data: any) => {
      let title = data.message.notification.title;
      const splitTitle = data.message.notification.title.split("-");

      if (splitTitle.length > 1) {
        title = splitTitle[1];
      }
      notifyUser(data.message.notification.body, title);
    });

    stream.connect();
  }, [stream]);
}