export enum SupportedLanguage {
  en = 'en',
  es = 'es',
  pt = 'pt',
  tr = 'tr'
}

export type LanguageMetadataType = {
  [lang in SupportedLanguage]: {
    country: string;
    name: string;
  };
};


export const LanguageMetadata: LanguageMetadataType = {
  [SupportedLanguage.en]: {
    country: 'US',
    name: 'English',
  },
  [SupportedLanguage.es]: {
    country: 'ES',
    name: 'Español'
  },
  [SupportedLanguage.pt]: {
    country: 'BR',
    name: 'Português'
  },
  [SupportedLanguage.tr]: {
    country: 'TR',
    name: 'Türkçe'
  }
}
