import React from "react"
import ReactGA from "react-ga4"
import TradeWrapper from "../../components/Trade/"


const TradePage = () => {
  ReactGA.send({ hitType: "pageview", page: "/perpetuals", title: "Perpetuals Trade" });

  return (
    <TradeWrapper isProInterface={false} />
  )
};

export default TradePage;
