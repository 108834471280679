import React, { useCallback, useContext, useMemo, useState } from "react"
import { Button, Modal, Spinner } from "react-bootstrap/esm"
import { NumericFormat } from "react-number-format"
import { ethers } from "ethers"
import { useTranslation } from "react-i18next"

import "../../../styles/modals.scss"
import { positionStatsContext } from "../../states"
import {
  getUserProductPosition,
  useUserPositionChanges,
  useProductSnapshot,
  useUserProductSnapshot,
} from "../../hooks"
import { useActiveProvider, useWithdrawOrClosePosition } from "../../hooks"
import { ERROR_USER_REJECTED_TRAN } from "../../utils/constants"
import { calculateLatestPositionPnl, getMarketByProduct, isLongPosition, userPositionSize } from "../../utils/utils"
import { errorNotification, notifyUser } from "../../../components/common"
import { PriceUpdateWarning } from "../common";


type props = {
  showModal: boolean;
  ownerAddress: string;
  productAddress: string;
  isTaker: boolean;
  onHide: () => void;
};

export const ClosePosition = ({ showModal, ownerAddress, productAddress, isTaker, onHide }: props) => {
  const { t } = useTranslation()
  const { chainId } = useActiveProvider();
  const { product } = useProductSnapshot(productAddress);
  const positionStats = useContext(positionStatsContext);
  const { userProduct, mutateUserProductSnapshot } = useUserProductSnapshot(ownerAddress, productAddress);
  const { data: userPositionChanges } = useUserPositionChanges(isTaker, productAddress);
  const [ writingTransaction, setWritingTransaction] = useState(false);
  const pendingPositionSize = userPositionSize(userProduct?.position)

  const getPositionSize = useCallback(() => {
    let amount = 0n; 
    if (userProduct) {
      const position = getUserProductPosition([userProduct.pre, userProduct.position]);
      amount = BigInt(position.amount);
    }
    
    return amount;
  },
    [userProduct]
  );

  const getCollateral = useCallback(() => {
    if (userProduct) {
      return userProduct.collateral; 
    }
    return 0n;
  },
    [userProduct]
  );

  const { isPositionClosing, isWithdrawal } = useMemo(() => {
    const isPositionClosing = pendingPositionSize !== 0n && getPositionSize() === 0n;
    const isWithdrawal = getPositionSize() === 0n;

    return {isPositionClosing, isWithdrawal }
  }, [pendingPositionSize, getPositionSize])

  const canWrite = (): boolean => {
    if (userProduct) {
      const hasPosition = getPositionSize() !== 0n || 0n !== userProduct.collateral;
      return hasPosition && ownerAddress !== "" && productAddress !== "" && !isPositionClosing;
    }
  
    return false;
  };

  const onTransactionSettled = (isSuccess: boolean, error: any, tranHash: string) => {
    setWritingTransaction(false);
    if (isSuccess) {
      let msg = t("notification.position-closed-2")
      if (isWithdrawal) {
        msg = t("notification.collateral-withdrawn")
      }

      notifyUser(msg);
      if (isWithdrawal) {
        onHide();
      }
      
      // refetch user product snapshots
      mutateUserProductSnapshot();
    } else {
      if (error.name !== ERROR_USER_REJECTED_TRAN) {
        errorNotification(t("error.transaction"));
      }
    }
  };

  const { onClose, onWithdraw } = useWithdrawOrClosePosition(chainId, ownerAddress, isTaker, onTransactionSettled);

  const { initialCollateral, positionPnL } = useMemo(() => {
    let initialCollateral = 0;
    let positionPnL = 0;
    if (!userProduct && !userPositionChanges && !product) {
      return { initialCollateral, positionPnL };
    }
    const nextCollateral = userProduct.collateral;

    const pnl = calculateLatestPositionPnl(userPositionChanges, nextCollateral);
    positionPnL = parseFloat(ethers.formatEther(pnl));

    initialCollateral = parseFloat(ethers.formatEther(nextCollateral)) - positionPnL;

    if (isWithdrawal && positionPnL > 0 && product) {
      const currentMarket = getMarketByProduct(chainId, productAddress);
      positionStats.setCurrentMarket(currentMarket);
      positionStats.setCurrentType(isLongPosition(chainId, productAddress) ? t("long").toUpperCase() : t("short").toUpperCase())
      positionStats.setCurrentPnl(positionPnL);
      positionStats.setCurrentCollateral(parseFloat(ethers.formatEther(userProduct.collateral)));
    }

    return { initialCollateral, positionPnL };
  },
    // eslint-disable-next-line
    [userPositionChanges, userProduct]
  );

  const onButtonClick = async () => {
    if (!userProduct.isLiquidating && canWrite()) {
      setWritingTransaction(true);
      if (isWithdrawal) {
        onWithdraw(productAddress, getPositionSize(), getCollateral())
      } else {
        onClose(productAddress, getPositionSize(), getCollateral())
      }
    }
  };

  const btnText = () => {
    if (!isPositionClosing && getPositionSize() === 0n) {
      return writingTransaction ? t("withdrawing") : t("withdraw")
    } else {
      return writingTransaction || isPositionClosing ? t("closing") : t("close-position")
    }
  };

  return (
    <Modal show={showModal} onHide={onHide} className="modal-close-position">
      <Modal.Header closeButton>
        <h5 className="bold">{t("confirm-close-position")}</h5>
      </Modal.Header>
      <Modal.Body>
        <p>
          {isWithdrawal ? (
            t("info-msg.collateral-available-withdraw")
          ) : (
            t("info-msg.change-warning")
          )}
        </p>
        <div className="close-info">
          <div className="summary-item">
            <h6 className="title">{t("initial-collateral")}:</h6>
            <div className="value-change">
              <NumericFormat
                className="number"
                value={initialCollateral.toFixed(4)}
                displayType="text"
                thousandSeparator=","
                prefix="$"
                decimalScale={4}
              />
            </div> 
          </div>
          <div className="summary-item">
            <h6 className="title">{t("profit")}/{t("loss")}:</h6>
            <span className="number">
              <NumericFormat
                className={"number ".concat(positionPnL < 0 ? "red" : "green")}
                value={positionPnL}
                displayType="text"
                thousandSeparator=","
                prefix="$"
                decimalScale={4}
              />
            </span>
          </div>
          <div className="summary-item">
            <h6 className="title">{t("current-collateral")}:</h6>
            <span className="number">
              <NumericFormat
                className="number"
                value={ethers.formatEther(getCollateral())}
                displayType="text"
                thousandSeparator=","
                prefix="$"
                decimalScale={4}
              />
            </span>
          </div>
        </div>
        <PriceUpdateWarning productAddress={productAddress} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-close-position bold"
          onClick={() => onButtonClick()}
          disabled={writingTransaction || isPositionClosing}
        >
          <div className="btn-spinner">
            {(writingTransaction || isPositionClosing) && <Spinner animation="border" variant="secondary" className="small" />}
            {btnText()}  
          </div>  
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
