import React from "react"
import { Stack } from "react-bootstrap/esm"


export const IndexInfo = () => {
  return (
    <div className="market-info-wrapper">
      <Stack
        direction={"vertical"}
        className="market-info-container py-3 px-2"
        gap={3}
      >
        <p>
          <span className="text-purple">MEEM Components:</span> DOGE, SHIBA, PEPE, FLOKI, ETH
        </p>
        <p>
          <span className="text-purple">Weighting:</span> 20%, 20%, 20%, 20%, 20%
        </p>
        <Stack direction={"vertical"} gap={1}>
          <p className="text-purple">
            Index formula:
          </p>
          <p className="small">MEEM Index Price =</p>
          <p className="small ps-4">
            <p>0.2 * (Current DOGE Price / DOGE Mark Price) + </p>
            <p>0.2 * (Current SHIB Price / SHIB Mark Price) +</p>
            <p>0.2 * (Current PEPE Price / PEPE Mark Price) +</p>
            <p>0.2 * (Current FLOKI Price / FLOKI Mark Price) +</p>
            <p>0.2 * (Current ETH Price / ETH Mark Price)</p>
          </p>
        </Stack>  
        <Stack direction={"vertical"} gap={1}>
          <p>
            <span className="text-purple">MEEM</span> was built in collaboration with:
          </p>
          <ul>
            <li>Chainlink Low latency price feeds</li>
            <li>Perennial/ Infrastructure</li>
            <li>Gauntlet/ Parameter calculations</li>
          </ul>
        </Stack>  
        <Stack direction={"vertical"} gap={1}>
          <p className="text-purple">
            Criteria: 
          </p>
          <ul>
            <li>Classified as Memecoin (except ETH)</li>
            <li>Ranked top 100 by market cap</li>
            <li>Sufficient liquidity on Ethereum</li>
          </ul>
        </Stack>  
      </Stack>
    </div>
  )     
}
