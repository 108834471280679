import useSWR from "swr";
import { AbiCoder, AbstractProvider, BlockTag, Contract, EventLog, Log, Typed } from "ethers";
import { VaultAllowances, VaultSnapshot, VaultUserSnapshot } from "../utils/types"
import { sumBNArray, vaultHasLPRewards } from "../utils/utils"
import {
  getLensContract,
  getMultiInvokerContract,
  getProductContract,
  getDSUContract,
  getUSDCContract,
  getVaultContract,
  getLiquidityRewardsContract,
  getLiquidityRewardsContractCtx,
  tcapVaultContract,
} from "../utils/contracts"
import { useActiveProvider } from ".";
import { arbitrum } from "viem/chains";


const vaultSnapshotFetcher =
  (provider: AbstractProvider) =>
    async ([_, chainId, vaultAddress, longAddress]: [_: string, chainId: number, vaultAddress: string, longAddress: string]):
      Promise<VaultSnapshot | undefined> =>
    {

    const vault = getVaultContract(provider, vaultAddress);
    const lens = getLensContract(chainId, provider);
    const longProduct = getProductContract(provider, longAddress);

    const [name, symbol, long, short, targetLeverage, maxCollateral, latestVersion] = await Promise.all([
      vault.name(),
      vault.symbol(),
      vault.long(),
      vault.short(),
      vault.targetLeverage(),
      vault.maxCollateral(),
      longProduct.latestVersion(Typed.address(vaultAddress)), 
    ])

    const redemptionsQuery = vault.filters.Redemption(null, null);
    const [longSnapshot, shortSnapshot, longUserSnapshot, shortUserSnapshot, canSync, totalSupply, totalAssets, redemptionsAtVersion] =
      await Promise.all([
        lens.snapshot.staticCall(Typed.address(long)),
        lens.snapshot.staticCall(Typed.address(short)),
        lens.snapshot.staticCall(Typed.address(vaultAddress), Typed.address(long)),
        lens.snapshot.staticCall(Typed.address(vaultAddress), Typed.address(short)),
        trySync(vault),
        vault.totalSupply(),
        vault.totalAssets(),
        vault.queryFilter(redemptionsQuery)
      ])
      
    const pendingRedemptions = redemptionsAtVersion.reduce(
      (acumulator: bigint, currentValue: EventLog | Log) => {
        const [version, shares] = AbiCoder.defaultAbiCoder().decode(["uint256", "uint256"], currentValue.data);
        return acumulator + (latestVersion === version ? shares : 0n);
      },
      0n);

    return {
      address: vaultAddress,
      name,
      symbol,
      long: long.toLowerCase(),
      short: short.toLowerCase(),
      totalSupply,
      totalAssets,
      pendingRedemptions,
      targetLeverage,
      maxCollateral,
      longSnapshot,
      shortSnapshot,
      longUserSnapshot,
      shortUserSnapshot,
      canSync,
    }
  }

export const useVaultSnapshot = (longAddress: string) => {
  const { chainId, multiCallProvider } = useActiveProvider();
  const vaultAddress = tcapVaultContract[arbitrum.id].address
  return useSWR<VaultSnapshot | undefined>(
    ["vaultSnapshot", chainId, vaultAddress, longAddress],
    vaultSnapshotFetcher(multiCallProvider),
    { revalidateOnFocus: false },
  )
}

export const vaultUserSnapshotFetcher =
  (provider: AbstractProvider) =>
  async ([_, chainId, vaultAddress, account]: [
    _: string,
    chainId: number,
    vaultAddress: string,
    account: string
  ]) => {
    if (!account) return

    const vault = getVaultContract(provider, vaultAddress);
    const rewardsContract = getLiquidityRewardsContract(chainId, provider);
    const rewardsContractCtx = getLiquidityRewardsContractCtx(chainId, provider); 
    
    const depositsQuery = vault.filters.Deposit(null, account)
    const claimsQuery = vault.filters.Claim(null, account)
    const redemptionsQuery = vault.filters.Redemption(null, account)

    
    const [long, short, _deposits, _claims, _redemptions] = await Promise.all([
      vault.long(),
      vault.short(),
      vault.queryFilter(depositsQuery),
      vault.queryFilter(claimsQuery),
      vault.queryFilter(redemptionsQuery)
    ])

    const longProduct = getProductContract(provider, long)
    const shortProduct = getProductContract(provider, short)

    const [, , , balance, rewardsBalance, rewardsBalanceCtx] = await Promise.all([
      longProduct.settleAccount.staticCall(Typed.address(vaultAddress)),
      shortProduct.settleAccount.staticCall(Typed.address(vaultAddress)),
      trySync(vault),
      vault.balanceOf(account), 
      rewardsContract.balanceOf(account),
      rewardsContractCtx.balanceOf(account),
    ])
    const hasCtxRewardsBalance = rewardsBalanceCtx > 10n;

    const [, , , latestVaultVersion, assets, claimable, rewardsAssets] = await Promise.all([
      longProduct.settleAccount.staticCall(Typed.address(vaultAddress)),
      shortProduct.settleAccount.staticCall(Typed.address(vaultAddress)),
      trySync(vault),
      longProduct["latestVersion(address)"](Typed.address(vaultAddress)),
      vault.convertToAssets(balance),
      vault.unclaimed(account),
      vault.convertToAssets(!hasCtxRewardsBalance ? rewardsBalance : rewardsBalanceCtx),
    ])

    // sort in descending order 
    const deposits = _deposits.sort((a: EventLog | Log, b: EventLog | Log) => b.blockNumber - a.blockNumber);
    const claims = _claims.sort((a: EventLog | Log, b: EventLog | Log) => b.blockNumber - a.blockNumber);
    const redemptions = _redemptions.sort((a: EventLog | Log, b: EventLog | Log) => b.blockNumber - a.blockNumber);
    // const redemptions = _redemptions.sort((a: EventLog | Log, b: EventLog | Log) => b.args.version.sub(a.args.version).toNumber());

    let pendingRedemptionAmount = 0n;
    if (redemptions.length > 0 && claimable === 0n) {
      const [redemptionVersion, redemptionShares] = AbiCoder.defaultAbiCoder().decode(["uint256", "uint256"], redemptions[0].data);
      if (redemptionVersion >= latestVaultVersion) { 
        pendingRedemptionAmount = redemptionShares;
      }
      // pendingRedemptionAmount = redemptions[0].args.shares;
    }

    let pendingDepositAmount = 0n;
    if (deposits.length > 0) {
      const [depositVersion, depositAssets] = AbiCoder.defaultAbiCoder().decode(["uint256", "uint256"], deposits[0].data);
      if (depositVersion >= latestVaultVersion) {
        pendingDepositAmount = depositAssets;
      }  
      // pendingDepositAmount = deposits[0].args.assets
    }

    let currentPositionStartBlock = (deposits.at(-1)?.blockNumber || 0) - 1;
    for (const claim of claims) {
      const [, balance, stakedBalanceAtClaim, stakedBalanceAtClaimCtx] = await Promise.all([
        trySync(vault, { blockTag: claim.blockNumber }),
        vault.balanceOf(account, { blockTag: claim.blockNumber }),
        rewardsContract.balanceOf(
          account,
          { blockTag: claim.blockNumber < 108187243 ? 108187243 : claim.blockNumber }
        ),
        rewardsContractCtx.balanceOf(
          account,
          { blockTag: claim.blockNumber < 98111426 ? 98111426 : claim.blockNumber }
        ),
      ])

      // If less than 100 wei, consider it a new starting block
      if (vaultHasLPRewards(chainId, vaultAddress)) {
        if (balance < 100n && stakedBalanceAtClaim < 10n && stakedBalanceAtClaimCtx < 10n) {
          currentPositionStartBlock = claim.blockNumber
          break
        }
      } else {
        if (balance < 100n) {
          currentPositionStartBlock = claim.blockNumber
          break
        }
      }
    }

    return {
      balance,
      assets,
      claimable,
      totalDeposit: sumBNArray(_deposits.map((e: any) => e.args.assets)),
      totalClaim: sumBNArray(_claims.map((e: any) => e.args.assets)),
      currentPositionDeposits: sumBNArray(
        _deposits.filter((e) => e.blockNumber > currentPositionStartBlock).map((e: any) => e.args.assets)
      ),
      currentPositionClaims: sumBNArray(
        _claims.filter((e) => e.blockNumber > currentPositionStartBlock).map((e: any) => e.args.assets)
      ),
      // Sort redemptions as most recent first
      deposits,
      claims,
      redemptions,
      pendingRedemptionAmount,
      pendingDepositAmount,
      rewardsAssets,
    }
  }

export const useVaultUserSnapshot = (vaultAddress: string) => {
  const { chainId, userAccount, multiCallProvider } = useActiveProvider();

  return useSWR<VaultUserSnapshot | undefined>(
    ["vaultUserSnapshot", chainId, vaultAddress, userAccount],
    vaultUserSnapshotFetcher(multiCallProvider),
    { revalidateOnFocus: true },
  )
}

const vaultAllowancesFetcher =
  (provider: AbstractProvider | null) =>
  async ([_, chainId, vaultAddress, account]: [
    _: string,
    chainId: number,
    vaultAddress: string,
    account: string
  ]): Promise<VaultAllowances | undefined> => {
    if (!provider || !account) {
      return
    }

    const multiInvokerContract = getMultiInvokerContract(chainId, provider);
    const rewardsContract = getLiquidityRewardsContract(chainId, provider);
    const multiInvokerAddress = await multiInvokerContract.getAddress();
    const rewardsAddress = await rewardsContract.getAddress();

    const usdcContract = getUSDCContract(chainId, provider);
    const dsuContract = getDSUContract(chainId, provider);
    const vaultContract = getVaultContract(provider, vaultAddress);
    
    const [usdc, dsu, shares, sharesToStake] = await Promise.all([
      usdcContract.allowance(account, multiInvokerAddress),
      dsuContract.allowance(account, multiInvokerAddress),
      vaultContract.allowance(account, multiInvokerAddress),
      vaultContract.allowance(account, rewardsAddress)
    ])

    return { usdc, dsuAllowance: dsu, shares, sharesToStake }
  }

export const useVaultAllowances = (vaultAddress: string) => {
  const { chainId, userAccount, multiCallProvider } = useActiveProvider();

  return useSWR<VaultAllowances | undefined>(
    !userAccount || !multiCallProvider ? null : ["vaultAllowances", chainId, vaultAddress, userAccount],
    vaultAllowancesFetcher(multiCallProvider),
    { revalidateOnFocus: false },
  )
};

export const convertAssetsToShares = async (provider: AbstractProvider, vaultAddress: string, assets: bigint) => {
  const vault = getVaultContract(provider, vaultAddress);
  
  const [shares] = await Promise.all([
    vault.convertToShares(assets),
  ])

  return shares;
};

export const convertSharesToAssets = async (provider: AbstractProvider, vaultAddress: string, shares: bigint) => {
  const vault = getVaultContract(provider, vaultAddress);
  
  const [assets] = await Promise.all([
    vault.convertToAssets(shares),
  ])

  return assets;
};

const trySync = async (vault: Contract, { blockTag }: { blockTag?: BlockTag } = {}) => {
  try {
    await vault.sync.staticCall({ blockTag: blockTag })
    return true
  } catch (e) {
    console.log(e)
    return false
  }
}
