import React, { useMemo, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Address } from "viem";
import { formatBig18, Big18Math } from '@perennial/sdk';
import "../../../styles/modals.scss";

import { useCtxBalances } from "../../../hooks/governance";
import { useStakerTransactions } from "../../../hooks/governance/tx";
import { NumericFormInput } from "../../common/NumericFormInput";
import { isNonNegativeNumber } from "../../../utils/utils";


const Stake = ({ show, keeperAddress, onHide }: { show: boolean, keeperAddress: Address, onHide: () => void }) => {
  const { t } = useTranslation();
  const { data: ctxBalances } = useCtxBalances();
  const { onApproveCtx, onStake } = useStakerTransactions()
  const [stakeAmount, setStakeAmount] = useState("");
  const [stakeAmountBI, setStakeAmountBI] = useState(0n);
  const [writingTran, setWritingTran] = useState(false);

  const { ctxBalance, ctxAllowance } = useMemo(() => {
    if (!ctxBalances) return { ctxBalance: 0n, ctxAllowance: 0n }

    return {
      ctxBalance: ctxBalances.ctxBalance,
      ctxAllowance: ctxBalances.ctxAllowance
    }
  }, [ctxBalances])

  const { stakeError } = useMemo(() => {
    let stakeError = "";
    if (stakeAmount !== "") {
      if (stakeAmountBI > ctxBalance) {
        stakeError = t("error.insufficient-balance");
      } else if (stakeAmountBI > ctxAllowance) {
        stakeError = t("error.insufficient-allowance", { token: "CTX", amount: formatBig18(ctxAllowance) });
      }
    }

    return {
      stakeError,
    }
  },
    // eslint-disable-next-line
    [stakeAmount, stakeAmountBI, ctxBalance, ctxAllowance]
  );

  const onApprove = async () => { 
    setWritingTran(true)
    await onApproveCtx(stakeAmountBI + 1n);
    setWritingTran(false)
  }

  const stake = async (event: React.MouseEvent) => {
    event.preventDefault();
    setWritingTran(true)
    if (stakeError === "") {
      await onStake(keeperAddress, stakeAmountBI);
      onHide();
    }
    setWritingTran(false)
  };

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      size="sm"
      centered
      className="stake-modal"
      onHide={() => {
        setStakeAmount("");
        onHide();
      }}
    >
      <Modal.Header closeButton>
        <h4>
          {t("governance.stake-delegate")}
        </h4>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <p style={{ marginBottom: "1.5rem", color: "#aaa9bf" }}>
            {t("governance.stake-info")}
          </p>
          <NumericFormInput
            controlId="ctx-stake"
            showBalance={true}
            amount={stakeAmount}
            title={t("governance.amount-stake")}
            titleSize={"1.1rem"}
            maxAmount={ctxBalance}
            tokenPrice={0n}
            maxCaption="Balance"
            onChange={(value: string) => {
              if (isNonNegativeNumber(value) || value.trim() === "") { 
                setStakeAmount(value.trim());
                if (value.trim() === "") {
                  setStakeAmountBI(0n);
                } else {
                  setStakeAmountBI(Big18Math.fromFloatString(value));  
                }
              }
            }}
            onSetMaxAmount={() => {
              setStakeAmount(formatBig18(ctxBalance, { useGrouping: false, minDecimals: 10 }));
              setStakeAmountBI(ctxBalance);
            }}
            is18Decimals={true}
            tokenSymbol="CTX"
            showUSDValue={false}
          />
        </Form>
        {stakeError !== "" && <p className="text-red">{stakeError}</p>}
      </Modal.Body>
      <Modal.Footer>
        {stakeAmountBI <= ctxAllowance ? (
          <Button
            className="w-100"
            onClick={stake}
            disabled={writingTran || stakeAmountBI === 0n  || stakeError !== ""}
          >
            <div className="btn-spinner">
              {writingTran && <Spinner animation="border" variant="secondary" className="small" />}
              {!writingTran ? t("governance.delegate-to") : t("governance.delegating")}
            </div>
          </Button>
        ) : (
          <Button variant="primary" className="w-100" onClick={onApprove} disabled={writingTran}>
            <div className="btn-spinner">
              {writingTran && <Spinner animation="border" variant="secondary" className="small" />}
              {!writingTran ? t("approve", { token: "CTX" }) : t("approving")}
            </div>
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default Stake;
