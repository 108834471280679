import React from "react";
import ReactGA from "react-ga4";
import { Spot } from "../../components/Spot";


const SpotPage = () => {
  ReactGA.send({ hitType: "pageview", page: "/", title: "Spot Trading" });

  return (
    <Spot />
  )
};

export default SpotPage
