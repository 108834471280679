import React, { useMemo, useState } from "react"
import { Dropdown, Image, Stack } from "react-bootstrap/esm"
import { ethers } from "ethers"
import { arbitrum } from "viem/chains"
import { formatBig18USDPrice } from "@perennial/sdk"
import { gql, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { useMediaQuery } from "react-responsive";

import tcapIcon from "../../assets/tcap-coin.png";
import { TokenIcon } from "../../components/common"
import { useTcapPriceChanges } from "../hooks/graph"
import { useVaultSnapshot } from "../hooks"
import { tcapLongMarketContract } from "../utils/contracts"
import { TCAP_TOKEN, aggregatorContracts } from "../utils/constants"
import { NumericFormat } from "react-number-format"
import { addPositions, calculateFunding, calculateLeverageBN, getVaultExposure, nextPosition } from "../utils/utils"


const TcapPrice = ({ showChangeColor } : { showChangeColor?: boolean }) => {
  const [tokenPrice, setTokenPrice] = useState(0)
  const [priceDelta, setPriceDelta] = useState(0)

  const loadPrice = (data: any) => {
    if (data.length > 0) {
      const oraclePrice = BigInt(data[0].answer);
      const lastPrice = parseFloat(ethers.formatEther(oraclePrice));
      const secondLastPrice = parseFloat(ethers.formatEther(
        BigInt(data[1].answer)
      ));

      setTokenPrice(lastPrice);
      setPriceDelta(lastPrice - secondLastPrice);
    }
  }

  const LASTESTS_PRICE_UPDATES = gql`
    query LastPriceUpdates($aggregatorAddress: String!) {
      answerUpdateds(
        first: 2,
        orderBy: blockTimestamp, 
        orderDirection: desc,
        where: { aggregatorAddress: $aggregatorAddress }
      ) {
        id
        updatedAt
        roundId
        blockTimestamp
        blockNumber
        answer
      }
    }
  `;

  useQuery(LASTESTS_PRICE_UPDATES, {
    fetchPolicy: "no-cache",
    variables: {
      aggregatorAddress: aggregatorContracts[TCAP_TOKEN.key].address
    },
    notifyOnNetworkStatusChange: true,
    pollInterval: 20000,
    onError: (error) => {
      console.log(error);
    },
    onCompleted: (data: any) => {
      if (data) {
        loadPrice(data.answerUpdateds);
      }
    },
  });

  return (
    <NumericFormat
      className={"price number ".concat(
        showChangeColor ? (priceDelta ? "text-green" : "text-red") : ""
      )}
      value={tokenPrice.toFixed(2)}
      displayType="text"
      thousandSeparator=","
      prefix="$"
      decimalScale={2}
    />
  )
}

const TcapVaultApr = () => {
  const { data: vaultSnapshot } = useVaultSnapshot(
    tcapLongMarketContract[arbitrum.id].address
  )

  const { apr } = useMemo(() => {
    if (!vaultSnapshot) return { apr: 0 }

    const { longSnapshot, longUserSnapshot, shortSnapshot, shortUserSnapshot, totalAssets } = vaultSnapshot

    const price = longSnapshot.latestVersion.price
    const longGlobalPosition = nextPosition(longSnapshot.pre, longSnapshot.position);
    const shortGlobalPosition = nextPosition(shortSnapshot.pre, shortSnapshot.position);
    const userLongPosition = nextPosition(longUserSnapshot.pre, longUserSnapshot.position);
    const userShortPosition = nextPosition(shortUserSnapshot.pre, shortUserSnapshot.position);
    const userTotalPosition = addPositions(userLongPosition, userShortPosition);
    const leverage = calculateLeverageBN(price, userTotalPosition.maker, totalAssets);

    // Funding = Long(Utilization * Rate * Leverage) + Short(Utilization * Rate * Leverage)
    const longFunding = calculateFunding(
      leverage,
      longSnapshot.rate,
      longGlobalPosition
    )
    const shortFunding = calculateFunding(
      leverage,
      shortSnapshot.rate,
      shortGlobalPosition
    )
    const funding = (longFunding + shortFunding) / 2n
    const fundingApr = ethers.formatEther(funding * (60n * 60n * 24n * 365n) * 100n)

    return {
      apr: parseFloat(fundingApr).toFixed(2)
    }  
  }, [vaultSnapshot])

  return (
    <span className="number text-green">
      {apr}%
    </span> 
  )
}

export const TcapMarketStats = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const { data: vaultSnapshot } = useVaultSnapshot(tcapLongMarketContract[arbitrum.id].address);
  const { data: pricesData } = useTcapPriceChanges()

  const { exposure, delta } = useMemo(() => {
    if (!vaultSnapshot) return { exposure: "0", delta: 0 }

    const { exposure, delta } = getVaultExposure(vaultSnapshot)
  
    return {
      exposure: (exposure * 100).toFixed(2),
      delta,
    }
  }, [vaultSnapshot]);

  const { changeIsNegative, changePercent } = useMemo(() => {
    if (pricesData && pricesData.answerUpdateds) {
      const prices = pricesData?.answerUpdateds

      if (prices.length > 0) {
        const currentPrice = parseFloat(ethers.formatEther(BigInt(prices[0].answer)))
        const price24H = prices.length > 1
          ? parseFloat(ethers.formatEther(BigInt(prices[prices.length - 1].answer)))
          : currentPrice
        
        return {
          changeIsNegative: currentPrice - price24H < 0,
          changePercent: ((currentPrice - price24H) / currentPrice) * 100
        }
      }      
    }

    return {
      changeIsNegative: false,
      changePercent: 0
    }
  }, [pricesData])

  return (
    <div className="markets-stats">
      <div className="stats-values">
        <div className="stats-item left">
          <span className="title small">
            {!isMobile ? `${t("vault-exposure")}:` : `${t("exposure")}:`}
          </span>
          <div className="stats-item-2">
            <NumericFormat
              className="number small"
              value={exposure}
              displayType="text"
              thousandSeparator=","
              decimalScale={4}
              suffix={"% ".concat(delta < 0 ? "Short " : "Long ")}
            />
          </div>
        </div>
        <div className="stats-item right">
          <span className="title small">
            {t("metrics.24h-price-change")}:
          </span>
          <span className={`number ${!changeIsNegative ? "text-green" : "text-red"}`}>
            {changePercent.toFixed(2)}%
          </span>
        </div>
      </div>
    </div>    
  )    
};

export const TcapMarketInfo = ({ positionType } : { positionType: string }) => {
  <div className="markets-info">
    <Stack direction="horizontal">
      <TokenIcon name={TCAP_TOKEN.symbol} size="normal" />
      <div className="markets-desc">
        <h6 className="title">
          {TCAP_TOKEN.symbol} - USD
        </h6>
        <span className="caption">
          {positionType.toUpperCase()} - USDC.e
        </span>
      </div>
    </Stack>  
    <div className="vertical-divisor" />
    <Stack style={{ justifyContent: "center" }}>
      <TcapPrice showChangeColor={true} />
    </Stack>
  </div>
}

export const TcapMarketToggle = () => (
  <div className="dropdown-toggle-container">
    <div className="toggle-section market" style={{ width: "55%" }}>
      <TokenIcon name={TCAP_TOKEN.symbol} size="normal" />
      <div className="market-desc">
        <h6 className="title">
          {TCAP_TOKEN.symbol} - USD
        </h6>
        <span className="caption">{TCAP_TOKEN.description}</span>
      </div>
    </div>
    <div className="vertical-divisor" />
    <div className="toggle-section price-box" style={{ width: "45%" }}>
      <div className="flex-column">
        <TcapPrice showChangeColor={true} />
      </div>  
    </div>
  </div>
);

export const TcapVaultMarketToggle = () => {

  return (
    <div className="dropdown-toggle-container">
      <div className="toggle-section desc-box" style={{ width: "55%" }}>
        <TokenIcon name={TCAP_TOKEN.symbol} size="normal" />
        <div className="market-desc">
          <h6 className="title">
            {TCAP_TOKEN.symbol} - USD
          </h6>
          <span className="caption">{TCAP_TOKEN.description}</span>
        </div>
      </div>
      <div className="vertical-divisor" />
      <div className="toggle-section apr-box" style={{ width: "45%" }}>
        <div className="flex-column">
          <TcapVaultApr />
        </div>
      </div>
    </div>
  );
};  

export const TcapMarketItem = ({ isVault } : { isVault?: boolean }) => {
  const { data: vaultSnapshot } = useVaultSnapshot(tcapLongMarketContract[arbitrum.id].address);

  const { currentLiquidity } = useMemo(() => {
    const currentLiquidity = vaultSnapshot ? vaultSnapshot.totalAssets : 0n

    return {
      currentLiquidity,
    }
  }, [vaultSnapshot]);

  return (
    <Dropdown.Item key={TCAP_TOKEN.key} eventKey={TCAP_TOKEN.key}>
      <Stack className="left" direction="horizontal">
        <Image src={tcapIcon} height={isVault ? 26 : 34} alt="TCAP Icon" style={{ marginRight: "5px" }} />
        <Stack style={{ marginLeft: isVault ? "7px" : "0px", justifyContent: "center" }}>
          <h6>{TCAP_TOKEN.symbol} {isVault ? "Vault" : "- USD"}</h6>
          {!isVault && <span className="small text-muted">{TCAP_TOKEN.description}</span>}
        </Stack>
      </Stack>
      <Stack className="right">
        {isVault ? (
          <TcapVaultApr />
        ) : (
          <>
            <TcapPrice />
            <span className="number small text-muted">
              {formatBig18USDPrice(currentLiquidity, {
                compact: true,
              })}
            </span>
          </>  
        )}
      </Stack>
    </Dropdown.Item>
  )
}
