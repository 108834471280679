import { AbstractProvider, ethers, Typed } from "ethers";
import useSWR from "swr";
import axios from "axios";
import { API_BASE_URL } from "../utils/constants";
import { getLensContract, getProductContract } from "../utils/contracts";
import { UserProductHistorySnapshot, TradesHistoryType } from "../utils/types";
import { nextPosition, userPositionSize, getMarketByProduct } from "../utils/utils";
import { useActiveProvider } from ".";


const useUserTradesHistoryFetcher = () => async ([_, chainId, apiUrl, userAddress, market, type]: [
  _: string,
  chainId: number,
  apiUrl: string,
  userAddress: string,
  market: string,
  type: string,
]): Promise<any> => {
  
  let response = {
    success: false,
    trades: new Array<TradesHistoryType>(),
    error: null,
  }

  let url = apiUrl.concat(userAddress);
  if (market !== "all") {
    const queryParams = "?market=".concat(market);
    url = url.concat(queryParams);    
  }

  try {
    const resp = await axios.get(url);
    if (resp && resp.data) {
      if (resp.status === 200) {
        response.success = true;
        // const currentPositionType = isTaker ? "TAKE" : "MAKE";
        let trades = new Array<TradesHistoryType>();
        
        // let previousTrade: any;
        resp.data.forEach((trade: any) => {
          const marketInfo = getMarketByProduct(chainId, trade.product_address);

          trades.push({
            market: trade.market,
            marketInfo,
            action: trade.type,
            account: trade.account,
            productAddress: trade.product_address,
            productType: trade.product_type,
            amount: BigInt(trade.amount),
            blockNumber: trade.block_number,
            transactionHash: trade.transaction_hash,
            tokenPrice: 0,
            ts: new Date(trade.time),
          }); 
        })

        if (type.toLowerCase() !== "all") {
          response.trades = trades.filter((trade: TradesHistoryType) => trade.productType === type.toUpperCase()); 
        } else {
          response.trades = trades;
        }
      }  
    }
  } catch (error: any) { 
    console.error("Error:", error);
  }
  
  return response;
};

export const useUserTradesHistory = (market: string, type: string) => {
  const { chainId, userAccount } = useActiveProvider();
  const apiUrl = API_BASE_URL[chainId].url.concat("stats/trade-history/");

  return useSWR<any>(
    ["useTradesHistory", chainId, apiUrl, userAccount, market, type],
    useUserTradesHistoryFetcher(),
    { revalidateOnFocus: false },
  )
};


export const getTadresSnapshots =
  async (chainId: number, provider: AbstractProvider, trades: TradesHistoryType[]): Promise<Array<UserProductHistorySnapshot>> => {
  const lens = getLensContract(chainId, provider);
  
  const snapshotsCalls = trades.map((trade) =>
    lens.snapshot.staticCall(Typed.address(trade.account), Typed.address(trade.productAddress), { blockTag: trade.blockNumber })
  );
  
  const snapshotsResp = await Promise.all(snapshotsCalls)

  const resp = snapshotsResp.map((ss, index) => {
    const userNextPosition = nextPosition(ss.pre, ss.position);
    const market = getMarketByProduct(chainId, ss.productAddress);

    return {
      ...ss,
      productAddress: ss.productAddress.toLowerCase(),
      nextPosition: userNextPosition,
      positionSize: userPositionSize(userNextPosition),
      positionDirection: "none",
      market,
      action: trades[index].action,
      productType: trades[index].productType,
      amount: trades[index].amount,
      blockNumber: trades[index].blockNumber,
      ts: trades[index].ts,
    }
  });

  return resp;
  };


export const getPricesAtTrade =
  async (provider: AbstractProvider, trades: TradesHistoryType[]): Promise<Array<TradesHistoryType>> => {

    const pricesCalls = trades.map((trade) => {
      const productContract = getProductContract(provider, trade.productAddress);
      return productContract.currentVersion({ blockTag: trade.blockNumber }); // .callStatic["currentVersion()"]({ blockTag: trade.blockNumber })
    });
    
    const pricesResp = await Promise.all(pricesCalls);
    const resp = pricesResp.map((price, index) => {
      return {
        ...trades[index],
        tokenPrice: Math.abs(parseFloat(ethers.formatEther(price[2]))),
      }
    });

    return resp;
  };
