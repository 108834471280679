export const DelegatorFactoryAddress = "0x70236b36f86AB4bd557Fe9934E1246537B472918"

export const ApiUrl = process.env.REACT_APP_API_URL || "https://api.cryptex.finance";

export const OAuthTokenEndpoint = ApiUrl.concat("o/token/");

export const KeeperAllEndPoint = ApiUrl.concat("cryptkeeper/all/");

export const KeeperCreateEndpoint = ApiUrl.concat("cryptkeeper/create/");

export const KeeperUpdateEndpoint = ApiUrl.concat("cryptkeeper/update/");

export type KeeperType = {
  id: string;
  delegatee: string;
  delegatedVotes: string;
  delegatedVotesRaw: string;
  tokenOwners: { stake: string; stakeRaw: string }[];
  totalHoldersRepresented: number;
  createdAt: number;
};

export type KeeperInfoType = {
  id: number;
  address: string;
  name: string;
  eth_name: string;
  image: string;
  expertise: string[];
  why: string;
  discord: string;
  twitter: string;
  delegatedVotes: number;
  totalHoldersRepresented: number;
  createdAt: number;
};