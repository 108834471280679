import { BigNumberish } from "ethers";

export type ContractType = {
  [x: number]: {
    address: string;
  }
  abi: any;
}

export type TokenType = {
  key: string;
  symbol: string;
  description: string;
  displayDecimals: number;
  multiplier: bigint,
  [x: number]: {
    oracleContract: ContractType;
  };
};

export type ProductType = {
  market: TokenType;
  collateral: TokenType;
  longContract: ContractType;  
  shortContract: ContractType;
  collateralContract: ContractType;
};

export enum OpenPositionType {
  Maker = "maker",
  Taker = "taker",
}

export type PositionType = OpenPositionType | "none";


export type ProtocolSnapshot = {
  collateral: any;
  incentivizer: any;
  collateralToken: any;
  protocolFee: bigint
  liquidationFee: bigint;
  minCollateral: bigint;
  paused: boolean;
};

export type AccountProductPosition = {
  type: PositionType
  amount: string
  canOpenTaker: boolean
  canOpenMaker: boolean
};

export type PositionBucket = {
  startingCollateral: bigint
  startingBlock: number
  endingBlock: number
  subPositions: {
    size: bigint
    price: bigint
    delta: bigint
    block: number
    tx?: string
    version?: bigint
  }[]
  collateralChanges: { deposits: bigint[]; withdrawals: bigint[] }
  afterCloseCollateralChanges?: {
    deposits: { amount: bigint; block: number; tx?: string }[]
    withdrawals: { amount: bigint; block: number; tx?: string }[]
  }
};

export type UserPositionChanges = {
  buckets: PositionBucket[],
  positionLiquidated: boolean,
};

export type ProductDetails = {
  name: string;
  price: string;
  totalTaker: string;
  totalMaker: string;
  rate: string;
  position: AccountProductPosition;
  maintenanceRequired?: string;
  // string of BigNumber of fixed18
  collateral: string;
  symbol: string;
  address: string;

  // string of BigNumber of fixed18
  // maintenance ratio
  maintenance?: string;

  userPositionChanges?: UserPositionChanges;
};

export type ProductCollateralDetails = {
  userCollateral: bigint;
  maintenance: bigint;
  usdcAllowance: bigint;
}

export type PositionStruct = {
  maker: bigint;
  taker: bigint;
}

export type PrePositionStruct = {
  oracleVersion: BigNumberish;
  openPosition: PositionStruct;
  closePosition: PositionStruct;
}

type ProductInfo = {
  name: string
  symbol: string;
  payoffDefinition: any;
  oracle: any;
  maintenance: bigint;
  fundingFee: bigint;
  makerFee: bigint;
  takerFee: bigint;
  positionFee: bigint;
  makerLimit: bigint;
  utilizationCurve: any;
}

export type ProductSnapshot = {
  productInfo: ProductInfo;
  address: string;
  rate: bigint;
  dailyRate: bigint;
  latestVersion: any;
  maintenance: bigint;
  collateral: bigint;
  shortfall: bigint;
  pre: PrePositionStruct;
  position: PositionStruct;
  productFee: bigint;
  protocolFee: bigint;
  openInterest: PositionStruct;
}

export type UserProductSnapshot = {
  productAddress: string;
  userAddress: string;
  collateral: bigint;
  maintenance: BigNumberish;
  pre: PrePositionStruct;
  position: PositionStruct;
  liquidatable: boolean;
  liquidating: boolean;
  openInterest: PositionStruct;
  fees: BigNumberish;
  exposure: BigNumberish;
};

export type ExtendedUserProductSnapshot = UserProductSnapshot & {
  nextPosition: PositionStruct;
  positionSize: bigint;
  positionDirection: PositionType;
};

export type TokensBalanceType = {
  usdcBalance: bigint;
}

export type RewardsType = {
  amountStaked: bigint;
  earned: bigint;
  rewardRate: bigint;
  totalSupply: bigint;
}

export type VaultAllowances = {
  usdc: bigint;
  dsuAllowance: bigint;
  shares: bigint;
  sharesToStake: bigint;
}

export type VaultSnapshot = {
  address: string
  name: string
  symbol: string
  long: string
  short: string
  totalSupply: bigint
  totalAssets: bigint
  pendingRedemptions: bigint,
  targetLeverage: bigint
  maxCollateral: bigint
  longSnapshot: ProductSnapshot
  shortSnapshot: ProductSnapshot
  longUserSnapshot: UserProductSnapshot
  shortUserSnapshot: UserProductSnapshot
  canSync: boolean
};

export type VaultUserSnapshot = {
  balance: bigint
  assets: bigint
  claimable: bigint
  totalDeposit: bigint
  totalClaim: bigint
  currentPositionDeposits: bigint
  currentPositionClaims: bigint
  deposits: any[]
  redemptions: any[]
  pendingRedemptionAmount: bigint
  pendingDepositAmount: bigint
  claims: any[]
  rewardsAssets: bigint
};

export type CashbackProofType = {
  account: string;
  fees: string;
  reward_amount: string | number;
  proof: Array<string>;
}

export type CashbackDistributionType = {
  metadata: {
    root: string | null;
    reward_max_amount: string | null;
    reward_price: string | null;
  };
  distribution: Array<CashbackProofType>;
}

export type TradesHistoryType = {
  market: string;
  marketInfo: TokenType,
  action: string;
  account: string;
  productAddress: string;
  productType: string;
  amount: bigint;
  blockNumber: number;
  transactionHash: string;
  tokenPrice: number;
  ts: Date;
}

export type UserProductHistorySnapshot = ExtendedUserProductSnapshot & {
  market: TokenType;
  action: string;
  productType: string;
  amount: bigint;
  blockNumber: number;
  ts: Date;
}