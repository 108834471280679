import React from "react";
import { TokenType } from "../utils/types";


export interface IPositionStatsContext {
  market: TokenType | null,
  setCurrentMarket: (currentMarket: TokenType | null) => void;
  type: string,
  setCurrentType: (currentType: string) => void;
  exposure: number;
  setCurrentExposure: (currentMarket: number) => void;
  size: number;
  setCurrentSize: (currentSize: number) => void;
  collateral: number;
  setCurrentCollateral: (currentCollateral: number) => void; 
  pnl: number;
  setCurrentPnl: (currentPnl: number) => void;
};

export const STATS_DEFAULT_VALUE = {
  market: null,
  setCurrentMarket: () => {},
  type: "LONG",
  setCurrentType: () => {},
  exposure: 0,
  setCurrentExposure: () => {},
  size: 0,
  setCurrentSize: () => {},
  collateral: 0,
  setCurrentCollateral: () => {},
  pnl: 0,
  setCurrentPnl: () => {},
}

export const positionStatsContext = React.createContext<IPositionStatsContext>(STATS_DEFAULT_VALUE);
