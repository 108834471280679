import { Address } from 'viem'
import { Big6Math, PositionSide, SupportedAsset } from '@perennial/sdk'

import { FormNames, OrderTypes, OrderValues } from '../../../constants'

export const limitOrderPercents = ['5', '10', '15', '20', '30']
export const stopLossPercents = ['-5', '-10', '-20', '-30', '-50']
export const takeProfitPercents = ['+5', '+10', '+20', '+30', '+50']

export type TriggerFormValues = Pick<OrderValues, 'takeProfit' | 'triggerAmount' | 'stopLoss'>

export const getInitialTriggerFormState = (latestPrice: string): TriggerFormValues => ({
  [FormNames.triggerAmount]: '',
  [FormNames.takeProfit]: latestPrice,
  [FormNames.stopLoss]: latestPrice,
})

export type EditOrderValues = {
  orderValues: OrderValues
  orderType: OrderTypes
  asset: SupportedAsset
  orderDirection: PositionSide
  cancelOrderDetails: {
    nonce: bigint
    market: Address
  }
}

export const OrderExecutionDeposit = Big6Math.fromFloatString('20')
