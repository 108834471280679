import React from 'react'
import { useOpenOrderData } from '../hooks'
import { OrdersList } from './OrdersList'


const Orders = () => {
  const openOrders = useOpenOrderData()

  return (
    <OrdersList openOrders={openOrders} />
  )
}

export default Orders