// import { utils } from "ethers";
import { getAddress, encodeAbiParameters, parseAbiParameters } from "viem"

export enum InvokerAction {
  NOOP,
  DEPOSIT,
  WITHDRAW,
  OPEN_TAKE,
  CLOSE_TAKE,
  OPEN_MAKE,
  CLOSE_MAKE,
  CLAIM,
  WRAP,
  UNWRAP,
  WRAP_AND_DEPOSIT,
  WITHDRAW_AND_UNWRAP,
  VAULT_DEPOSIT,
  VAULT_REDEEM,
  VAULT_CLAIM,
  VAULT_WRAP_AND_DEPOSIT,
}

type invokerFunctionParams = {
  userAddress: string;
  productAddress?: string;
  position?: bigint;
  collateral?: bigint;
  vaultAddress?: string;
  programs?: bigint[];
};

export const buildInvokerAction = (
  action: InvokerAction,
  {
    userAddress,
    productAddress,
    position,
    collateral,
    vaultAddress,
    programs,
  }: invokerFunctionParams
): { action: number, args: string } => {
  // const defaultAbiCoder = AbiCoder.defaultAbiCoder();
  
  switch (action) {
    case InvokerAction.DEPOSIT:
      if (collateral === undefined || productAddress === undefined) throw new Error('Invalid arguments')
      return {
        action: 1,
        args: encodeAbiParameters(parseAbiParameters("address, address, uint256"), [
          getAddress(userAddress),
          getAddress(productAddress),
          collateral
        ]),
      }
    case InvokerAction.WITHDRAW:
      if (collateral === undefined || productAddress === undefined) throw new Error('Invalid arguments')
      return {
        action: 2,
        args: encodeAbiParameters(parseAbiParameters("address, address, uint256"), [
          getAddress(userAddress),
          getAddress(productAddress),
          collateral
        ]),
      }
    case InvokerAction.OPEN_TAKE:
      if (position === undefined || productAddress === undefined) throw new Error('Invalid arguments')
      return {
        action: 3,
        args: encodeAbiParameters(parseAbiParameters("address, uint256"), [getAddress(productAddress), position]),
      }
    case InvokerAction.CLOSE_TAKE:
      if (position === undefined || productAddress === undefined) throw new Error('Invalid arguments')
      return {
        action: 4,
        args: encodeAbiParameters(parseAbiParameters(["address, uint256"]), [getAddress(productAddress), position]),
      }
    case InvokerAction.OPEN_MAKE:
      if (position === undefined || productAddress === undefined) throw new Error('Invalid arguments')
      return {
        action: 5,
        args: encodeAbiParameters(parseAbiParameters(["address, uint256"]), [getAddress(productAddress), position]),
      }
    case InvokerAction.CLOSE_MAKE:
      if (position === undefined || productAddress === undefined) throw new Error('Invalid arguments')
      return {
        action: 6,
        args: encodeAbiParameters(parseAbiParameters(["address, uint256"]), [getAddress(productAddress), position]),
      }
    case InvokerAction.CLAIM:
      if (productAddress === undefined || programs === undefined) throw new Error('Invalid arguments')
      return {
        action: 7,
        args: encodeAbiParameters(parseAbiParameters(["address, uint256[]"]), [getAddress(productAddress), programs]),
      }
    case InvokerAction.WRAP:
      if (collateral === undefined) throw new Error('Invalid arguments')
      return {
        action: 8,
        args: encodeAbiParameters(parseAbiParameters(["address, uint256"]), [getAddress(userAddress), collateral]),
      }
    case InvokerAction.UNWRAP:
      if (collateral === undefined) throw new Error('Invalid arguments')
      return {
        action: 9,
        args: encodeAbiParameters(parseAbiParameters(["address, uint256"]), [getAddress(userAddress), collateral]),
      }
    case InvokerAction.WRAP_AND_DEPOSIT:
      if (productAddress === undefined || collateral === undefined) throw new Error('Invalid arguments')
      return {
        action: 10,
        args: encodeAbiParameters(parseAbiParameters(["address, address, uint256"]), [
          getAddress(userAddress),
          getAddress(productAddress),
          collateral
        ]),
      }
    case InvokerAction.WITHDRAW_AND_UNWRAP:
      if (productAddress === undefined || collateral === undefined) throw new Error('Invalid arguments')
      return {
        action: 11,
        args: encodeAbiParameters(parseAbiParameters(["address, address, uint256"]), [
          getAddress(userAddress),
          getAddress(productAddress),
          collateral
        ]),
      }
    case InvokerAction.VAULT_DEPOSIT:
      if (vaultAddress === undefined || collateral === undefined) throw new Error('Invalid arguments')
      return {
        action: 12,
        args: encodeAbiParameters(parseAbiParameters(["address, address, uint256"]), [
          getAddress(userAddress),
          getAddress(vaultAddress),
          collateral
        ]),
      }
    case InvokerAction.VAULT_REDEEM:
      if (vaultAddress === undefined || collateral === undefined) throw new Error('Invalid arguments')
      return {
        action: 13,
        args: encodeAbiParameters(parseAbiParameters(["address, uint256"]), [getAddress(vaultAddress), collateral]),
      }
    case InvokerAction.VAULT_CLAIM:
      if (vaultAddress === undefined) throw new Error('Invalid arguments')
      return {
        action: 14,
        args: encodeAbiParameters(parseAbiParameters(["address, address"]), [getAddress(userAddress), getAddress(vaultAddress)]),
      }
    case InvokerAction.VAULT_WRAP_AND_DEPOSIT:
      if (vaultAddress === undefined || collateral === undefined) throw new Error('Invalid arguments')
      return {
        action: 15,
        args: encodeAbiParameters(parseAbiParameters(["address, address, uint256"]), [
          getAddress(userAddress),
          getAddress(vaultAddress),
          collateral
        ]),
      }
    default:
      return { action: 0, args: "0x" }
  }
};
