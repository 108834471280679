import React, { useState } from "react";
import { Button, Image, Table } from "react-bootstrap/esm";
import { NumericFormat } from "react-number-format";
import {
  Big6Math,
  calcFundingRates,
  formatBig6,
  formatBig6Percent,
  formatBig6USDPrice,
  PositionSide,
  PositionStatus,
  SupportedAsset,
  UserMarketSnapshot,
} from "@perennial/sdk";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { useMarketContext, useLivePriceContext } from "../../../contexts"
import { CustomTooltip } from "../../common";
import { MarketMetadata } from "../../../constants/markets";
import { capitalize } from "../../../v1/utils/utils";
import { UpdatePosition } from "../modals/UpdatePosition";
import { ClosePosition } from "../modals/ClosePosition";
import { useActiveMarketsPnls, useOpenPositionsData, usePositionPnl } from "../../../hooks/markets2/metrics";
import { PositionTableData } from "../constants";


const pnlClassName = (pnl: number) => {
  if (Math.abs(pnl) < 0.0001) {
    return "number"
  }
  return pnl >= 0 ? "number green" : "number red"
}

export const PositionsList = ({ isProInterface }: { isProInterface: boolean }) => {
  const { t } = useTranslation()
  const isSmallScreen = useMediaQuery({ query: "(min-width: 851px) and (max-width: 1700px)" })
  const isMobile = useMediaQuery({ query: "(max-width: 850px)" })
  const { snapshots2, setSelectedMarket, setSelectedMakerMarket } = useMarketContext()
  const livePrices = useLivePriceContext()
  const marketsPnl = useActiveMarketsPnls(livePrices)
  const { positions } = useOpenPositionsData()

  const [showUpdatePosition, setShowUpdatePosition] = useState(false)
  const [showClosePosition, setShowClosePosition] = useState(false)

  const hasPositions = positions && positions.length > 0
  const exposureTooltip = t("tooltip.exposure")
  const collateralTooltip = t("tooltip.collateral")
  const pnlTooltip = t("tooltip.pnl")
  const entryPriceTooltip = t("tooltip.entry-price-2")
  const liqPriceTooltip = t("tooltip.liquidation-price")

  const getPositionPnlData = (position: UserMarketSnapshot) => {
    if (marketsPnl) {
      const positionData = marketsPnl[position.market]
      return {
        pnl: position.side === PositionSide.none && position.status === PositionStatus.closed ? 0n : positionData?.livePnl || 0n,
        initialCollateral: positionData?.data.startCollateral || 0n
      }
    }
    return {
      pnl: 0n,
      initialCollateral: 0n
    }
  }

  const PositionTotals = () => {
    let exposure = 0n
    let collateral = 0n
    let totalPnl = 0n
    
    if (snapshots2 && snapshots2.user) {
      const assets = Object.keys(snapshots2.user)
      for (let i = 0; i < assets.length; i += 1) {
        const position = snapshots2.user[assets[i] as SupportedAsset]
        const addPosition =(isProInterface && position.nextSide === PositionSide.maker)
          || (!isProInterface && position.nextSide !== PositionSide.maker)

        if (addPosition && position.status !== PositionStatus.resolved) {
          exposure += position.nextNotional
          collateral += position.local.collateral
          const { pnl } = getPositionPnlData(position)
          totalPnl += pnl
        }
      }
    }

    return (
      <tr className="table-totals">
        <td>
          <h5>{t("totals")}</h5>
        </td>
        <td className="right">
          <NumericFormat
            className="bold"
            value={formatBig6USDPrice(exposure)}
            displayType="text"
            thousandSeparator=","
            prefix="$"
            decimalScale={4}
          />
        </td>
        <td className="right">
          <NumericFormat
            className="bold"
            value={formatBig6USDPrice(collateral)}
            displayType="text"
            thousandSeparator=","
            prefix="$"
            decimalScale={4}
          />
        </td>
        <td className=" right">
          <NumericFormat
            className={
              "bold ".concat(pnlClassName(parseFloat(formatBig6(totalPnl))))
            }
            value={formatBig6USDPrice(totalPnl)}
            displayType="text"
            thousandSeparator=","
            prefix="$"
            decimalScale={4}
          />
        </td>
        <td />
        <td />
        <td />
        <td />
        <td />
      </tr>
    )
  }

  return (
    <>
      {!isMobile ? (
        <>
          {hasPositions && (
            <Table striped hover variant="dark">
              <thead>
                <tr>
                  <th>{t("market")}</th>
                  <th className="right">
                    <CustomTooltip
                      id="texposure"
                      msg={exposureTooltip}
                      showIcon={true}
                      placement="top"
                    >
                      <h6 className="margin-right">{t("exposure")}</h6>
                    </CustomTooltip>  
                  </th>
                  <th className="right">
                    <CustomTooltip
                      id="texposure"
                      msg={collateralTooltip}
                      showIcon={true}
                      placement="top"
                    >
                      <h6 className="margin-right">{t("collateral")}</h6>
                    </CustomTooltip>  
                  </th>
                  <th className="right">
                    <CustomTooltip
                      id="tpnl"
                      msg={pnlTooltip}
                      showIcon={true}
                      iconOnLeft={false}
                      placement="top"
                    >
                      <h6 className="margin-right">
                        {!isSmallScreen ? t("total-profit-loss") : t("total-pnl")}
                      </h6>
                    </CustomTooltip>  
                  </th>
                  {!isProInterface ? (
                    <th className="right">
                      <CustomTooltip
                        id="taprice"
                        msg={entryPriceTooltip}
                        showIcon={true}
                        iconOnLeft={false}
                        placement="top"
                      >
                        <h6 className="margin-right">{t("est-entry-price")}</h6>
                      </CustomTooltip>
                    </th>
                  ) : (
                    <th className="right">
                      <h6 className="margin-right">{t("total-apr")}</h6>
                    </th>  
                  )}
                  <th className="right">
                    <CustomTooltip
                      id="tliqprice"
                      msg={liqPriceTooltip}
                      showIcon={true}
                      iconOnLeft={false}
                      placement="top"
                    >
                      <h6 className="margin-right">
                        {!isSmallScreen ? t("liquidation-price") : t("liq-price")}
                      </h6>
                    </CustomTooltip>
                  </th>
                  <th className="right">
                    <CustomTooltip
                      id="texposure"
                      msg={t("tooltip.hourly-funding-rate")}
                      showIcon={true}
                      iconOnLeft={false}
                      placement="top"
                    >
                      <h6 className="margin-right">
                        {!isSmallScreen ? t("hourly-funding-rate") : t("h-funding-rate")}
                      </h6>
                    </CustomTooltip>
                  </th>
                  <th className="center col-status">{t("status")}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {positions && positions.length > 0 &&
                  positions.map((position, index: number) => {
                    return (
                      <Position
                        key={index.toString()}
                        position={position}
                        isMaker={isProInterface}
                        index={index}
                        setShowUpdatePosition={setShowUpdatePosition}
                        setShowClosePosition={setShowClosePosition}
                        setSelectedMarket={isProInterface ? setSelectedMakerMarket : setSelectedMarket}
                      />
                    )
                    
                  })
                }
                {PositionTotals()}
              </tbody>
            </Table>
          )}
          {!hasPositions && (
            <div className="empty-positions">
              <h5 className="text-purple">
                {t("info-msg.no-positions")}
              </h5>
            </div>
          )}
        </>  
      ) : (
        <>
          {positions && positions.length > 0 &&
            positions.map((position, index: number) => {
              return (
                <Position
                  key={index.toString()}
                  position={position}
                  isMaker={isProInterface}
                  index={index}
                  setShowUpdatePosition={setShowUpdatePosition}
                  setShowClosePosition={setShowClosePosition}
                  setSelectedMarket={isProInterface ? setSelectedMakerMarket : setSelectedMarket}
                />
              )
            })
          }  
          {!hasPositions && (
            <div className="empty-positions">
              <h5 className="text-purple">
                {t("info-msg.no-positions")}
              </h5>
            </div> 
          )}  
        </>    
      )}
      {showUpdatePosition && (
        <UpdatePosition
          showModal={showUpdatePosition}
          isMaker={isProInterface}
          onHide={() => setShowUpdatePosition(false)}
        />
      )}
      <ClosePosition
        showModal={showClosePosition}
        isMaker={isProInterface}
        onHide={() => setShowClosePosition(false)}
      />
    </>
  )
};

const Position = ({
  position,
  isMaker,
  index,
  setShowUpdatePosition,
  setShowClosePosition,
  setSelectedMarket,
}: {
  position: PositionTableData,
  isMaker: boolean,
  index: number
  setShowUpdatePosition: (v: boolean) => void
  setShowClosePosition: (v: boolean) => void
  setSelectedMarket: (v: SupportedAsset) => void
}) => { 
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 850px)" })
  const exposureTooltip = t("tooltip.exposure")
  const pnlTooltip = t("tooltip.pnl")
  const entryPriceTooltip = t("tooltip.entry-price-2")
  const liqPriceTooltip = t("tooltip.liquidation-price")

  const { snapshots2 } = useMarketContext()
  const livePrices = useLivePriceContext()

  const minorSide = snapshots2?.market[position.asset]?.minorSide
  const pnlData = usePositionPnl(position.details, snapshots2?.market[position.asset], livePrices, position.failedClose)
  const isPnlPositive = pnlData ? pnlData.livePnl >= 0n : true
  const closingOrFailed = position.isClosing || position.failedClose || position.details.status === PositionStatus.syncError
  const direction = closingOrFailed ? position.details.side : position.details.nextSide
  const livePnl = formatBig6USDPrice(pnlData?.livePnl ?? 0n)

  if (position.details.status === PositionStatus.resolved) return <></>

  const marketMetadata = MarketMetadata[position.asset as SupportedAsset]

  const fundingRateSide = minorSide
    ? isMaker ? (minorSide as PositionSide.long | PositionSide.short)
      : (direction === PositionSide.none ? PositionSide.long : direction)
      : PositionSide.long

  const { hourlyFunding } = calcFundingRates(
    snapshots2?.market[position.asset]?.fundingRate[fundingRateSide],
  )
  const isFundingNegative = hourlyFunding < 0n

  let makerStats = {
    fundingAPR: 0n,
    interestAPR: 0n,
    positionFeeAPR: 0n
  }

  const statusColorClass = (status: string) => {
    if (status === PositionStatus.open || status === PositionStatus.opening) {
      return "text-green"
    } else if (status === PositionStatus.closed || status === PositionStatus.closing) {
      return "text-grey"
    }
    return "text-red"
  };

  const closeBtnLabel = (positionStatus: string): string => {
    if (positionStatus === PositionStatus.closed) {
      return t("withdraw")
    }
    if (positionStatus === PositionStatus.closing) { 
      return t("closing")
    }
    return t("close")
  }

  return (
    <>
      {!isMobile ? (
        <tr key={index.toString()}>
          <td>
            <div className="market">
              <Image className="token-icon margin-right" src={marketMetadata.icon} width={30} height={30} />
              <div className="market-info">
                <h6>
                  {capitalize(position.details.side)}
                </h6>
                <span className="title">{marketMetadata.name}</span>
              </div>
            </div>
          </td>
          <td className="right">
            <div className="flex-column">
              <NumericFormat
                className="balance"
                value={!closingOrFailed ? position.nextNotional : position.notional}
                displayType="text"
                thousandSeparator=","
                decimalScale={4}
                prefix="$"
              />
              <NumericFormat
                className="number small gray"
                value={!closingOrFailed ? position.nextPosition : position.position}
                displayType="text"
                thousandSeparator=","
                decimalScale={4}
                suffix={" ".concat(marketMetadata.baseCurrency.toUpperCase())}
              />
            </div>
          </td>
          <td className="right">
            <div className="flex-column">
              <NumericFormat
                className="balance"
                value={position.currentCollateral}
                displayType="text"
                thousandSeparator=","
                prefix="$"
                decimalScale={4}
              />
              <NumericFormat
                className="number small gray"
                value={position.startCollateral}
                displayType="text"
                thousandSeparator=","
                decimalScale={4}
                prefix="$"
              />
            </div>  
          </td>
          <td className="right">
            <NumericFormat
              className={isPnlPositive ? "number green" : "number red"}
              value={livePnl}
              displayType="text"
              thousandSeparator=","
              prefix="$"
              decimalScale={4}
            />
          </td>
          {!isMaker ? (
            <td className="right">
              <NumericFormat
                className="balance"
                value={pnlData?.averageEntryPriceFormatted || "0"}
                displayType="text"
                thousandSeparator=","
                prefix="$"
                decimalScale={marketMetadata.displayDecimals}
              />
            </td>
          ) : (
            <td className="right">
              <NumericFormat
                className="balance"
                value={formatBig6Percent(
                  makerStats.fundingAPR + makerStats.interestAPR + makerStats.positionFeeAPR,
                  { numDecimals: 4 }
                )}
                displayType="text"
                thousandSeparator=","
                suffix="%"
                decimalScale={4}
              />
            </td>
          )}
          <td className="right">
            <div className="flex-column liquidation-price">          
              <NumericFormat
                className="balance"
                value={position.liquidationPrice}
                displayType="text"
                thousandSeparator=","
                prefix="$"
              />
            </div>  
          </td>
          <td className="right">        
            <NumericFormat
              className={isFundingNegative ? "number green" : "number red"}
              value={
                direction !== PositionSide.none ? formatBig6Percent(Big6Math.abs(hourlyFunding), { numDecimals: 4 }) : ""
              }
              displayType="text"
              thousandSeparator=","
              suffix="%"
              decimalScale={5}
            />
          </td>
          <td className="center col-status">
            <span className={statusColorClass(position.details.status)}>
              {capitalize(t(position.details.status))}
            </span>
          </td>
          <td className="right">
            <Button
              variant="outline-primary"
              onClick={() => {
                setSelectedMarket(position.asset)
                setShowUpdatePosition(true)
              }}
            >
              {t("change")}
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => {
                setSelectedMarket(position.asset)
                setShowClosePosition(true)
              }}
            >
              {closeBtnLabel(position.details.status)}
            </Button>
          </td>
        </tr>
      ) : (
        <div key={index} className="position-box">
          <div className="box-header">
            <div className="market">
              <Image src={marketMetadata.icon} width={30} height={30} />
              <div className="market-info">
                <div className="postion-direction">
                  <h6>{capitalize(position.details.side)}</h6>
                  <div className="status">
                    <h6>-</h6>
                    <h6 className={statusColorClass(position.details.status)}>{capitalize(t(position.details.status))}</h6>
                  </div>
                </div>
                <span className="title">{marketMetadata.name}</span>
              </div>
            </div>
          </div>
          <div className="box-body">
            <div className="position-item">
              <div className="left">
                <NumericFormat
                  className="balance"
                  value={!closingOrFailed ? position.nextNotional : position.notional}
                  displayType="text"
                  thousandSeparator=","
                  decimalScale={4}
                  prefix="$"
                />
                <CustomTooltip
                  id="texposure"
                  msg={exposureTooltip}
                  showIcon={true}
                  placement="top"
                  iconOnLeft={true}
                >
                  <h6 className="margin-right">{t("exposure")}</h6>
                </CustomTooltip>
              </div>
              <div className="right">
                <NumericFormat
                  className="balance"
                  value={!closingOrFailed ? position.nextPosition : position.position}
                  displayType="text"
                  thousandSeparator=","
                  suffix={" ".concat(marketMetadata.baseCurrency.toUpperCase())}
                  decimalScale={4}
                />
                <h6 className="margin-right">{t("position-size")}</h6>
              </div>
            </div>
            <div className="position-item">
              <div className="left">
                <NumericFormat
                  className="balance"
                  value={position.startCollateral}
                  displayType="text"
                  thousandSeparator=","
                  prefix="$"
                  decimalScale={4}
                />
                <h6>{t("initial-collateral")}</h6>
              </div>
              <div className="right">
                <NumericFormat
                  className="balance"
                  value={position.currentCollateral}
                  displayType="text"
                  thousandSeparator=","
                  prefix="$"
                  decimalScale={4}
                />
                <h6>{t("current-collateral")}</h6>
              </div>
            </div>
            <div className="position-item">
              <div className="left">
                <NumericFormat
                  className={isPnlPositive ? "number text-green" : "number text-red"}
                  value={livePnl}
                  displayType="text"
                  thousandSeparator=","
                  prefix="$"
                  decimalScale={4}
                />
                <CustomTooltip
                  id="tpnl"
                  msg={pnlTooltip}
                  showIcon={true}
                  iconOnLeft={true}
                  placement="top"
                >
                  <h6 className="margin-right">{t("total-pnl")}</h6>
                </CustomTooltip>
              </div>
              <div className="right">
                <NumericFormat
                  className="balance"
                  value={position.liquidationPrice}
                  displayType="text"
                  thousandSeparator=","
                  prefix="$"
                />
                <CustomTooltip
                  id="tliqprice"
                  msg={liqPriceTooltip}
                  showIcon={true}
                  iconOnLeft={true}
                  placement="top"
                >
                  <h6 className="margin-right">{t("liq-price")}</h6>
                </CustomTooltip>
              </div>
            </div>
            <div className="position-item">
              <div className="left">
                <NumericFormat
                  className="balance"
                  value={pnlData?.averageEntryPriceFormatted || "0"}
                  displayType="text"
                  thousandSeparator=","
                  prefix="$"
                  decimalScale={marketMetadata.displayDecimals}
                />
                <CustomTooltip
                  id="taprice"
                  msg={entryPriceTooltip}
                  showIcon={true}
                  iconOnLeft={true}
                  placement="top"
                >
                  <h6 className="margin-right">{t("est-entry-price")}</h6>
                </CustomTooltip>
              </div>
              <div className="right">
                <NumericFormat
                  className="balance"
                  value={formatBig6Percent(hourlyFunding, { numDecimals: 4 })}
                  displayType="text"
                  thousandSeparator=","
                  suffix="%"
                  decimalScale={5}
                />
                <CustomTooltip
                  id="texposure"
                  msg="The interest rate paid hourly."
                  showIcon={true}
                  iconOnLeft={true}
                  placement="top"
                >
                  <h6 className="margin-right">{t("h-funding-rate")}</h6>
                </CustomTooltip>
              </div>
            </div>
          </div>
          <div className={"box-footer "}>
            <Button
              variant="outline-primary"
              onClick={() => {
                setSelectedMarket(position.asset)
                setShowUpdatePosition(true)
              }}
            >
              {t("change")}
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => {
                setSelectedMarket(position.asset)
                setShowClosePosition(true)
              }}
            >
              {closeBtnLabel(position.details.status)}
            </Button>
          </div>
        </div>  
      )}      
    </>
  );
}
