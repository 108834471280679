import React, { useEffect, useMemo, useState } from "react"
import { Button, Form, Image, Modal, Stack } from "react-bootstrap/esm"
import { FaArrowRight } from "react-icons/fa"
import { useTranslation } from "react-i18next"
import { Big6Math, formatBig6USDPrice, calcLiquidationPrice, PositionSide } from "@perennial/sdk"

import "../../../styles/modals.scss"
import { OrdersWarning, PercentsBox, PlaceOrderButton } from "../TradeForm/components"
import { FormattedOpenOrder, getOrderTypeFromOrder } from "../hooks"
import { useMarketContext } from "../../../contexts"
import { getOpenOrderLabel } from "../../../utils/positionUtils"
import { OrderExecutionDeposit, OrderTypes, OrderTypesLabels } from "../constants"
import { MarketMetadata } from "../../../constants/markets"
import { useMediaQuery } from "react-responsive"
import { stopLossPercents, takeProfitPercents } from "../TradeForm/components/TriggerOrders/constants"
import { useStopLossValidator, useTakeProfitValidators } from '../validatorHooks'
import { TokenIcon } from "../../common"
import { calcTriggerOrderPrice } from "../TradeForm/components/TriggerOrders/hooks"
import { useLivePriceContext } from "../../../contexts/livePriceContext"
import { isNumbersOnly } from "../../../utils/formUtils"
import { isNonNegativeNumber } from "../../../utils/utils"


export const UpdateOrder = ({
  showModal,
  order,
  isLimit,
  onHide,
}: {
  showModal: boolean,
  order: FormattedOpenOrder,
  isLimit: boolean,
  onHide: () => void  
}) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const { side, orderDelta, triggerPrice, triggerPriceUnformatted, market, marketAddress, nonce, type: orderTriggerComparison } = order
  const orderType = getOrderTypeFromOrder(order)
  const orderDirection = side === PositionSide.maker || side === PositionSide.none ? PositionSide.long : side
  const marketMetadata = MarketMetadata[market]
  const livePrices = useLivePriceContext()
  const [positiveChange, setPositiveChange] = useState(true)
  const [previousPrice, setPreviousPrice] = useState(0n)
  const { isMaker, snapshots2 } = useMarketContext()
  const userMarketSnapshot = snapshots2?.user?.[market]

  const [newAmount, setNewAmount] = useState(userMarketSnapshot
    ? Big6Math.toFloatString(userMarketSnapshot.nextMagnitude)
    : ""
  )
  const [newAmountBI, setNewAmountBI] = useState(userMarketSnapshot ? userMarketSnapshot.nextMagnitude : 0n)
  const [newTriggerPriceBI, setNewTriggerPriceBI] = useState(triggerPriceUnformatted)
  const [newTriggerPrice, setNewTriggerPrice] = useState(Big6Math.toFloatString(triggerPriceUnformatted))

  const { nextPosition, indexPrice, latestPrice, currentCollateral } = useMemo(() => {
    let nextPosition = 0n
    let nextNotional = 0n
    let indexPrice = 0n
    let latestPrice = 0n
    let currentCollateral = 0n
    const marketSnapshot = snapshots2?.market?.[market]
    const userMarketSnapshot = snapshots2?.user?.[market]
    
    if (marketSnapshot && userMarketSnapshot) {
      nextPosition = userMarketSnapshot.nextMagnitude
      nextNotional = userMarketSnapshot.nextNotional
      indexPrice = livePrices[market]?.price ?? marketSnapshot.global.latestPrice
      latestPrice = marketSnapshot.global.latestPrice
      currentCollateral = userMarketSnapshot.local.collateral ?? 0n
    }

    return {
      nextPosition,
      nextNotional,
      indexPrice,
      latestPrice,
      currentCollateral,
    }
  },
    [snapshots2, market, livePrices]
  )

  const oraclePrice = livePrices[market]?.price ?? latestPrice
  useEffect(() => {
    setPositiveChange(previousPrice < oraclePrice)
    setPreviousPrice(oraclePrice)
  },
    // eslint-disable-next-line
    [oraclePrice]
  )

  const liquidationPrices = calcLiquidationPrice({
    marketSnapshot: snapshots2?.market?.[market],
    collateral: currentCollateral,
    position: newAmountBI,
  })

  const stopLossValidators = useStopLossValidator({
    orderDirection,
    latestPrice,
    isLimit: false,
    liquidationPrice: orderDirection === PositionSide.long ? liquidationPrices.long : liquidationPrices.short
  })

  const takeProfitValidators = useTakeProfitValidators({
    orderDirection,
    latestPrice,
    isLimit: false,
  })

  const { orderError } = useMemo(() => {
    let orderError

    if (orderType === OrderTypes.stopLoss) {
      const minValidation = stopLossValidators.min(newTriggerPrice)
      if (!minValidation.isValid) {
        orderError = minValidation.error || ""
      }
    }
    if (orderType === OrderTypes.takeProfit) { 
      const minValidation = takeProfitValidators.min(newTriggerPrice)
      if (!minValidation.isValid) {
        orderError = minValidation.error || ""
      } 
    }
    if (!isNumbersOnly(newTriggerPrice)) {
      orderError = t("error.not-a-number")
    }
    
    if (orderError !== "") {
      setNewTriggerPriceBI(Big6Math.fromFloatString(newTriggerPrice))
    }

    return {
      orderError
    }
  },
    // eslint-disable-next-line
    [orderType, newTriggerPrice]
  )

  const { positionError } = useMemo(() => { 
    let positionError

    if (isNumbersOnly(newAmount)) {
      if (!isNonNegativeNumber(newAmount)) {
        positionError = t("error.amount-greater-than-zero")
      }
      if (nextPosition < Big6Math.fromFloatString(newAmount)) {
        positionError = t("error.amount-exceeds-position")
      }
      setNewAmountBI(Big6Math.fromFloatString(newAmount))
    } else {
      positionError = t("error.not-a-number")
    }

    return { positionError }
  },
    // eslint-disable-next-line
    [newAmount, nextPosition]
  )

  const orderLabel = getOpenOrderLabel({
    orderDelta: Big6Math.fromFloatString(orderDelta),
    comparison: orderTriggerComparison,
    orderDirection: side,
    isMaker,
  })

  const onPercentClick = (percent: string) => {
    const triggerPrices = calcTriggerOrderPrice({
      positionSize: newAmountBI,
      orderDirection,
      orderType,
      percent,
      latestPrice: isLimit && latestPrice ? latestPrice : indexPrice,
      collateral: currentCollateral,
    })

    // const percentFromIndex = Big6Math.div(triggerPrices.triggerPrice - indexPrice, indexPrice) * 100n
    // setNewTriggerPriceBI(triggerPrices.triggerPrice)
    setNewTriggerPrice(Big6Math.toFloatString(triggerPrices.triggerPrice))
  }

  return (
    <Modal
      show={showModal}
      onHide={onHide}
      className={"modal-edit-order"}
    >
      <Modal.Header closeButton>
        <h5 className="bold">{t("change-order", { orderType: OrderTypesLabels[orderLabel] })}</h5>
      </Modal.Header>
      <Modal.Body>
        <Stack direction="vertical" gap={3} className="order-form">
          <Stack direction="vertical" gap={1} className="order-position">
            <Stack direction="horizontal" className="position-data">
              <Stack direction="horizontal" gap={2} className="left">
                <Image src={marketMetadata.icon} height={35} width={35} />
                <Stack direction="vertical" gap={1} className="info">
                  <h6 className="title">
                    {marketMetadata.symbol}
                  </h6>
                  <span className="caption text-muted">
                    {side.toUpperCase()}
                  </span>
                </Stack>
              </Stack>
              <div className="vertical-divisor" style={{ height: "2.3rem" }} />
              <Stack
                direction="horizontal"
                gap={1}
                className="right"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: "67%"
                }}
              >
                <span
                  className={"price ".concat(positiveChange ? "text-green" : "text-red")}
                  style={{ textAlign: "center", fontSize: "1.4rem" }}
                >
                  {formatBig6USDPrice(oraclePrice)}
                </span>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="vertical" gap={2} className="order-trigger-form">
            <Stack gap={1}>
              <Stack direction="horizontal" className="space-between" gap={1}>
                <h6>{t("trigger-price")}</h6>
                <div>
                  <PercentsBox
                    percentValues={
                      orderType === OrderTypes.stopLoss
                        ? stopLossPercents
                        : takeProfitPercents
                    }
                    onPercentClick={onPercentClick}  
                  />
                </div>                
              </Stack>
              <Stack direction="horizontal" className="trigger-price-group">
                <Form.Control
                  id="edit-trigger-price"
                  placeholder="0"
                  value={newTriggerPrice}
                  type={isMobile ? "number" : "text"}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNewTriggerPrice(event.target.value)
                  }}
                />
                <TokenIcon name="usdc" size="normal" />
              </Stack>
              {orderError && (
                <span className="text-red medium">{orderError}</span>
              )}
            </Stack>
            <Stack>
              <Stack direction="horizontal" className="space-between">
                <h6>{t("order-amount")}</h6>
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    setNewAmountBI(nextPosition)
                    setNewAmount(Big6Math.toFloatString(nextPosition))
                  }}
                >
                  {t("set-max")}
                </Button>
              </Stack>
              <Stack direction="horizontal" className="trigger-amount-group">
                <Form.Control
                  id="edit-order-amount"
                  placeholder="0"
                  value={newAmount}
                  type={isMobile ? "number" : "text"}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNewAmount(event.target.value)
                  }}
                />
                <Image src={marketMetadata.icon} height={28} width={28} />
              </Stack>
              {positionError && (
                <span className="text-red medium">{positionError}</span>
              )}
            </Stack>  
          </Stack>
          <Stack direction="vertical" gap={1} className="order-trigger-changes">
            <Stack direction="horizontal" className="space-between">
              <span className="medium">{t("trigger-price")}</span>
              <Stack direction="horizontal" gap={1}>
                <span className="number medium text-muted">
                  {triggerPrice}
                </span>
                <FaArrowRight size={12} />
                <span className="number medium">
                  {newTriggerPrice}
                </span>
              </Stack>
            </Stack>
            <Stack direction="horizontal" className="space-between">
              <span className="medium">{t("order-amount")}</span>
              <Stack direction="horizontal" gap={1}>
                <span className="number medium text-muted">
                  {Big6Math.toUnsafeFloat(nextPosition).toFixed(4)} {marketMetadata.baseCurrency.toUpperCase()}
                </span>
                <FaArrowRight size={12} />
                <span className="number medium">
                  {Big6Math.toUnsafeFloat(newAmountBI).toFixed(4)} {marketMetadata.baseCurrency.toUpperCase()}
                </span>
              </Stack>
            </Stack>
            <Stack direction="horizontal" className="space-between">
              <span className="medium">{t("order-execution-deposit")}</span>
              <span className="medium number">${Big6Math.toFloatString(OrderExecutionDeposit)}</span>
            </Stack>
          </Stack>
          <OrdersWarning />
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <PlaceOrderButton
          label={t("update-order")}
          marketAddress={marketAddress}
          orderType={orderType}
          positionSide={side}
          collateralDelta={0n}
          positionAbs={newAmountBI}
          limitPrice={isLimit ? newTriggerPriceBI : undefined}
          takeProfitPrice={orderType === OrderTypes.takeProfit && !isLimit ? newTriggerPriceBI : undefined}
          stopLossPrice={orderType === OrderTypes.stopLoss && !isLimit ? newTriggerPriceBI : undefined}
          selectedLimitComparison={orderTriggerComparison}
          cancelOrderDetails={{
            market: marketAddress,
            nonce: nonce
          }}
          disabled={!!orderError || !!positionError}
          successMessage={t("notification.order-updated")}
          onSuccess={() => onHide()}
        />
      </Modal.Footer>
    </Modal>
  )
}