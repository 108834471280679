import React, { useState } from "react";
import { IPositionStatsContext } from "../states";
import { TokenType } from "../utils/types";


export const usePositionStatsContext = (): IPositionStatsContext => {
  const [market, setMarket] = useState<TokenType | null>(null);
  const [type, setType] = useState("LONG");
  const [exposure, setExposure] = useState(0);
  const [size, setSize] = useState(0);
  const [collateral, setCollateral] = useState(0);
  const [pnl, setPnl] = useState(0);

  const setCurrentMarket = React.useCallback((currentMarket: TokenType | null): void => {
    setMarket(currentMarket);
  }, []);
  const setCurrentType = React.useCallback((currentType: string): void => {
    setType(currentType);
  }, []);
  const setCurrentExposure = React.useCallback((currentExposure: number): void => {
    setExposure(currentExposure);
  }, []);
  const setCurrentSize = React.useCallback((currentSize: number): void => {
    setSize(currentSize);
  }, []);
  const setCurrentCollateral = React.useCallback((currentCollateral: number): void => {
    setCollateral(currentCollateral);
  }, []);
  const setCurrentPnl = React.useCallback((currentPnl: number): void => {
    setPnl(currentPnl);
  }, []);

  return {
    market,
    setCurrentMarket,
    type,
    setCurrentType,
    exposure,
    setCurrentExposure,
    size,
    setCurrentSize,
    collateral,
    setCurrentCollateral,
    pnl,
    setCurrentPnl,
  };
};
