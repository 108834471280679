import React, { useMemo, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import Table from "react-bootstrap/esm/Table";
import { NumericFormat } from "react-number-format";
import { formatBig18 } from '@perennial/sdk';

import { useMediaQuery } from "react-responsive";
import { useStakerStats } from "../../../hooks/governance";
import { useTranslation } from "react-i18next";
import { useStakerTransactions } from "../../../hooks/governance/tx";


const sixMonthCtxRewardAmount = 12654;

export const StakerStats = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 850px)" });
  const { onClaimRewards } = useStakerTransactions()
  const { data } = useStakerStats()
  const [writingTran, setWritingTran] = useState(false);

  const { apy, currentReward } = useMemo(() => {
    let apy = "0.0"

    if (data) {
      const totalStaked = formatBig18(data.totalSupply)
      const a = Math.round(((4 * sixMonthCtxRewardAmount) / parseFloat(totalStaked)) / 10);
      apy = a.toFixed(0).concat("%");
    }  
    
    return {
      currentReward:  data?.currentReward ?? 0n,
      apy,
    }
    
  }, [data])  

  const StakedValue = () => (
    <>
      <NumericFormat
        className="number"
        value={formatBig18(data?.currentStake)}
        displayType="text"
        thousandSeparator
        suffix=" CTX"
        decimalScale={2}
      />
    </>
  );

  const StakeReward = () => (
    <NumericFormat
      className="number"
      value={formatBig18(currentReward)}
      displayType="text"
      thousandSeparator
      suffix=" CTX"
      decimalScale={4}
    />
  );

  const claim = async () => {
    setWritingTran(true)
    await onClaimRewards()
    setWritingTran(false)
  }

  const ClaimButton = () => (
    <Button
      variant={isMobile ? "primary" : "outline-primary"}
      className="ml-4"
      onClick={() => claim()}
      disabled={writingTran || currentReward === 0n}
    >
      {writingTran ? t("claiming") : t("claim")}
    </Button>
  );

  const RenderMobile = () => (
    <Accordion className="stake-reward-mobile" defaultActiveKey={isMobile ? "1" : "0"}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <h2>{t("governance.stake-reward")}</h2>
        </Accordion.Header>
        <Accordion.Body>
          <div className="mobile-detail-container">
            <div className="mobile-item">
              <div className="value">
                <StakedValue />
              </div>
              <h6 className="title">{t("staked")}</h6>
            </div>
            <div className="mobile-item left">
              <div className="value">
                <StakeReward />
              </div>
              <h6 className="title">{t("governance.staked-reward")}</h6>
            </div>
            <div className="mobile-item">
              <div className="value">
                <b className="fire">{apy}</b>
              </div>
              <h6 className="title">APR</h6>
            </div>
            <div className="mobile-item left">
              <div className="value">
                <h6>{data ? data.currentPeriodEnds.toLocaleDateString() : " - " }</h6>
              </div>
              <h6 className="title">End Date</h6>
            </div>
          </div>
          <ClaimButton />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );

  return (
    <div className="mb-2 staker">
      {isMobile ? (
        <RenderMobile />
      ) : (
        <>
          <Table hover className="mt-2">
            <thead>
              <tr>
                <th>{t("staked")}</th>
                <th>{t("governance.staked-reward")}</th>
                <th>APR</th>
                <th className="end-date">End Date</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="number">
                  <StakedValue />
                </td>
                <td className="number">
                  <StakeReward />
                </td>
                <td>
                  <b className="fire">{apy}</b>
                </td>
                <td className="end-date">
                  {data ? data.currentPeriodEnds.toLocaleDateString() : " - "}
                </td>
                <td align="right">
                  <ClaimButton />
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
};
