import React from "react"
import { Button, Stack } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { FaExclamationTriangle } from "react-icons/fa"


export const OrdersWarning = () => {
  const { t } = useTranslation()
  return (
    <Stack className="warning-orders" direction="horizontal" gap={3}>
      <FaExclamationTriangle size={30} fill="#eb7413" />
      <p>
        {t("info-msg.limit-orders")}
      </p>
    </Stack>
  )
}


export const PercentsBox = ({ percentValues, onPercentClick }: { percentValues: string[], onPercentClick: (percent: string) => void }) => (
  <Stack direction="horizontal" gap={2}>
    {percentValues.map((value, index) => (
      <Button key={index} variant="outline" className="btn-percents" onClick={() => onPercentClick(value)}>
        {value.concat("%")}
      </Button>
    ))}
  </Stack>
)
