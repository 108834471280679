import React, { useContext } from "react"
import { Modal, Image } from "react-bootstrap/esm"
import { NumericFormat } from "react-number-format"
import { TwitterShareButton, TwitterIcon } from "react-share"
import { useTranslation } from "react-i18next"
import cryptexImg from "../../../assets/cryptexv2.png"
import { positionStatsContext } from "../../states"
import { TokenIcon } from "../../../components/common"


type props = {
  showModal: boolean;
  onHide: () => void;
};

export const SharePosition = ({ showModal, onHide }: props) => {
  const { t } = useTranslation()
  const positionStats = useContext(positionStatsContext);
  
  const shareToXMsg = (): string => {
    const msg = t("share-msg-1", { value: positionStats.pnl.toFixed(4) })
    const msg2 = t("share-msg-2")

    return msg.concat(msg2);
  };

  return (
    <Modal show={showModal} onHide={onHide} className="modal-share-position">
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="image-container">
          <Image className="share-image" src={cryptexImg} alt="Cryptex V2" />
        </div>
        <div className="position-box">
          <div className="position-box-header">
            <div className="market">
              <TokenIcon name={positionStats.market?.symbol || ""} size="normal" />
              <div className="market-info">
                <div className="postion-direction">
                  <h6>{positionStats.type} {t("position")}</h6>
                </div>
                <span className="title">{positionStats.market?.description}</span>
              </div>
            </div>
          </div>
          <div className="position-box-body">
            <div className="box-item">
              <NumericFormat
                className="balance"
                value={positionStats.collateral.toFixed(4)}
                displayType="text"
                thousandSeparator=","
                decimalScale={4}
                prefix="$"
              />
              <h6 className="margin-right">{t("collateral")}</h6>
            </div>
            <div className="box-item algin-right">
              <NumericFormat
                className="number green"
                value={positionStats.pnl.toFixed(4)}
                displayType="text"
                thousandSeparator=","
                decimalScale={4}
                prefix="$"
              />
              <h6 className="margin-right">{t("total-profit")}</h6>
            </div>
          </div>
        </div>
        <div className="btn-container">
          <TwitterShareButton
            url="https://cryptex.finance"
            title={shareToXMsg()}
          >
            <TwitterIcon
              size={30}
              round
              bgStyle={{ fill: "#0a0a0a" }}
            />
            {t("share-this-win")}
          </TwitterShareButton>
        </div>  
      </Modal.Body>
    </Modal>    
  );  
}