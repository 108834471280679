
import React from "react"
import { Col, Image, Row, Stack } from "react-bootstrap";
import ReactGA from "react-ga4";
import { useMediaQuery } from 'react-responsive'

import meemLogo from '../../assets/icons/meem.png'
import tcapLogo from '../../assets/tcap-coin.png'
import { CrypdexProvider } from "../../contexts/crypdexContext";


const CrypdexPage = () => {
  ReactGA.send({ hitType: "pageview", page: "/indexes", title: "Indexes" });
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })

  return (
    <CrypdexProvider>
      <Stack
        direction="horizontal"
        className={`align-items-center justify-content-center pt-0 pb-5 mb-2 w-100 h-100 ${!isMobile ? "px-5" : "px-2"}`}
      >
        <Stack
          direction="vertical"
          className="align-self-center p-3 h-auto w-50"
          style={{
            backgroundColor: "0d0d0e",
            border: "0.5px solid rgb(76, 75, 84)",
            borderRadius: "5px",
            margin: !isMobile ? "-10rem 20% 0rem 20%" : "-18rem 0rem 0rem 0rem",
          }}
        >
          <Row className="px-3 mb-1">
            <span className="text-muted">Indexes</span>
          </Row>
          <Stack direction="vertical" className="p-1" gap={2}>
            <Col
              lg={12}
              className={`d-flex px-3 ${!isMobile ? "flex-row py-2" : "flex-column py-3"}`}
              style={{
                backgroundColor: "#0E0E19",
                borderRadius: "5px",
              }}
            >
              <Col lg={6} sm={12}>
                <Stack direction="horizontal" gap={2}>
                  <Image src={tcapLogo} alt="meem" width={42} height={42} />
                  <Stack direction="vertical" gap={1}>
                    <h6>TCAP 2.0</h6>
                    <span style={{ color: "#bdbbcf" }}>
                      Cryptex Total Crypto Market Cap Index
                    </span>
                  </Stack>
                </Stack>
              </Col>
              <Col
                lg={6}
                sm={12}
                className={`d-flex align-items-center ${!isMobile ? "justify-content-end" : "w-100 justify-content-center mt-2"}`}
              >
                <span
                  className="text-purple"
                  style={{ fontSize: "1.2rem" }}
                >
                  Coming Soon
                </span>
              </Col>
            </Col>
            <Col
              lg={12}
              className={`d-flex px-3 ${!isMobile ? "flex-row py-2" : "flex-column py-3"}`}
              style={{
                backgroundColor: "#1e1e30",
                borderRadius: "5px",
              }}
            >
              <Col lg={6} sm={12}>
                <Stack direction="horizontal" gap={2}>
                  <Image src={meemLogo} alt="meem" width={42} height={42} />
                  <Stack direction="vertical" gap={1}>
                    <h6>MEEM</h6>
                    <span style={{ color: "#bdbbcf" }}>
                      Cryptex 1:1 Meme Coin Index
                    </span>
                  </Stack>
                </Stack>
              </Col>
              <Col
                lg={6}
                sm={12}
                className={`d-flex align-items-center ${!isMobile ? "justify-content-end" : "w-100 justify-content-center mt-2"}`}
              >
                <span
                  className="text-purple"
                  style={{ fontSize: "1.2rem" }}
                >
                  Coming Soon
                </span>
              </Col>
            </Col>
          </Stack>
        </Stack>
      </Stack>
    </CrypdexProvider>
  )
};

export default CrypdexPage;
