import { GetContractReturnType, WalletClient, createPublicClient, getContract, http } from 'viem'
import { usePerennialSDKContext } from '../contexts/perennialSdkContext'
import { mainnet } from 'viem/chains'

import { CtxTokenAddresses, SeasonalMerkleTreeAddresses } from '../constants/contracts'
import { DelegatorFactoryAddress } from '../constants/governance'
import { usePerpetualsChainId, useRPCProviderUrl } from '../hooks/network'
import { useCryptexProductsContext } from '../contexts'
import { Ctx } from '../abi/Ctx.abi'
import { DelegatorFactory } from '../abi/DelegatorFactory.abi'
import { SeasonMerkleClaim } from '../abi/SeasonalMerkleClaim.abi'
import { chainIdToChainMap } from '../constants/network'


export const useDSU = () => {
  const sdk = usePerennialSDKContext()
  return sdk.contracts.getDSUContract()
}

export const useUSDC = () => {
  const sdk = usePerennialSDKContext()
  return sdk.contracts.getUSDCContract()
}

export const useMultiInvoker2 = () => {
  const sdk = usePerennialSDKContext()
  return sdk.contracts.getMultiInvokerContract()
}

export const useMarketFactory = () => {
  const sdk = usePerennialSDKContext()
  return sdk.contracts.getMarketFactoryContract()
}

export const useVaultFactory = () => {
  const sdk = usePerennialSDKContext()
  return sdk.contracts.getVaultFactoryContract()
}

export const usePythFactory = () => {
  const sdk = usePerennialSDKContext()
  return sdk.contracts.getPythFactoryContract()
}

export const useSeasonalMerkleClaim = (signer?: WalletClient) => {
  const chainId = usePerpetualsChainId()
  const rpcUrl = useRPCProviderUrl()
  const publicClient = createPublicClient({ chain: chainIdToChainMap[chainId], transport: http(rpcUrl) })

  const contract = getContract({
    address: SeasonalMerkleTreeAddresses[chainId],
    abi: SeasonMerkleClaim,
    client: signer ? { public: publicClient, wallet: signer } : publicClient,
  })

  return signer
    ? (contract as GetContractReturnType<
        typeof SeasonMerkleClaim,
        typeof signer,
        (typeof SeasonalMerkleTreeAddresses)[typeof chainId]
      >)
    : (contract as GetContractReturnType<
        typeof SeasonMerkleClaim,
        typeof publicClient,
        (typeof SeasonalMerkleTreeAddresses)[typeof chainId]
      >)
}

export const useCtx = (signer?: WalletClient) => {
  const chainId = mainnet.id
  const chain = chainIdToChainMap[chainId]
  const rpcUrl = useRPCProviderUrl()
  const publicClient = createPublicClient({
    batch: {
      multicall: true,
    },
    chain, transport: http(rpcUrl)
  })

  return getContract({
    address: CtxTokenAddresses[chainId],
    abi: Ctx,
    client: signer ? { public: publicClient, wallet: signer } : publicClient,
  })
}

export const useDelegatorFactory = (signer?: WalletClient) => {
  const products = useCryptexProductsContext()
  const chain = products.getDefaultChain()
  const rpcUrl = useRPCProviderUrl()
  const publicClient = createPublicClient({
    batch: {
      multicall: true,
    },
    chain,
    transport: http(rpcUrl)
  })

  return getContract({
    address: DelegatorFactoryAddress,
    abi: DelegatorFactory,
    client: signer ? { public: publicClient, wallet: signer } : publicClient,
  })
}