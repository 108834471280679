import React, { useState, useEffect } from "react"
import { Button, Dropdown, Spinner, Table } from "react-bootstrap/esm"
import { NumericFormat } from "react-number-format"
import { useMediaQuery } from "react-responsive"
import { useTranslation } from "react-i18next"
import { ethers } from "ethers"
import { getPricesAtTrade, useActiveProvider, useUserTradesHistory } from "../../hooks"
import { ACTION_TYPES, MARKETS } from "../../utils/constants"
import { capitalize } from "../../utils/utils"
import { TradesHistoryType } from "../../utils/types"
import { TokenIcon } from "../../../components/common"


type props = {
  tradeResp: any;
}

export const UserActiviy = ({ tradeResp }: props) => {
  const { t } = useTranslation()
  const isMobileOrTablet = useMediaQuery({ query: "(max-width: 850px)" });
  const itemsPerPage = isMobileOrTablet ? 3 : 10;
  const [tradesSS, setTradesSS] = useState(new Array<TradesHistoryType>());
  const { multiCallProvider } = useActiveProvider();
  const [loadingPrices, setLoadingPrices] = useState(true);
  const [lastItemIndex, setLastItemIndex] = useState(0);

  useEffect(() => {
    const loadPrices = async () => {
      const initialIndex = lastItemIndex;
      const endIndex = lastItemIndex + itemsPerPage > tradeResp.trades.length
        ? tradeResp.trades.length
        : lastItemIndex + itemsPerPage;

      const currentTrades = tradesSS;
      const trades = await getPricesAtTrade(multiCallProvider, tradeResp.trades.slice(initialIndex, endIndex));
      if (currentTrades.length === 0) {
        setTradesSS(trades);
      } else {
        currentTrades.push(...trades);
        setTradesSS(currentTrades);
      }
      setLastItemIndex(endIndex);
    };

    if (loadingPrices && tradeResp && tradeResp.success) {
      loadPrices();
      setLoadingPrices(false);
    } 
  },
    // eslint-disable-next-line
    [tradeResp, loadingPrices]
  );

  const formatActionDate = (date: Date): string => {
    if (!isMobileOrTablet) {
      const options = { month: "short", day: "numeric", year: "numeric" };
      // @ts-ignore
      return date.toLocaleDateString("en-US", options);
    } else {
      const options = { month: "short", day: "numeric" };
      // @ts-ignore
      const formatted = date.toLocaleDateString("en-US", options);
      const timeParts = date.toTimeString().slice(0, 15).split(" ");
            
      return formatted.concat(", ").concat(timeParts[0].slice(0,5)).concat(" ").concat(timeParts[1]);
    }
  };

  const actionClassname = (action: string) => {
    if (action === ACTION_TYPES.deposit || action === ACTION_TYPES.takeOpened || action === ACTION_TYPES.makeOpened) {
      return "text-green";
    } else if (action === ACTION_TYPES.liquidated) {
      return "text-red-dark";
    }
    return "text-red";
  };

  const actionName = (action: string) => {
    if (action === ACTION_TYPES.takeOpened) {
      return t("open-position")
    } else if (action === ACTION_TYPES.makeOpened) {
      return t("open-position")
    } else if (action === ACTION_TYPES.takeClosed) {
      return t("close-position")
    } else if (action === ACTION_TYPES.makeClosed) { 
      return t("close-position")
    }
    return capitalize(action.toLowerCase());
  }

  const isDepositOrWithdrawal = (action: string): boolean => 
    action === ACTION_TYPES.deposit || action === ACTION_TYPES.withdraw;

  const onLoadMoreClick = () => {
    setLoadingPrices(true);    
  };
  
  const RenderAction = (trade: TradesHistoryType, index: number) => {
    return (
      <tr key={"history-".concat(index.toString())} className={index < lastItemIndex ? "show" : "hide"}>
        <td>
          <div className="market">
            <TokenIcon name={trade.marketInfo.symbol} size="normal" />
            <div className="market-info">
              <h6>
                {trade.productType}
              </h6>
              <span className="title">{trade.marketInfo.description.split("/")[1]}</span>
            </div>
          </div>
        </td>
        <td>
          <div className="flex-column">
            <span className="balance">
              {formatActionDate(trade.ts)}, {trade.ts.toTimeString().slice(0, 15)}
            </span>
          </div>
        </td>
        <td>
          <div className="flex-column">
            <span className={actionClassname(trade.action)}>
              {actionName(trade.action)}
            </span>
            {(trade.action === ACTION_TYPES.makeOpened || trade.action === ACTION_TYPES.makeClosed) && (
              <span className="info-tag">{t("pro-interface")}</span>
            )}  
          </div>  
        </td>
        <td className="right">
          <div className="flex-column">
            <NumericFormat
              className=""
              value={ethers.formatEther(trade.amount)}
              displayType="text"
              thousandSeparator=","
              decimalScale={4}
              prefix={isDepositOrWithdrawal(trade.action) ? "$" : ""}
              suffix={isDepositOrWithdrawal(trade.action) ? "" : " ".concat(trade.marketInfo.symbol)}
            />
            {!isDepositOrWithdrawal(trade.action) && (
              <div className="flex-row">
                <span className="number gray small">{t("price")}:</span>
                <NumericFormat
                  className="number gray small"
                  value={trade.tokenPrice}
                  displayType="text"
                  thousandSeparator=","
                  decimalScale={
                    trade.marketInfo.key === "pepe" ? 9 : 4
                  }
                  prefix="$"
                />
              </div>
            )}            
          </div>
        </td>
        <td className="right">
          <a
            className="btn-outline-primary"
            href={"https://arbiscan.io/tx/".concat(trade.transactionHash)}
            target="_blank"
            rel="noreferrer"
          >
            {t("transaction")}
          </a>
        </td>
      </tr>
    )
  }

  const RenderActionMobile = (trade: TradesHistoryType, index: number) => {
    const className = "action-box ".concat(index < lastItemIndex ? "show" : "hide");

    return (
      <div key={"history-".concat(index.toString())} className={className}>
        <div className="box-header">
          <div className="market">
            <TokenIcon name={trade.marketInfo.symbol} size="normal" />
            <div className="market-info">
              <div className="postion-direction">
                <h6>{trade.productType}</h6>
              </div>
              <span className="title">{trade.marketInfo.description}</span>
            </div>            
          </div>
        </div>
        <div className="box-body">
          <div className="position-item">
            <div className="left">
              <span className={actionClassname(trade.action)}>
                {actionName(trade.action)}
              </span>
              <h6 className="margin-right">{t("action")}</h6>
            </div>
            <div className="right">
              <NumericFormat
                className="balance"
                value={ethers.formatEther(trade.amount)}
                displayType="text"
                thousandSeparator=","
                prefix={isDepositOrWithdrawal(trade.action) ? "$" : ""}
                decimalScale={4}
              />
              <h6 className="margin-right">
                {!isDepositOrWithdrawal(trade.action) && trade.marketInfo.symbol} {t("amount")}
              </h6>
            </div>
          </div>
          <div className="position-item">
            <div className="left">
              <NumericFormat
                className="balance"
                value={trade.tokenPrice}
                displayType="text"
                thousandSeparator=","
                decimalScale={
                  trade.marketInfo.key === "pepe" ? 9 : 4
                }
                prefix="$"
              />
              <h6 className="margin-right">{trade.marketInfo.symbol} {t("price")}</h6>
            </div>
            <div className="right">
              <span className="action-date">
                {formatActionDate(trade.ts)}
              </span>
              <h6 className="margin-right">{t("date")}</h6>
            </div>
          </div>
        </div>
        <div className="box-footer">
          <a
            className="btn-outline-primary"
            href={"https://arbiscan.io/tx/".concat(trade.transactionHash)}
            target="_blank"
            rel="noreferrer"
          >
            {t("go-to-transaction")}
          </a>        
        </div>
      </div>
    );
  };

  return (
    <>
      {!isMobileOrTablet ? (
        <Table striped hover variant="dark">
          <thead>
            <tr>
              <th>{t("market")}</th>
              <th>{t("date")}</th>
              <th>{t("action")}</th>
              <th className="right">{t("amount")}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {tradesSS.map((trade, index) => {
              return RenderAction(trade, index);
            })}
          </tbody>
        </Table>
      ) : (
        <>
          {tradesSS.map((trade, index) => {
            return RenderActionMobile(trade, index);
          })}
        </>    
      )}
      {loadingPrices && (
        <div className="spinner-container">
          <Spinner className="spinner small " animation="border" />
        </div>
      )}
      {!loadingPrices && (
        <div className="history-footer">
          <Button
            onClick={() => onLoadMoreClick()}
            disabled={tradeResp && lastItemIndex >= tradeResp.trades.length}
          >
            {t("load-more")}
          </Button>
        </div>
      )}  
    </>  
  );
};


export const UserActiviyWrapper = ({ positionType }: { positionType: string }) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const [currentMarket, setCurrentMarket] = useState("all");
  const [currentType, setCurrentType] = useState("all");
  const { data: tradeResp, isLoading, isValidating } = useUserTradesHistory(currentMarket, currentType);

  return (
    <div>
      <div className="filters-container">
        <div className="left">
          <div className="filter-item">
            <h6 className="filter-title">{t("market")}{!isMobile && ":"}</h6>
            <Dropdown
              className="markets-filter"
              onSelect={(eventKey) => {
                if (eventKey) {
                  setCurrentMarket(eventKey);
                } else {
                  setCurrentMarket("all");
                }
              }}
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <div className="dropdown-toggle-container">
                  <TokenIcon name={currentMarket} size="small" />
                  <div className="market-desc">
                    <h6 className="title">{currentMarket === "all" ? t("all") : MARKETS[currentMarket].market.symbol}</h6>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item key="all" eventKey="all">
                  {t("all")}
                </Dropdown.Item>
                {Object.values(MARKETS).slice(0,2).map((market) => (
                  <Dropdown.Item key={market.market.key} eventKey={market.market.key}>
                    <TokenIcon name={market.market.symbol} size="small" />
                    {market.market.description.split("/")[0]}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="filter-item">
            <h6 className="filter-title">{t("type")}{!isMobile && ":"}</h6>
            <Dropdown
              className="markets-type-filter"
              onSelect={(eventKey) => {
                if (eventKey) {
                  setCurrentType(eventKey);
                } else {
                  setCurrentType("all");
                }
              }}
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <div className="dropdown-toggle-container">
                  <div className="market-desc">
                    <h6 className="title">{capitalize(currentType)}</h6>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item key="all" eventKey="all">
                  {t("all")}
                </Dropdown.Item>
                <Dropdown.Item key="long" eventKey="long">
                  {t("long")}
                </Dropdown.Item>
                <Dropdown.Item key="short" eventKey="short">
                  {t("short")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="right">          
        </div>
      </div>
      {(isLoading || isValidating) && (
        <div className="spinner-container">
          <Spinner className="spinner small " animation="border" />
        </div>
      )}
      {!isLoading && !isValidating && (
        <UserActiviy tradeResp={tradeResp} />
      )}
    </div>
  );
};
