import {
  SupportedMarket,
  chainAssetsWithAddress,
  last7dBounds,
} from '@perennial/sdk'

import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query'

import { useAddress, usePerpetualsChainId } from '../network'
import { useMarketSnapshots2 } from './chain'
import { usePerennialSDKContext } from '../../contexts/perennialSdkContext'


export const useActivePositionsMarketPnls = (markToMarket = true) => {
  const chainId = usePerpetualsChainId()
  const { data: marketSnapshots, isLoading: marketSnapshotsLoading } = useMarketSnapshots2()
  const { address } = useAddress()
  const sdk = usePerennialSDKContext()
  const marketOracleVersions = Object.entries(marketSnapshots?.market ?? {}).map(([market, snapshot]) => ({
    market,
    version: snapshot?.currentOracleVersion.toString(),
  }))

  return useQuery({
    queryKey: ['activePositionsMarketPnls', chainId, address, marketOracleVersions, markToMarket],
    enabled: !!address && !marketSnapshotsLoading,
    placeholderData: keepPreviousData,
    queryFn: async () => {
      if (!address || !marketSnapshots) return

      return sdk.markets.read.activePositionsPnl({
        marketSnapshots,
        address,
        markToMarket,
      })
    },
  })
}


export type ActiveSubPositionHistory = NonNullable<
  NonNullable<Awaited<ReturnType<typeof useActiveSubPositionHistory>['data']>>['pages'][number]
>['changes']

const ActivePositionHistoryPageSize = 100
export const useActiveSubPositionHistory = (asset: SupportedMarket, enabled: boolean = true) => {
  const chainId = usePerpetualsChainId()
  const { data: marketSnapshots, isLoading: marketSnapshotsLoading } = useMarketSnapshots2()
  const { address } = useAddress()
  const sdk = usePerennialSDKContext()
  const activePositions = useActivePositionsMarketPnls()

  return useInfiniteQuery({
    queryKey: [
      'activeSubPositionHistory',
      chainId,
      asset,
      address,
      activePositions.data?.[asset]?.positionId.toString(),
    ],
    enabled:
      !!address &&
      !marketSnapshotsLoading &&
      !!marketSnapshots?.user?.[asset] &&
      enabled &&
      !!activePositions.data?.[asset],
    queryFn: async ({ pageParam = 0 }) => {
      const activePositionPnl = activePositions.data?.[asset]
      if (!address || !activePositionPnl) return
      const changes = await sdk.markets.read.activePositionHistory({
        market: asset,
        address,
        positionId: activePositionPnl.positionId,
        first: ActivePositionHistoryPageSize,
        skip: pageParam * ActivePositionHistoryPageSize,
      })

      return {
        changes,
        nextPageParam: changes.length === ActivePositionHistoryPageSize ? pageParam + 1 : undefined,
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage?.nextPageParam ?? 0,
  })
}

const HistoricalPositionsPageSize = 10
export type HistoricalPosition = NonNullable<
  NonNullable<ReturnType<typeof useHistoricalPositions>['data']>['pages'][number]
>['positions'][number]
export const useHistoricalPositions = (maker: boolean) => {
  const chainId = usePerpetualsChainId()
  const markets = chainAssetsWithAddress(chainId)
  const { address } = useAddress()
  const sdk = usePerennialSDKContext()

  return useInfiniteQuery({
    queryKey: ['historicalPositions', chainId, maker ? 'maker' : 'taker', address],
    enabled: !!address && !!markets.length,
    queryFn: async ({ pageParam = 0 }) => {
      if (!address || !markets.length) return
      const positions = await sdk.markets.read.historicalPositions({
        address,
        markets: markets.map(({ market }) => market),
        first: HistoricalPositionsPageSize,
        skip: pageParam * HistoricalPositionsPageSize,
        maker,
      })
      return {
        positions,
        nextPageParam: positions.length === HistoricalPositionsPageSize ? pageParam + 1 : undefined,
      }
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => lastPage?.nextPageParam,
  })
}

const HistoricalSubPositionsPageSize = 100
export const useHistoricalSubPositions = ({
  market,
  positionId,
  enabled,
}: {
  market: SupportedMarket
  positionId: bigint
  enabled?: boolean
}) => {
  const chainId = usePerpetualsChainId()
  const { address } = useAddress()
  const sdk = usePerennialSDKContext()

  return useInfiniteQuery({
    queryKey: ['historicalSubPositions', chainId, market, positionId.toString(), address],
    enabled: !!address && enabled,
    queryFn: async ({ pageParam = 0 }) => {
      if (!address) return
      const changes = await sdk.markets.read.subPositions({
        address,
        market,
        positionId,
        first: HistoricalSubPositionsPageSize,
        skip: pageParam * HistoricalSubPositionsPageSize,
      })

      return { changes, nextPageParam: changes.length === HistoricalSubPositionsPageSize ? pageParam + 1 : undefined }
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => lastPage?.nextPageParam,
  })
}

const OpenOrdersPageSize = 1000
export const useOpenOrders = (isMaker?: boolean) => {
  const chainId = usePerpetualsChainId()
  const { address } = useAddress()
  const markets = chainAssetsWithAddress(chainId)
  const sdk = usePerennialSDKContext()

  return useInfiniteQuery({
    queryKey: ['openOrders', chainId, address, isMaker ? 'maker' : 'taker'],
    enabled: !!address && !!markets.length,
    queryFn: async ({ pageParam = 0 }) => {
      if (!address || !markets.length) return

      const sdkRes = await sdk.markets.read.openOrders({
        address,
        markets: markets.map(({ market }) => market),
        first: OpenOrdersPageSize,
        skip: pageParam * OpenOrdersPageSize,
        isMaker,
      })

      return {
        orders: sdkRes,
        nextPageParam: sdkRes.length === OpenOrdersPageSize ? pageParam + 1 : undefined,
      }
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => lastPage?.nextPageParam,
  })
}

export const useMarkets24hrData = () => {
  const chainId = usePerpetualsChainId()
  const sdk = usePerennialSDKContext()

  return useQuery({
    queryKey: ['markets24hData', chainId],
    queryFn: async () => {
      return sdk.markets.read.markets24hrData()
    },
  })
}

export const useMarket7dData = () => {
  const chainId = usePerpetualsChainId()
  const sdk = usePerennialSDKContext()

  return useQuery({
    queryKey: ['market7dData', chainId],
    queryFn: async () => {
      const { from, to } = last7dBounds()
      return sdk.markets.read.marketsHistoricalData({
        fromTs: BigInt(from),
        toTs: BigInt(to),
      })
    },
  })
}

