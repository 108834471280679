import { mainnet } from "viem/chains";
import { normalize } from 'viem/ens'
import { getPublicClient } from "../constants/network";


export const shortenAddress = (address: string) =>
  `${address.substring(0, 6)}...${address.substring(address.length - 4)}`

export const getAddressFromEns = async (ensName: string) => { 
  const publicClient = getPublicClient(mainnet.id);

  return publicClient.getEnsAddress({
    name: normalize(ensName),
  })
}

export const getEnsAvatar = (ensName: string) => {
  const publicClient = getPublicClient(mainnet.id);

  return publicClient.getEnsAvatar({
    name: normalize(ensName),
  })
}

export const isValidEns = async (ensName: string) => { 
  const address = await getAddressFromEns(ensName);
  return address !== null;
}
