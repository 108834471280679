import React, { useEffect, useMemo, useRef } from "react"
import { useMarketContext } from "../../../contexts"
import { ReactComponent as PythIcon } from "../../../assets/pyth.svg"


// @ts-ignore
let tvScriptLoadingPromise;

export const TradingViewWidget = () => {
  const onLoadScriptRef = useRef()
  const market = useMarketContext()
  
  const tvSymbol = useMemo(() => {
    if (market && market.marketMetadata) {
      return market.marketMetadata.tvPythSymbol
    }

    return "PYTH:ETHUSD"
  }, [market])

  useEffect(
    () => {
      const load = () => {
        // @ts-ignore
        onLoadScriptRef.current = createWidget;
        // @ts-ignore
        if (!tvScriptLoadingPromise) {
          tvScriptLoadingPromise = new Promise((resolve) => {
            const script = document.createElement("script");
            script.id = "tradingview-widget-loading-script";
            script.src = "https://s3.tradingview.com/tv.js";
            script.type = "text/javascript";
            script.onload = resolve;

            document.head.appendChild(script);
          });
        }
        // @ts-ignore
        tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

        // @ts-ignore
        return () => onLoadScriptRef.current = null;

        function createWidget() {
          if (document.getElementById("tradingview_14a8d") && "TradingView" in window) {
            // @ts-ignore
            new window.TradingView.widget({
              autosize: true,
              symbol: tvSymbol,
              interval: "1",
              timezone: "Etc/UTC",
              theme: "dark",
              style: "1",
              locale: "en",
              toolbar_bg: "#f1f3f6",
              enable_publishing: false,
              allow_symbol_change: true,
              container_id: "tradingview_14a8d"
            });
          }
        }
      }
      load();
    },
    // eslint-disable-next-line
    [market.marketMetadata]
  );

  return (
    <div className="tradingview-widget-container">      
      <PythIcon width={80} height={80} className="pyth-icon" />
      <div id="tradingview_14a8d" />
    </div>
  );
}
