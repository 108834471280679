import React, { useMemo, useState } from "react"
import { Button, Image, Modal, Stack } from "react-bootstrap/esm"
import { useTranslation } from "react-i18next"
import { Big6Math, calcLeverage, formatBig6, PositionSide, SupportedAsset, TriggerComparison } from "@perennial/sdk"
import "../../../styles/modals.scss";

import { NumericFormat } from "react-number-format"
import { FaArrowRight } from "react-icons/fa"
import TradeForm from "../TradeForm"
import { ModifyPositionButton, PlaceOrderButton } from "../TradeForm/components/ActionButtons"
import { ApproveButton } from "../../common/TxButtons";
import { useMarketContext } from "../../../contexts/marketContext"
import { MarketMetadata } from "../../../constants/markets"
import { getAddress, zeroAddress } from "viem";
import { useBalances } from "../../../hooks/wallet";
import { needsApproval } from "../utils";
import { TokenIcon } from "../../common";
import { FormattedOpenOrder } from "../hooks";
import { OrderTypes } from "../constants";
import { OrdersWarning } from "../TradeForm/components";
import { useSettlementFees } from "../../../hooks/markets2";


type props = {
  showModal: boolean
  isMaker: boolean
  isLimitOrder?: boolean
  order?: FormattedOpenOrder
  onHide: () => void
}

export const UpdatePosition = ({
  showModal,
  isMaker,
  isLimitOrder,
  order,
  onHide,
}: props) => {
  const { t } = useTranslation()
  const { setOrderDirection, snapshots2, selectedMarket, selectedMakerMarket } = useMarketContext()
  const { data: balances, refetch: refetchBalances } = useBalances()
  const { data: settlementFees } = useSettlementFees()
  const currentMarket = !isMaker ? selectedMarket : selectedMakerMarket
  const assetMetadata = MarketMetadata[currentMarket as SupportedAsset]
  const market = snapshots2?.market?.[currentMarket]
  const userPosition = snapshots2 && snapshots2.user && snapshots2.user[currentMarket as SupportedAsset]

  const { marketAddress, settlementFee } = useMemo(() => {
    let marketAddress = getAddress(zeroAddress)
    let settlementFee

    if (market) {
      marketAddress = market.marketAddress
    }
    if (settlementFees) { 
      settlementFee = settlementFees[currentMarket].totalCost
    }

    return {
      marketAddress,
      settlementFee
    }
  }, [market, currentMarket, settlementFees])

  const { orderSide, currentCollateral, currentPosition, currentLeverage } = useMemo(() => {
    let currentCollateral = "0"
    let currentPosition = "0"
    let currentLeverage = "0"
    let orderSide = PositionSide.long

    if (userPosition) {
      currentCollateral = Big6Math.toFloatString(userPosition.local.collateral)
      currentPosition = Big6Math.toFloatString(userPosition.nextMagnitude)
      currentLeverage = parseFloat(Big6Math.toFloatString(userPosition.nextLeverage)).toFixed(1)

      if (userPosition.nextSide !== PositionSide.none) {
        orderSide = userPosition.nextSide 
      }
    }

    return {
      orderSide,
      currentCollateral,
      currentPosition,
      currentLeverage,
    }
  }, [userPosition])

  const { orderNonce, limitOrderSide, orderSize, triggerPrice, orderComparisson, orderLeverage } = useMemo(() => {
    let orderNonce = 0n
    let orderSize = "0"
    let triggerPrice = "0"
    let limitOrderSide = PositionSide.long
    let orderComparisson = TriggerComparison.lte
    let orderLeverage = "0"

    if (order) {
      orderNonce = order.nonce
      orderSize = order.orderDelta
      triggerPrice = order.triggerPrice
      limitOrderSide = order.side
      orderComparisson = order.type
      orderLeverage = Big6Math.toFloatString(
        calcLeverage( order.triggerPriceUnformatted, Big6Math.fromFloatString(orderSize), Big6Math.fromFloatString(currentCollateral))
      )
    }

    return {
      orderNonce,
      limitOrderSide,
      orderSize,
      triggerPrice,
      orderComparisson,
      orderLeverage,
    }
  }, [order, currentCollateral])

  const [canModifyPosition, setCanModifyPosition] = useState(true)
  const [newCollateral, setNewCollateral] = useState("0")
  const [newPosition, setNewPosition] = useState("0")
  const [newLeverage, setNewLeverage] = useState("0")
  const [newLimitOrderPrice, setNewLimitOrderPrice] = useState("0")
  const [stopLossPrice, setStopLossPrice] = useState("0")
  const [takeProfitPrice, setTakeProfitPrice] = useState("0")
  const [interfaceFee, setInterfaceFee] = useState(0n)
  const [positionDelta, setPositionDelta] = useState({
    collateralDelta: 0n,
    positionDelta: 0n
  })
  const [showConfirm, setShowConfirm] = useState(false);

  const setValues = (
    canMofifyPosition: boolean,
    positionDelta: { collateralDelta: bigint, positionDelta: bigint },
    interfaceFee: bigint,
    newCollateral: string,
    newPosition: string,
    newLeverage: string,
    newLimitPrice: string,
    slPrice: string,
    tpPrice: string,
  ) => {
    setCanModifyPosition(canMofifyPosition)
    setPositionDelta(positionDelta)
    setInterfaceFee(interfaceFee)
    setNewCollateral(newCollateral)
    setNewPosition(newPosition)
    setNewLeverage(parseFloat(newLeverage).toFixed(1))
    setNewLimitOrderPrice(newLimitPrice)
    setStopLossPrice(slPrice)
    setTakeProfitPrice(tpPrice)
  }

  const { usdcAllowance } = useMemo(() => {
    return {
      usdcAllowance: balances?.usdcAllowance || 0n
    }
  }, [balances])

  const approvalInfo = needsApproval({
    collateralDifference: positionDelta.collateralDelta,
    usdcAllowance,
    interfaceFee,
  })

  const RenderConfirmPosition = () => (
    <Modal.Body className="confirm-position">
      <Stack className="markets-info" direction="horizontal" gap={2}>
        <Image src={assetMetadata.icon} width={30} height={30} />
        <div className="markets-desc">
          <h6 className="title">
            {assetMetadata.name}
          </h6>
          <span className="caption">
            {!isLimitOrder ? orderSide.toUpperCase() : limitOrderSide.toUpperCase()} - {assetMetadata.baseCurrency.toUpperCase()}
          </span>
        </div>
      </Stack>
      <div className="change-info">
        <div className="info-item">
          <span className="title">{t("collateral")}:</span>
          <div className="value-change">
            <NumericFormat
              className="number gray"
              value={currentCollateral}
              displayType="text"
              thousandSeparator=","
              decimalScale={2}
            />
            <FaArrowRight size={12} />
            <NumericFormat
              className="number"
              value={newCollateral}
              displayType="text"
              thousandSeparator=","
              decimalScale={2}
            />
          </div>
        </div>
        <div className="info-item">
          <span className="title">{t("position-amount")}:</span>
          <div className="value-change">
            <NumericFormat
              className="number gray"
              value={isLimitOrder ? orderSize : currentPosition}
              displayType="text"
              thousandSeparator=","
              decimalScale={4}
            />
            <FaArrowRight size={12} />
            <NumericFormat
              className="number"
              value={newPosition}
              displayType="text"
              thousandSeparator=","
              decimalScale={4}
            />
          </div>
        </div>
        {isLimitOrder && (
          <div className="info-item">
            <h6 className="title">{t("trigger-price")}:</h6>
            <div className="value-change">
              <span className="number gray">{triggerPrice}</span>
              <FaArrowRight size={12} />
              <NumericFormat
                className={"number "}
                value={newLimitOrderPrice}
                displayType="text"
                thousandSeparator=","
                decimalScale={2}
              />
            </div>
          </div>
        )}
        <div className="info-item">
          <span className="title">{t("leverage")}:</span>
          <div className="value-change">
            <span className="number gray">{!isLimitOrder ? currentLeverage : orderLeverage}x</span>
            <FaArrowRight size={12} />
            <span className="number">{newLeverage}x</span>
          </div>
        </div>
      </div>
    </Modal.Body>
  );

  return (
    <Modal
      show={showModal}
      onHide={onHide}
      className={showConfirm ? "modal-edit-position confirm" : "modal-edit-position"}
    >
      <Modal.Header closeButton>
        <h5 className="bold">
          {isLimitOrder ? t("change-order", { orderType: "limit" }) : t("change-position")}
        </h5>
      </Modal.Header>
      {!showConfirm ? (
        <Modal.Body>
          {market && (
            <>
              <div className="edit-left">
                <TradeForm
                  isNewPosition={false}
                  isProInterface={isMaker}
                  asset={assetMetadata.baseCurrency}
                  orderSide={isLimitOrder
                    ? (limitOrderSide !== PositionSide.none ? limitOrderSide : PositionSide.long)
                    : orderSide}
                  setOrderDirection={setOrderDirection}
                  market={market}
                  position={userPosition}
                  order={order}
                  isLimitOrder={isLimitOrder}
                  setUpdateValues={setValues}
                />
              </div>
              <div className="edit-right">
                <div className="position-changes">
                  <h6>{t("position-changes")}</h6>
                  <div className="summary-box changes">
                    <div className="summary-item">
                      <div className="summary-item-2">
                        <TokenIcon name="usdc" size="xs" />                        
                        <h6 className="title">{t("collateral")}:</h6>
                      </div>  
                      <div className="value-change">
                        <div className="value-change-number">                  
                          <NumericFormat
                            className="number gray"
                            value={currentCollateral}
                            displayType="text"
                            thousandSeparator=","
                            decimalScale={4}
                            prefix="$"
                          />
                        </div>  
                        <FaArrowRight size={12} />
                        <div className="value-change-number">
                          <NumericFormat
                            className="number"
                            value={newCollateral}
                            displayType="text"
                            thousandSeparator=","
                            decimalScale={4}
                            prefix="$"
                          />
                        </div>  
                      </div> 
                    </div>
                    <div className="summary-item">
                      <div className="summary-item-2">
                        <Image className="token-icon margin-right" src={assetMetadata.icon} width={16} height={16} />
                        <h6 className="title">{t("amount")}:</h6>
                      </div>  
                      <div className="value-change">
                        <div className="value-change-number">
                          <NumericFormat
                            className="number gray"
                            value={isLimitOrder ? orderSize : currentPosition}
                            displayType="text"
                            thousandSeparator=","
                            decimalScale={4}
                          />
                        </div>
                        <FaArrowRight size={12} />
                        <div className="value-change-number">
                          <NumericFormat
                            className={"number "}
                            value={newPosition}
                            displayType="text"
                            thousandSeparator=","
                            decimalScale={4}
                          />
                        </div>  
                      </div> 
                    </div>
                    {isLimitOrder && (
                      <div className="summary-item">
                        <h6 className="title">{t("trigger-price")}:</h6>
                        <div className="value-change">
                          <div className="value-change-number">
                            <span className="number gray">{triggerPrice}</span>
                          </div>
                          <FaArrowRight size={12} />
                          <div className="value-change-number">
                            <NumericFormat
                              className={"number "}
                              value={newLimitOrderPrice}
                              displayType="text"
                              thousandSeparator=","
                              decimalScale={2}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="summary-item">
                      <h6 className="title">{t("leverage")}:</h6>
                      <div className="value-change">
                        <span className="number gray">{!isLimitOrder ? currentLeverage : orderLeverage}x</span>
                        <FaArrowRight size={12} />
                        <span className="number">{newLeverage}x</span>
                      </div>
                    </div>
                  </div>
                </div>
                <p>{t("info-msg.change-warning")}</p>
                <div>
                  <OrdersWarning />
                  {approvalInfo.needsApproval && (
                    <ApproveButton
                      approvalType="market"
                      contractAddres={marketAddress}
                      currentAllowance={formatBig6(usdcAllowance)}
                      amountToApprove={approvalInfo.approvalAmount}
                      onSuccess={() => {
                        refetchBalances()
                      }}                      
                    />
                  )}
                  {!approvalInfo.needsApproval && (
                    <Button onClick={() => setShowConfirm(true)} disabled={!canModifyPosition} style={{ width: "100%", marginTop: "1rem" }}>
                      {t("update-position")}
                    </Button>
                  )}
                </div>  
              </div>              
            </>
          )}
        </Modal.Body>
      ) : (
        <>
          <RenderConfirmPosition />
          <Modal.Footer className="confirm-change">
            <Button className="btn-edit-position bold" onClick={() => setShowConfirm(false)}>
              {t("cancel")}
            </Button>              
            {!isLimitOrder ? (
              <ModifyPositionButton
                marketAddress={marketAddress}
                label={t("confirm-change")}
                txHistoryLabel="Modify Position"
                positionSide={orderSide}
                collateralDelta={positionDelta.collateralDelta}
                positionAbs={Big6Math.fromFloatString(newPosition)}
                stopLossPrice={Big6Math.fromFloatString(stopLossPrice)}
                takeProfitPrice={Big6Math.fromFloatString(takeProfitPrice)}
                settlementFee={settlementFee}
                interfaceFee={interfaceFee}
                disabled={!showConfirm || assetMetadata.baseCurrency === SupportedAsset.pol}
                successMessage={t("notification.position-updated")}
                onSuccess={() => onHide()}
              />
            ) : (
              <PlaceOrderButton
                label={t("update-order")}
                marketAddress={marketAddress}
                orderType={OrderTypes.limit}
                positionSide={limitOrderSide}
                collateralDelta={positionDelta.collateralDelta}
                positionAbs={Big6Math.fromFloatString(newPosition)}
                limitPrice={Big6Math.fromFloatString(newLimitOrderPrice)}
                stopLossPrice={Big6Math.fromFloatString(stopLossPrice)}
                takeProfitPrice={Big6Math.fromFloatString(takeProfitPrice)}
                selectedLimitComparison={orderComparisson}
                disabled={!showConfirm || assetMetadata.baseCurrency === SupportedAsset.pol}
                successMessage={t("notification.order-updated")}
                cancelOrderDetails={{
                  nonce: orderNonce,
                  market: marketAddress,
                }}
                onSuccess={() => onHide()}
              />
            )}
          </Modal.Footer>  
        </>
      )}
    </Modal>
  ); 
};
