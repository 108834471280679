import React, { useContext, useMemo, useState } from "react"
import { Button } from "react-bootstrap/esm"
import { PricesChart } from "./PricesChart"
import { aggregatorContracts } from "../../utils/constants"
import { productContext } from "../../states"


const timeFrames = [
    {
      label: "24h",
      tooltip: "1 Day",
      hours: 24,
    },
    {
      label: "1W",
      tooltip: "1 Week",
      hours: 168,
    },
    {
      label: "1M",
      tooltip: "1 Month",
      hours: 730,
    },
    {
      label: "6M",
      tooltip: "6 Months",
      hours: 4380,
    },
    {
      label: "1Y",
      tooltip: "1 Year",
      hours: 8760,
    },
]

type TimeFrameType = {
  label: string;
  tooltip: string;
  hours: number;
}

const hoursToMillisecons = (hours: number): number => {
  return hours * 3600 * 1000;
}

export const PriceChartWrapper = () => {
  const currentProduct = useContext(productContext);
  const [currentTimeFrame, setCurrentTimeFrame] = useState(timeFrames[1]);

  const initialMinBlockTS = useMemo(() => {
    const initMinBlockTS = (Date.now() - hoursToMillisecons(currentTimeFrame.hours)) / 1000;
    return parseFloat(initMinBlockTS.toFixed());
  },
    // eslint-disable-next-line
    [currentTimeFrame, currentProduct.market]
  );  

  const onTimeFrameClick = (timeFrame: TimeFrameType) => {
    setCurrentTimeFrame(timeFrame);
  }

  const TimesFrameBox = () => {
    return (
      <div className="time-frame-box">
        {timeFrames.map((frame, index) => (
          <Button
            key={index}
            className={"time-frame-btn".concat(frame.hours === currentTimeFrame.hours ? " active" : "")}
            onClick={() => onTimeFrameClick(frame)}
          >
            {frame.label}
          </Button>
        ))}
      </div>
    )
  }  

  return (
    <div className="chart-container">
      <TimesFrameBox />
      <PricesChart
        key={initialMinBlockTS}
        aggregatorAddress={aggregatorContracts[currentProduct.market.key].address}
        initialMinBlockTS={parseFloat(initialMinBlockTS.toFixed())}
        hoursFrame={currentTimeFrame.hours}
      /> 
    </div>    
  )
}