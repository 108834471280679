import React, { useContext } from "react"
import { Stack } from "react-bootstrap"

import { productContext } from "../../states"
import { TokenIcon } from "../../../components/common"
import { MarketsDropdown } from "./MarketsDropdown"
import { MarketStats } from "./MarketStats"
import { MARKETS } from "../../utils/constants"
import { TokenType } from "../../utils/types"


type props = {
  editable: boolean;
  showStats: boolean;
  showLiquidity: boolean;
};

type propsMarket = {
  market: TokenType;
  positionType: string;
  collateral: TokenType;
};

export const MarketInfo = ({ market, positionType, collateral }: propsMarket) => (
  <Stack className="markets-info" direction="horizontal" gap={2}>
    <TokenIcon name={market.symbol} size="normal" />
    <div className="markets-desc">
      <h6 className="title">
        {market.description}
      </h6>
      <span className="caption">
        {positionType.toUpperCase()} - {collateral.symbol} 
      </span>
    </div>
  </Stack>
);

export const Markets = ({ editable, showStats, showLiquidity }: props) => {
  const currentProduct = useContext(productContext);

  const handleMarketChange = (value: TokenType) => {
    currentProduct.setCurrentMarket(value);
    currentProduct.setCurrentType("long");
    const cProduct = MARKETS[value.key];

    if (cProduct) {
      currentProduct.setCurrentProductContract(cProduct.longContract);
      currentProduct.setCurrentVaultContract(cProduct.vaultContract);
    }
  };

  return (
    <div className="markets">
      {editable ? (
        <MarketsDropdown
          currentMarket={currentProduct.market}
          handleMarketChange={handleMarketChange}
        />
      ) : (
        <MarketInfo
          market={currentProduct.market}
          positionType={currentProduct.type}  
          collateral={currentProduct.collateral}  
        />  
      )}
      {showStats && (
        <MarketStats
          market={currentProduct.market}
          productContract={currentProduct.productContract}
          showLiquidity={showLiquidity}
        />
      )}
    </div>    
  );    
};
