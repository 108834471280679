import React, { useEffect, useMemo, useState } from "react"
import { Button, Spinner, Stack } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import { PushAPI, CONSTANTS } from "@pushprotocol/restapi"

import { usePushContext } from "../../contexts"
import { useWalletClient } from "wagmi"
import { arbitrum } from "wagmi/chains"
import { errorNotification, notifyUser } from "../../components/common/notifications"


export const SubscriptionManager = ({ onHide }: { onHide: () => void }) => {
  const pushAPI = usePushContext();
  const [channelInfo, setChannelInfo] = useState<any>();
  const [channelSettings, setChannelSettings] = useState<any>();
  const [userSettings, setUserSettings] = useState<Array<any>>([]);
  const [initialUserSettings, setInitialUserSettings] = useState<Array<any>>([]);
  
  const loadData = async () => {
    if (!pushAPI.channelApi) return;

    setChannelInfo(pushAPI.channelInfo)
    const cSettings = JSON.parse(pushAPI.channelInfo.channel_settings);

    // check if user is subcribed to channels
    const subscription = pushAPI.getSubscription(pushAPI.channelInfo.channel);
    if (subscription) {
      const uSettings = JSON.parse(subscription.user_settings).map((setting: any, index: number) => {
        if (setting.type === 1) {
          cSettings[index].default = setting.user;
          return { enabled: setting.user };
        }
        cSettings[index].default = setting.user;
        return { enabled: setting.enabled, value: setting.user };
      })

      setUserSettings(uSettings);
      setInitialUserSettings([...uSettings]);
    }

    // if user is not subscribed, set default settings 
    if (!subscription) {
      const uSettings = cSettings.map((setting: any) => {
        if (setting.type === 1) {
          return { enabled: setting.default }
        }
        return { enabled: setting.enabled, value: setting.default }
      })
      setUserSettings(uSettings);
      setInitialUserSettings([...uSettings]);
    }
    setChannelSettings(cSettings);
  }

  useEffect(() => { 
    loadData();
  },
    // eslint-disable-next-line
    [pushAPI.channelApi, pushAPI.subscriberApi]
  )

  const { isSubscribed } = useMemo(() => {
    return {
      isSubscribed: pushAPI.isSubscribed(pushAPI.channelInfo.channel)
    }
  }, [pushAPI])

  const onUserSettingChange = async (index: number, enabled: boolean, value?: number) => { 
    const uSettings = userSettings;
    if (value) {
      uSettings[index - 1] = { enabled, value }
    } else {
      uSettings[index - 1] = { enabled }
    }

    setUserSettings([ ...uSettings ])
  }

  return (
    <Stack direction="vertical">
      <span className="text-muted" style={{ marginTop: "1.2rem" }}>
        {!isSubscribed && "Subscribe and receive notifications."}
        You can customize your notification settings below.
      </span>
      <Stack
        direction="vertical"
        gap={0}
        style={{
          margin: "1.5rem 0px",
          border: "0.5px solid #494866",
          borderRadius: "5px",
        }}
      >
        {channelInfo && channelSettings.map((setting: any, index: number) => (
          <ChannelSetting
            key={setting.index}
            index={index}
            setting={setting}
            onUserSettingChange={onUserSettingChange}
          />
        ))}
      </Stack>
      <SubscribeButton
        userSettings={userSettings}
        initialUserSettings={initialUserSettings}
        onHide={onHide}
      />
    </Stack>
  )    
}

const ChannelSetting = ({
  index,
  setting,
  onUserSettingChange
}: {
  index: number,
  setting: any,
  onUserSettingChange: (index: number, enabled: boolean, value?: number) => void
}) => { 
  const [checked, setChecked] = useState(setting.type === 1 ? setting.default : setting.enabled);

  const extraStyle = useMemo(() => { 
    if (index === 0) {
      return {
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px"
      }
    }
    if (index === 3) { 
      return {
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px"
      }
    }
    return {}
  }, [index])

  const onSwitchChange = () => { 
    setChecked(!checked)
    onUserSettingChange(setting.index, !checked)
  }

  return (
    <Stack
      direction="vertical"
      style={{
        height: "4rem",
        padding: "0.7rem 0.75rem",
        backgroundColor: (setting.index - 1) % 2 === 0 ? "#0f0f0f" : "#0a0a0a",
        ...extraStyle
      }}
    >
      <Stack direction="horizontal" style={{ width: "100%", height: "100%" }}>
        <Stack direction="vertical" style={{ justifyContent: "center" }}>
          <h6 style={{ width: "75%" }} >{setting.description}</h6>
        </Stack>
        <Form.Switch id={setting.index} onChange={() => {}} onClick={() => onSwitchChange()} checked={checked} />
      </Stack>
    </Stack>
  )
}

const SubscribeButton = ({
  userSettings,
  initialUserSettings,
  onHide,
}: {
  userSettings: Array<any>,
  initialUserSettings: Array<any>,
  onHide: () => void
}) => { 
  const pushAPI = usePushContext();
  const { data: walletClient } = useWalletClient({ chainId: arbitrum.id })
  const [subscribing, setSubscribing] = useState(false);
  const [unsubscribing, setUnsubscribing] = useState(false);

  const { isSubscribed } = useMemo(() => {
    return {
      isSubscribed: pushAPI.isSubscribed(pushAPI.channelInfo.channel)
    }
  }, [pushAPI])

  const hasSettingsChanged = useMemo(() => {
    if (!isSubscribed) return true;
    for (let i = 0; i < userSettings.length; i++) {
      if (userSettings[i].enabled !== initialUserSettings[i].enabled) {
        return true;
      }
      if (userSettings[i].value !== initialUserSettings[i].value) {
        return true;
      }
    }
    return false;
  },
    // eslint-disable-next-line
    [userSettings]
  );

  const createPushApiWithSigner = async () => { 
    if (walletClient) {
      const signer = {
        signTypedData: walletClient.signTypedData,
        getChainId: walletClient.getChainId,
        signMessage: walletClient.signMessage,
        account: walletClient.account,
        privateKey: walletClient.key,
      };
      const api = await PushAPI.initialize(signer, {
        env: CONSTANTS.ENV.PROD,
        account: walletClient.account.address,
      })
      return api;
    }
  }

  const subscribe = async (api: PushAPI) => {
    await api.notification.subscribe(
      pushAPI.getChannelCaipAddress(),
      {
        settings: userSettings,
        onSuccess: () => { 
          notifyUser("You have successfully subscribed to notifications", "Subscription Successful");
          setTimeout(() => {
            onHide()
          }, 5000);
        },
        onError: (err) => {
          errorNotification("Failed to subscribe to notifications");
        }
      },
    );
  }

  const unsubscribe = async (api: PushAPI) => {
    await api.notification.unsubscribe(
      pushAPI.getChannelCaipAddress(),
      {
        onSuccess: () => { 
          notifyUser("You have successfully Unsubscribed from notifications", "Unsubscribed");
          setTimeout(() => { onHide() }, 5000);
        },
        onError: () => { 
          errorNotification("Failed to unsubscribe from notifications");
        }
      }
    );
  }

  const onSubscribeClick = async () => { 
    setSubscribing(true);
    if (walletClient && !pushAPI.hasSigner()) {
      const api = await createPushApiWithSigner();
      if (api) {
        await subscribe(api);
      } 
    } else if (pushAPI.subscriberApi) {
      await subscribe(pushAPI.subscriberApi);
    }
    setSubscribing(false);
  }

  const onUnsubscribeClick = async () => {
    setUnsubscribing(true);
    if (walletClient && !pushAPI.hasSigner()) {
      const api = await createPushApiWithSigner();
      if (api) {
        await unsubscribe(api);
      } 
    } else if (pushAPI.subscriberApi) {
      await unsubscribe(pushAPI.subscriberApi);
    }
    setUnsubscribing(false);
  }

  return (
    <Stack direction="vertical" gap={1}>
      <Button
        onClick={() => onSubscribeClick()}
        disabled={!hasSettingsChanged || subscribing || unsubscribing || !walletClient}
        style={{ width: "100%" }}
      >
        <div className="btn-spinner">
          {subscribing && <Spinner animation="border" variant="secondary" className="small" />}
          {isSubscribed ? "Update Preferences" : "Subscribe"}
        </div>
      </Button>
      {isSubscribed && (
        <>
          <Stack direction="horizontal"  style={{ justifyContent: "center", fontSize: "14px", marginTop: "5px" }} >
            <Button
              onClick={() => onUnsubscribeClick()}
              variant="outline-primary"
              disabled={unsubscribing || subscribing}
              style={{ fontSize: "14px", padding: "5px 5px", color: "#A440F2"}}
            >
              Unsubscribe
            </Button>
            to stop receiving notifications.
          </Stack>
          <Stack direction="horizontal" style={{ justifyContent: "center", fontSize: "14px" }}>
            Get Notifications in MetaMask. 
            <a href="https://app.push.org/snap" target="_blank" rel="noreferrer" style={{ fontSize: "14px", paddingLeft: "5px" }}>
              Install Push Snap
            </a>
          </Stack>
        </>
      )}
    </Stack>
  )
}
