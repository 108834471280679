import React from "react";
import ReactGA from "react-ga4";
import Governance from "../../components/Governance";

const GovernancePage = () => {
  ReactGA.send({ hitType: "pageview", page: "/governance", title: "Governance" });

  return (
    <Governance />
  )
}

export default GovernancePage;
