import React from "react"
import { BrowserRouter } from "react-router-dom"
import { createRoot } from "react-dom/client"
import "@rainbow-me/rainbowkit/styles.css"
import "./styles/index.scss"
import App from "./App"

import './i18n'

window.Buffer = window.Buffer || require("buffer").Buffer;

const container = document.getElementById("root");
// @ts-ignore
const root = createRoot(container);


root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
